import { CATEGORY } from './categories';
import { DIFFICULTY } from './constants';

export const REGION_ANY = 'Global';

export default {
  193: {
    id: '193',
    label: `Achieve Your First Level 10`,
    description: `Reach level 10 in any skill (not including Agility and Hitpoints)`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  194: {
    id: '194',
    label: `Achieve Your First Level 20`,
    description: `Reach level 20 in any skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  192: {
    id: '192',
    label: `Achieve Your First Level 5`,
    description: `Reach level 5 in any skill (not including Agility, Hitpoints and Runecraft)`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  191: {
    id: '191',
    label: `Achieve Your First Level Up`,
    description: `Level up any of your skills for the first time`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  297: {
    id: '297',
    label: `Burn Some Food`,
    description: `Burn any kind of food while trying to cook it`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  371: {
    id: '371',
    label: `Burn Some Normal Logs`,
    description: `Burn some Normal Logs`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  372: {
    id: '372',
    label: `Burn Some Oak Logs`,
    description: `Burn some Oak Logs`,
    skillReqs: [{ skill: 'Firemaking', level: 15 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  97: {
    id: '97',
    label: `Bury Some Bones`,
    description: `Bury any kind of Bones`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1151: {
    id: '1151',
    label: `Buy Something From Trader Crewmembers`,
    description: `Buy something from the Trader Crewmembers`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  431: {
    id: '431',
    label: `Cast Home Teleport`,
    description: `Cast the Home Teleport spell`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  379: {
    id: '379',
    label: `Catch a Baby Impling`,
    description: `Catch a Baby Impling`,
    skillReqs: [{ skill: 'Hunter', level: 17 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  341: {
    id: '341',
    label: `Catch a Herring`,
    description: `Catch a Raw Herring whilst Fishing`,
    skillReqs: [{ skill: 'Fishing', level: 10 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  340: {
    id: '340',
    label: `Catch a Shrimp`,
    description: `Catch Raw Shrimp while Fishing`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  342: {
    id: '342',
    label: `Catch an Anchovy`,
    description: `Catch a Raw Anchovy whilst Fishing`,
    skillReqs: [{ skill: 'Fishing', level: 15 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  319: {
    id: '319',
    label: `Check Your Slayer Task`,
    description: `Use an Enchanted Gem to check your Slayer Task`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  289: {
    id: '289',
    label: `Chop Some Logs`,
    description: `Chop any kind of logs`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  290: {
    id: '290',
    label: `Chop Some Logs With a Steel Axe`,
    description: `Chop any kind of logs using a Steel Axe`,
    skillReqs: [{ skill: 'Woodcutting', level: 6 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1785: {
    id: '1785',
    label: `Clean 15 Grimy Tarromin`,
    description: `Clean 15 Grimy Tarromin`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1784: {
    id: '1784',
    label: `Clean 25 Grimy Guam Leafs`,
    description: `Clean 25 Grimy Guam Leafs`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  391: {
    id: '391',
    label: `Clean a Grimy Guam`,
    description: `Clean a Grimy Guam`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  190: {
    id: '190',
    label: `Complete the Leagues Tutorial`,
    description: `Complete the Leagues Tutorial and begin your adventure`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  296: {
    id: '296',
    label: `Cook Shrimp`,
    description: `Cook Raw Shrimp`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  333: {
    id: '333',
    label: `Craft a Leather Body`,
    description: `Craft a Leather Body`,
    skillReqs: [{ skill: 'Crafting', level: 14 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1367: {
    id: '1367',
    label: `Create a Compost Potion`,
    description: `Create a Compost Potion`,
    skillReqs: [{ skill: 'Herblore', level: 22 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1366: {
    id: '1366',
    label: `Create an Antipoison`,
    description: `Create an Antipoison`,
    skillReqs: [{ skill: 'Herblore', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1287: {
    id: '1287',
    label: `Cry in a wheat field`,
    description: `Cry in a wheat field`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  332: {
    id: '332',
    label: `Cut a Sapphire`,
    description: `Cut a Sapphire`,
    skillReqs: [{ skill: 'Crafting', level: 20 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1288: {
    id: '1288',
    label: `Dance in a graveyard`,
    description: `Dance in a graveyard`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  63: {
    id: '63',
    label: `Defeat a Goblin`,
    description: `Defeat a Goblin`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  64: {
    id: '64',
    label: `Defeat a Guard`,
    description: `Defeat a Guard`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  61: {
    id: '61',
    label: `Defeat a Moss Giant`,
    description: `Defeat a Moss Giant`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  393: {
    id: '393',
    label: `Drink a Strength Potion`,
    description: `Drink a Strength Potion`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1269: {
    id: '1269',
    label: `Dye a cape orange`,
    description: `Dye a cape orange`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1800: {
    id: '1800',
    label: `Eat a Banana`,
    description: `Eat a Banana`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1268: {
    id: '1268',
    label: `Enter Puro Puro from Gielinor`,
    description: `Enter Puro Puro from a crop circle in mainland Gielinor`,
    skillReqs: [{ skill: 'Hunter', level: 17 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  649: {
    id: '649',
    label: `Enter your Player Owned House`,
    description: `Enter your Player Owned House`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  321: {
    id: '321',
    label: `Equip a Spiny Helmet`,
    description: `Equip a Spiny Helmet`,
    skillReqs: [{ skill: 'Defence', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  421: {
    id: '421',
    label: `Equip a Studded Body and Chaps`,
    description: `Equip a Studded Body along with some Studded Chaps`,
    skillReqs: [
      { skill: 'Ranged', level: 20 },
      { skill: 'Defence', level: 20 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  430: {
    id: '430',
    label: `Equip an Elemental Staff`,
    description: `Equip a basic elemental staff`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  311: {
    id: '311',
    label: `Fletch an Oak Shortbow`,
    description: `Fletch an Oak Shortbow`,
    skillReqs: [{ skill: 'Fletching', level: 20 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  310: {
    id: '310',
    label: `Fletch Some Arrow Shafts`,
    description: `Fletch some Arrow Shafts`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1276: {
    id: '1276',
    label: `Kill a Necromancer`,
    description: `Kill a Necromancer`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  373: {
    id: '373',
    label: `Light a Torch`,
    description: `Light a Torch`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  366: {
    id: '366',
    label: `Locate a Runecrafting Altar With a Talisman`,
    description: `Use any talisman to check the location of a Runecrafting Altar`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  392: {
    id: '392',
    label: `Make an Attack Potion`,
    description: `Make an Attack Potion`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1371: {
    id: '1371',
    label: `Mine 5 Tin Ore`,
    description: `Mine 5 Tin Ore`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  303: {
    id: '303',
    label: `Mine some Copper Ore`,
    description: `Mine some Copper Ore`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1801: {
    id: '1801',
    label: `Mine some essence`,
    description: `Mine some essence`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  304: {
    id: '304',
    label: `Mine some Ore With a Steel Pickaxe`,
    description: `Mine any ore using a Steel Pickaxe`,
    skillReqs: [{ skill: 'Mining', level: 6 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  291: {
    id: '291',
    label: `Obtain a Bird Nest`,
    description: `Obtain a Bird Nest whilst cutting down trees`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1070: {
    id: '1070',
    label: `Obtain a Casket from Fishing`,
    description: `Obtain a Casket from Fishing`,
    skillReqs: [{ skill: 'Fishing', level: 16 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  189: {
    id: '189',
    label: `Open the Leagues Menu`,
    description: `Open the Leagues Menu found within the Journal Panel`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  410: {
    id: '410',
    label: `Perform a Special Attack`,
    description: `Perform any special attack`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  273: {
    id: '273',
    label: `Pickpocket a Citizen`,
    description: `Pickpocket a Man or a Woman`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  281: {
    id: '281',
    label: `Plant Seeds in an Allotment Patch`,
    description: `Plant some seeds in an Allotment patch`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  282: {
    id: '282',
    label: `Protect Your Crops`,
    description: `Pay a farmer to protect any of your crops`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  356: {
    id: '356',
    label: `Purchase a Player Owned House`,
    description: `Purchase a Player Owned House`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  280: {
    id: '280',
    label: `Rake a Farming Patch`,
    description: `Rake any Farming patch`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  401: {
    id: '401',
    label: `Reach Combat Level 10`,
    description: `Reach Combat Level 10`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '',
  },
  402: {
    id: '402',
    label: `Reach Combat Level 25`,
    description: `Reach Combat Level 25`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '',
  },
  94: {
    id: '94',
    label: `Restore 5 Prayer Points at an Altar`,
    description: `Restore 5 or more Prayer Points at any altar`,
    skillReqs: [{ skill: 'Prayer', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1783: {
    id: '1783',
    label: `Scatter some Ashes`,
    description: `Scatter some Ashes`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  347: {
    id: '347',
    label: `Smelt a Bronze Bar`,
    description: `Use a Furnace to smelt a Bronze Bar`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  348: {
    id: '348',
    label: `Smelt an Iron Bar`,
    description: `Use a Furnace to smelt an Iron Bar`,
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1373: {
    id: '1373',
    label: `Smith a Bronze full helm`,
    description: `Use an Anvil to smith a Bronze full helm`,
    skillReqs: [{ skill: 'Smithing', level: 7 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1374: {
    id: '1374',
    label: `Smith a Bronze plateskirt`,
    description: `Use an Anvil to smith a Bronze plateskirt`,
    skillReqs: [{ skill: 'Smithing', level: 16 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  380: {
    id: '380',
    label: `Snare a Bird`,
    description: `Catch any bird with a Bird Snare`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  331: {
    id: '331',
    label: `Spin a Ball of Wool`,
    description: `Use a Spinning Wheel to spin a Ball of Wool`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  275: {
    id: '275',
    label: `Steal a Chocolate Slice`,
    description: `Steal a Chocolate Slice from a Bakery Stall`,
    skillReqs: [{ skill: 'Thieving', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1071: {
    id: '1071',
    label: `Steal Some Silk`,
    description: `Steal some silk from a silk stall`,
    skillReqs: [{ skill: 'Thieving', level: 20 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1350: {
    id: '1350',
    label: `Successfully Cut a Red Topaz`,
    description: `Successfully Cut a Red Topaz`,
    skillReqs: [{ skill: 'Crafting', level: 16 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  95: {
    id: '95',
    label: `Superhuman Strength and Improved Reflexes`,
    description: `Use both the Superhuman Strength prayer and the Improved Reflexes prayer at the same time`,
    skillReqs: [{ skill: 'Prayer', level: 16 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  358: {
    id: '358',
    label: `Turn any Logs Into a Plank`,
    description: `Use a Sawmill to turn Logs into a Plank`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1540: {
    id: '1540',
    label: `Use a Hat Stand`,
    description: `Put a hat on a hat stand, or try at least`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  409: {
    id: '409',
    label: `Visit Death's Domain`,
    description: `Visit Death's Domain`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  364: {
    id: '364',
    label: `Visit the Rune Essence Mine`,
    description: `Visit the Rune Essence Mine`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1549: {
    id: '1549',
    label: `Balance 5 barrels on your head`,
    description: `Balance 5 barrels on your head in the Warriors guild`,
    skillReqs: [{ skill: 'Attack', level: 99 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  774: {
    id: '774',
    label: `Craft an Air Rune`,
    description: `Craft an Air Rune from Essence at the Air Altar`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  786: {
    id: '786',
    label: `Defeat a Troll in Asgarnia`,
    description: `Defeat a Troll in Asgarnia`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1324: {
    id: '1324',
    label: `Eat a magic cabbage`,
    description: `Eat a magic cabbage`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  823: {
    id: '823',
    label: `Enter the Taverley Dungeon`,
    description: `Enter the Taverley Dungeon`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1336: {
    id: '1336',
    label: `Give Thurgo a redberry pie`,
    description: `Give Thurgo a redberry pie`,
    skillReqs: [{ skill: 'Cooking', level: 10 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1312: {
    id: '1312',
    label: `Kill a port sarim prisoner`,
    description: `Kill a port sarim prisoner`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1525: {
    id: '1525',
    label: `Use the Falador Party room`,
    description: `Deposit an item into the Falador Party room chest`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1084: {
    id: '1084',
    label: `1 Tempoross Kill`,
    description: `Help the Spirit Anglers subdue the Tempoross`,
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  1548: {
    id: '1548',
    label: `Catch a Salmon`,
    description: `Catch a Salmon in the Desert`,
    skillReqs: [{ skill: 'Fishing', level: 30 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  852: {
    id: '852',
    label: `Complete the Al Kharid Agility Course`,
    description: `Complete a lap of the Al Kharid Rooftop Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 20 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  854: {
    id: '854',
    label: `Craft a Fire Rune`,
    description: `Craft a Fire Rune from Essence at the Fire Altar`,
    skillReqs: [{ skill: 'Runecraft', level: 14 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1568: {
    id: '1568',
    label: `Drink some of Ali's tea`,
    description: `Drink a cup of tea in Ali the Tea Seller's house`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  895: {
    id: '895',
    label: `Enter the Kalphite Lair`,
    description: `Enter the Kalphite Lair`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1569: {
    id: '1569',
    label: `Kill a Billy Goat`,
    description: `Kill a Billy Goat`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  842: {
    id: '842',
    label: `Pick a Winter Sq'irk`,
    description: `Pick a Winter Sq'irk in the Sorceress's Garden`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  831: {
    id: '831',
    label: `Room 1 of Pyramid Plunder`,
    description: `Search the Golden Chest in Room 1 of Pyramid Plunder in Sophanem`,
    skillReqs: [{ skill: 'Thieving', level: 21 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  832: {
    id: '832',
    label: `Room 2 of Pyramid Plunder`,
    description: `Search the Golden Chest in Room 2 of Pyramid Plunder in Sophanem`,
    skillReqs: [{ skill: 'Thieving', level: 31 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  833: {
    id: '833',
    label: `Room 3 of Pyramid Plunder`,
    description: `Search the Golden Chest in Room 3 of Pyramid Plunder in Sophanem`,
    skillReqs: [{ skill: 'Thieving', level: 41 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1545: {
    id: '1545',
    label: `Set a mummy ablaze`,
    description: `Set a Mummy on Fire with fire damage`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1045: {
    id: '1045',
    label: `Take a Carpet Ride from Pollnivneach to Sophanem`,
    description: `Take a carpet ride from Pollnivneach to Sophanem`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  846: {
    id: '846',
    label: `Turn in a Winter Sq'irkjuice to Osman`,
    description: `Turn in a Winter Sq'irkjuice to Osman in Al Kharid`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  889: {
    id: '889',
    label: `Use the Bank in Nardah`,
    description: `Use the Bank in Nardah`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1832: {
    id: '1832',
    label: `Catch a Cerulean Twitch`,
    description: `Catch a Cerulean twitch`,
    skillReqs: [{ skill: 'Hunter', level: 11 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1831: {
    id: '1831',
    label: `Catch a Polar Kebbit`,
    description: `Catch a Polar Kebbit`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1292: {
    id: '1292',
    label: `Chop down an arctic pine tree`,
    description: `Chop down an arctic pine tree`,
    skillReqs: [{ skill: 'Woodcutting', level: 54 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1581: {
    id: '1581',
    label: `Cut the Swaying Tree`,
    description: `Cut the Swaying Tree`,
    skillReqs: [{ skill: 'Woodcutting', level: 40 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  588: {
    id: '588',
    label: `Defeat a Cockatrice in the Fremennik Province`,
    description: `Defeat a Cockatrice in the Fremennik Province`,
    skillReqs: [{ skill: 'Slayer', level: 25 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  592: {
    id: '592',
    label: `Defeat a Pyrefiend in the Fremennik Province`,
    description: `Defeat a Pyrefiend in the Fremennik Province`,
    skillReqs: [{ skill: 'Slayer', level: 30 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  583: {
    id: '583',
    label: `Defeat a Rock Crab in the Fremennik Province`,
    description: `Defeat a Rock Crab in the Fremennik Province`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1837: {
    id: '1837',
    label: `Kill a Rooster`,
    description: `Kill a Rooster in the Fremennik Province`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1290: {
    id: '1290',
    label: `Kill a Yak`,
    description: `Kill a Yak`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1842: {
    id: '1842',
    label: `Pick up Snapegrass`,
    description: `Pick up Snapegrass from Lunar Isle`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  565: {
    id: '565',
    label: `Switch to the Lunar Spellbook`,
    description: `Switch to the Lunar Spellbook`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  632: {
    id: '632',
    label: `Use the Bank on Jatizso`,
    description: `Use the Bank on Jatizso`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  636: {
    id: '636',
    label: `Use the Bank on Lunar Isle`,
    description: `Use the Bank on Lunar Isle`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  634: {
    id: '634',
    label: `Use the Bank on Neitiznot`,
    description: `Use the Bank on Neitiznot`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  747: {
    id: '747',
    label: `Brimstail Rune Essence Mine teleport`,
    description: `Have Brimstail teleport you to the Rune Essence Mine`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  690: {
    id: '690',
    label: `Catch a Crimson Swift in the Feldip Hills`,
    description: `Catch a Crimson Swift in the Feldip Hills`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1369: {
    id: '1369',
    label: `Catch a wild kebbit`,
    description: `Catch a wild kebbit`,
    skillReqs: [{ skill: 'Hunter', level: 23 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  686: {
    id: '686',
    label: `Complete the Gnome Stronghold Agility Course`,
    description: `Complete a lap of the Gnome Stronghold Agility Course`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  721: {
    id: '721',
    label: `Defeat a Bloodveld in Kandarin`,
    description: `Defeat a Bloodveld in Kandarin`,
    skillReqs: [{ skill: 'Slayer', level: 50 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  724: {
    id: '724',
    label: `Defeat a Fire Giant in Kandarin`,
    description: `Defeat a Fire Giant in Kandarin`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1152: {
    id: '1152',
    label: `Get Stung By Bees`,
    description: `Get stung by bees at the Beehives`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1271: {
    id: '1271',
    label: `Kill Salarin the Twisted`,
    description: `Kill Salarin the Twisted in Yanille Agility Dungeon`,
    skillReqs: [{ skill: 'Agility', level: 67 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1544: {
    id: '1544',
    label: `Obtain Jack's legendary rod`,
    description: `Buy Grandpa Jack's competition winning fishing rod`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1150: {
    id: '1150',
    label: `Pick Wheat in Catherby`,
    description: `Pick some wheat in the field by the Catherby Farming patches`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1542: {
    id: '1542',
    label: `Receive a gift from Arnold`,
    description: `Talk to Arnold Lydspor and receive a gift`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  737: {
    id: '737',
    label: `Visit the Tree Gnome Stronghold`,
    description: `Visit the Tree Gnome Stronghold`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  734: {
    id: '734',
    label: `Visit the Tree Gnome Village`,
    description: `Visit the Tree Gnome Village`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  644: {
    id: '644',
    label: `Catch a Karambwanji`,
    description: `Catch a Karambwanji on Karamja`,
    skillReqs: [{ skill: 'Fishing', level: 5 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  675: {
    id: '675',
    label: `Fill a Crate With Bananas`,
    description: `Fill a crate with Bananas for Luthas at Musa Point`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1798: {
    id: '1798',
    label: `Kill a Snake in Karamja`,
    description: `Kill a Snake in Karamja`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  679: {
    id: '679',
    label: `Pick a Pineapple on Karamja`,
    description: `Pick a Pineapple on Karamja`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  638: {
    id: '638',
    label: `Receive an Agility Arena Ticket`,
    description: `Receive an Agility Arena Ticket from the Brimhaven Agility Arena`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  103: {
    id: '103',
    label: `1 Sarachnis Kill`,
    description: `Kill Sarachnis in the Forthos Dungeon`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '',
  },
  67: {
    id: '67',
    label: `1 Wintertodt Kill`,
    description: `Help the Pyromancers subdue the Wintertodt`,
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.WINTERTODT,
    prerequisite: '',
  },
  172: {
    id: '172',
    label: `Bank at Land's End`,
    description: `Open your Bank using the Bank at Land's End`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  173: {
    id: '173',
    label: `Bank at Mount Quidamortem`,
    description: `Open your Bank using the Bank at Mount Quidamortem`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1289: {
    id: '1289',
    label: `Brutally murder Conrad King`,
    description: `Brutally murder Conrad King`,
    skillReqs: [{ skill: 'Thieving', level: 20 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  71: {
    id: '71',
    label: `Complete 1 Farming Contract`,
    description: `Complete a Farming Contract for Guildmaster Jane in the Farming Guild`,
    skillReqs: [{ skill: 'Farming', level: 45 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1277: {
    id: '1277',
    label: `Drink a LizardKicker`,
    description: `Drink a LizardKicker`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1606: {
    id: '1606',
    label: `Eat a Field Ration`,
    description: `Eat a Field Ration`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1286: {
    id: '1286',
    label: `Equip a Protest Banner`,
    description: `Equip a Protest Banner`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1594: {
    id: '1594',
    label: `Kill a Barbarian in Kourend`,
    description: `Kill a Barbarian in Kourend`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  139: {
    id: '139',
    label: `Open 1 Grubby Chest`,
    description: `Open the grubby chest in the Forthos Dungeon`,
    skillReqs: [{ skill: 'Thieving', level: 57 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1638: {
    id: '1638',
    label: `Repair a Fishing Crane`,
    description: `Repair a Fishing Crane`,
    skillReqs: [
      { skill: 'Construction', level: 30 },
      { skill: 'Crafting', level: 30 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  174: {
    id: '174',
    label: `Travel to Molch Island`,
    description: `Take one of the boats around Lake Molch to Molch Island`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  145: {
    id: '145',
    label: `Turn in 1 Library Book`,
    description: `Find and turn in a book in the Arceuus Library`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1600: {
    id: '1600',
    label: `Use the Mine cart transportation System`,
    description: `Use the Mine cart transportation System`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1557: {
    id: '1557',
    label: `Ask for a Quest from Bob`,
    description: `Talk to Bob in Lumbridge axe shop and ask for a Quest`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1315: {
    id: '1315',
    label: `Chop a log from a potato tree`,
    description: `Chop a log from a tree that is curiously in a potato field`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  459: {
    id: '459',
    label: `Complete the Draynor Agility Course`,
    description: `Complete a lap of the Draynor Rooftop Agility Course`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  512: {
    id: '512',
    label: `Complete the Natural History Quiz`,
    description: `Complete the Natural History Quiz in the Varrock Museum`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1522: {
    id: '1522',
    label: `Complete the Varrock Agility Course`,
    description: `Complete a lap of the Varrock Rooftop Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 30 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  466: {
    id: '466',
    label: `Defeat the Lesser Demon in the Wizards' Tower`,
    description: `Defeat the Lesser Demon at the top of the Wizards' Tower`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1780: {
    id: '1780',
    label: `Drink a beer in the Longhall`,
    description: `Drink a beer in the Longhall in Barbarian Village`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1562: {
    id: '1562',
    label: `Eat an Onion`,
    description: `Eat an Onion, raw`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  494: {
    id: '494',
    label: `Enter Zanaris`,
    description: `Enter the lost city of Zanaris`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1560: {
    id: '1560',
    label: `Equip an Iron dagger`,
    description: `Equip an Iron dagger`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  1313: {
    id: '1313',
    label: `Get a chair to follow you`,
    description: `Get a chair to follow you`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1776: {
    id: '1776',
    label: `Get a haircut`,
    description: `Go and get a haircut`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1314: {
    id: '1314',
    label: `Get your revenge against a Dark Wizard`,
    description: `Get your revenge against a Dark Wizard, south of Varrock`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1321: {
    id: '1321',
    label: `Have Elsie tell you a story`,
    description: `Have Elsie tell you a story in Varrock`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1311: {
    id: '1311',
    label: `Have Ned make you some rope`,
    description: `Have Ned make you some rope using a ball of wool in Draynor Village`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1308: {
    id: '1308',
    label: `Insult Aggie the Witch`,
    description: `Insult Aggie the Witch in Draynor Village`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1781: {
    id: '1781',
    label: `Kill a Barbarian in the Barbarian Village`,
    description: `Kill a Barbarian in Barbarian Village`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1559: {
    id: '1559',
    label: `Kill a Chicken with your fists`,
    description: `Kill a Chicken with your fists`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1553: {
    id: '1553',
    label: `Kill a Cow in one hit`,
    description: `Kill a Cow in one hit`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1563: {
    id: '1563',
    label: `Kill a Duck with a fire spell`,
    description: `Kill a Duck with a fire spell`,
    skillReqs: [{ skill: 'Magic', level: 13 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1565: {
    id: '1565',
    label: `Kill a Frog`,
    description: `Kill a Frog`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1566: {
    id: '1566',
    label: `Kill a Goblin holding a spear`,
    description: `Kill a Goblin holding a spear`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1555: {
    id: '1555',
    label: `Kill a Mugger`,
    description: `Kill a Mugger`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1558: {
    id: '1558',
    label: `Kill a Ram`,
    description: `Kill a Ram`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1554: {
    id: '1554',
    label: `Kill a Rat`,
    description: `Kill a Rat`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1552: {
    id: '1552',
    label: `Kill a Spider by kicking it`,
    description: `Kill a Spider in Lumbridge by kicking it`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1564: {
    id: '1564',
    label: `Kill an Imp with an earth spell`,
    description: `Kill an Imp with an earth spell`,
    skillReqs: [{ skill: 'Magic', level: 9 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1306: {
    id: '1306',
    label: `Milk a cow`,
    description: `Milk a cow in Lumbridge`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1327: {
    id: '1327',
    label: `Pan for an Uncut Jade`,
    description: `Pan for an Uncut Jade`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  498: {
    id: '498',
    label: `Pet a Stray Dog in Varrock`,
    description: `Pet a Stray Dog in Varrock`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1143: {
    id: '1143',
    label: `Pet the Museum Camp dog`,
    description: `Pet the dog in the Museum Camp on Fossil Island`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1771: {
    id: '1771',
    label: `Pick a Cabbage in Varrock`,
    description: `Pick a Cabbage in Varrock`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1561: {
    id: '1561',
    label: `Pray at an Altar in Lumbridge`,
    description: `Pray at an Altar in Lumbridge`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1332: {
    id: '1332',
    label: `Slash a web in varrock sewers`,
    description: `Slash a web in Varrock sewers`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  457: {
    id: '457',
    label: `Steal From the Varrock Tea Stall`,
    description: `Steal from the Tea Stall in Varrock`,
    skillReqs: [{ skill: 'Thieving', level: 5 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1775: {
    id: '1775',
    label: `Stroke your cat`,
    description: `Get a cat and stroke it!`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1799: {
    id: '1799',
    label: `Take a Canoe to Champions Guild`,
    description: `Take a Canoe from Lumbridge to the Champions Guild`,
    skillReqs: [{ skill: 'Woodcutting', level: 12 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1556: {
    id: '1556',
    label: `Talk to Hans`,
    description: `Talk to Hans and have him tell you how old you are`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  496: {
    id: '496',
    label: `Travel to Fossil Island`,
    description: `Take the Museum Barge to Fossil Island`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1547: {
    id: '1547',
    label: `Upset the homeless`,
    description: `Anger the Tramp, in south east Varrock`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  508: {
    id: '508',
    label: `Use a Fairy Ring`,
    description: `Use any Fairy Ring`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1774: {
    id: '1774',
    label: `Use the Northern Staircase in Lumbridge`,
    description: `Use the Northern Staircase in Lumbridge Castle to go upstairs from the bottom floor`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  956: {
    id: '956',
    label: `Complete an Easy Temple Trek`,
    description: `Complete an easy Temple Trek`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  913: {
    id: '913',
    label: `Craft a Snelm`,
    description: `Craft any Snelm`,
    skillReqs: [{ skill: 'Crafting', level: 15 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  920: {
    id: '920',
    label: `Defeat a Werewolf in Morytania`,
    description: `Defeat a Werewolf in Morytania`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1130: {
    id: '1130',
    label: `Open a Bronze Chest`,
    description: `Open a Bronze Chest from Shades of Mort'ton`,
    skillReqs: [{ skill: 'Firemaking', level: 5 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  966: {
    id: '966',
    label: `Visit Harmony Island`,
    description: `Visit Harmony Island`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  968: {
    id: '968',
    label: `Visit Mos Le'Harmless`,
    description: `Visit Mos Le'Harmless`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  959: {
    id: '959',
    label: `Visit Port Phasmatys`,
    description: `Visit Port Phasmatys`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  563: {
    id: '563',
    label: `Charter a Ship From Prifddinas to Port Tyras`,
    description: `Take a Charter Ship from Prifddinas to Port Tyras`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  514: {
    id: '514',
    label: `Cook a Rabbit in Tirannwn`,
    description: `Cook a Rabbit anywhere within Tirannwn`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  521: {
    id: '521',
    label: `Cross a Trap in Isafdar`,
    description: `Successfully cross any kind of trap in Isafdar`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  532: {
    id: '532',
    label: `Defeat a Moss Giant in Tirannwn`,
    description: `Defeat a Moss Giant in Tirannwn`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  528: {
    id: '528',
    label: `Defeat a Waterfiend in Tirannwn`,
    description: `Defeat a Waterfiend in Tirannwn`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  564: {
    id: '564',
    label: `Talk to Ilfeen in Tirannwn`,
    description: `Talk to Ilfeen in Tirannwn`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1300: {
    id: '1300',
    label: `Use Broken Handz's telescope`,
    description: `Use Broken Hand'z telescope to spy on somene`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  556: {
    id: '556',
    label: `Use the Bank in Lletya`,
    description: `Use the Bank in Lletya`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1651: {
    id: '1651',
    label: `Charter a Ship From Sunset Coast to Civitas`,
    description: `Take a Charter Ship from Sunset Coast to Civitas illa Fortis`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1712: {
    id: '1712',
    label: `Drink from a bird bath`,
    description: `Drink from a bird bath`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1754: {
    id: '1754',
    label: `Give Oli some Stew`,
    description: `Give Oli some Stew in Civitas illa Fortis`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1716: {
    id: '1716',
    label: `Kill a Buffalo`,
    description: `Kill a Buffalo`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1715: {
    id: '1715',
    label: `Kill a Guard in Varlamore underground`,
    description: `Kill a Guard in Varlamore underground`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1718: {
    id: '1718',
    label: `Kill an Icefiend in Varlamore`,
    description: `Kill an Icefiend in Varlamore`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1717: {
    id: '1717',
    label: `Milk a Buffalo`,
    description: `Milk a Buffalo`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1755: {
    id: '1755',
    label: `Pet Renu`,
    description: `Pet Renu`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1713: {
    id: '1713',
    label: `Pet Xolo in Civitas`,
    description: `Pet Xolo in Civitas illa Fortis`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1714: {
    id: '1714',
    label: `Pick some Sweetcorn from a Field`,
    description: `Pick some Sweetcorn from a Field`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1777: {
    id: '1777',
    label: `Shear an Alpaca`,
    description: `Shear an Alpaca`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1653: {
    id: '1653',
    label: `Travel using the Quetzal Transport System`,
    description: `Travel using the Quetzal Transport System`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  994: {
    id: '994',
    label: `Defeat a Chaos Dwarf in the Wilderness`,
    description: `Defeat a Chaos Dwarf in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  990: {
    id: '990',
    label: `Defeat a Fire Giant in the Wilderness`,
    description: `Defeat a Fire Giant in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1037: {
    id: '1037',
    label: `Enter the Wilderness God Wars Dungeon`,
    description: `Enter the Wilderness God Wars Dungeon`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1035: {
    id: '1035',
    label: `Enter the Wilderness Resource Area`,
    description: `Enter the Wilderness Resource Area`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1295: {
    id: '1295',
    label: `Equip a team cape`,
    description: `Equip a team cape in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  1032: {
    id: '1032',
    label: `Open a Looting Bag`,
    description: `Open a Looting Bag`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1031: {
    id: '1031',
    label: `Use a Lever to Teleport to Edgeville`,
    description: `Use the lever near the Mage Arena to teleport to Edgeville`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1038: {
    id: '1038',
    label: `Use the Bank at the Mage Arena`,
    description: `Use the Bank at the Mage Arena`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  122: {
    id: '122',
    label: `Visit Ferox Enclave`,
    description: `Visit Ferox Enclave`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.EASY,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  148: {
    id: '148',
    label: `1 Easy Clue Scroll`,
    description: `Open a Reward casket for completing an easy clue scroll`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  160: {
    id: '160',
    label: `1 Elite Clue Scroll`,
    description: `Open a Reward casket for completing an elite clue scroll`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  156: {
    id: '156',
    label: `1 Hard Clue Scroll`,
    description: `Open a Reward casket for completing a hard clue scroll`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  152: {
    id: '152',
    label: `1 Medium Clue Scroll`,
    description: `Open a Reward casket for completing a medium clue scroll`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1449: {
    id: '1449',
    label: `15 Collection log slots`,
    description: `Obtain 15 unique Collection Log slots`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  149: {
    id: '149',
    label: `25 Easy Clue Scrolls`,
    description: `Open 25 Reward caskets for completing easy clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  161: {
    id: '161',
    label: `25 Elite Clue Scrolls`,
    description: `Open 25 Reward caskets for completing elite clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  157: {
    id: '157',
    label: `25 Hard Clue Scrolls`,
    description: `Open 25 Reward caskets for completing hard clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  153: {
    id: '153',
    label: `25 Medium Clue Scrolls`,
    description: `Open 25 Reward caskets for completing medium clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  96: {
    id: '96',
    label: `25 Superior Slayer Encounters`,
    description: `Defeat 25 superior foes while on a Slayer Task`,
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1450: {
    id: '1450',
    label: `30 Collection log slots`,
    description: `Obtain 30 unique Collection Log slots`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  1448: {
    id: '1448',
    label: `5 Collection log slots`,
    description: `Obtain 5 unique Collection Log slots`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  1451: {
    id: '1451',
    label: `50 Collection log slots`,
    description: `Obtain 50 unique Collection Log slots`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  150: {
    id: '150',
    label: `75 Easy Clue Scrolls`,
    description: `Open 75 Reward caskets for completing easy clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  154: {
    id: '154',
    label: `75 Medium Clue Scrolls`,
    description: `Open 75 Reward caskets for completing medium clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  98: {
    id: '98',
    label: `75 Superior Slayer Encounters`,
    description: `Defeat 75 superior foes while on a Slayer Task`,
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  195: {
    id: '195',
    label: `Achieve Your First Level 30`,
    description: `Reach level 30 in any skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  196: {
    id: '196',
    label: `Achieve Your First Level 40`,
    description: `Reach level 40 in any skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  197: {
    id: '197',
    label: `Achieve Your First Level 50`,
    description: `Reach level 50 in any skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  198: {
    id: '198',
    label: `Achieve Your First Level 60`,
    description: `Reach level 60 in any skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  359: {
    id: '359',
    label: `Build a Mahogany Portal`,
    description: `Build a Mahogany Portal in a Portal Chamber in your Player Owned House`,
    skillReqs: [{ skill: 'Construction', level: 65 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  357: {
    id: '357',
    label: `Build a Room in Your Player Owned House`,
    description: `Build a room in your Player Owned House`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  294: {
    id: '294',
    label: `Build a Waka Canoe`,
    description: `Build a Waka Canoe`,
    skillReqs: [{ skill: 'Woodcutting', level: 57 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  360: {
    id: '360',
    label: `Build an Oak Larder`,
    description: `Build an Oak Larder in a Kitchen in your Player Owned House`,
    skillReqs: [{ skill: 'Construction', level: 33 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  374: {
    id: '374',
    label: `Burn 100 Willow Logs`,
    description: `Burn 100 Willow Logs`,
    skillReqs: [{ skill: 'Firemaking', level: 30 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  1821: {
    id: '1821',
    label: `Burn 25 Maple Logs`,
    description: `Burn 25 Maple Logs`,
    skillReqs: [{ skill: 'Firemaking', level: 45 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  11: {
    id: '11',
    label: `Burn Some Coloured Logs`,
    description: `Burn some logs that have been coloured with a firelighter`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  188: {
    id: '188',
    label: `Bury Some Wyvern or Dragon Bones`,
    description: `Bury either some Wyvern Bones or some Dragon Bones`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1567: {
    id: '1567',
    label: `Buy a candle in Lumbridge`,
    description: `Buy a candle in Lumbridge`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  434: {
    id: '434',
    label: `Cast a Blast Spell`,
    description: `Cast any blast spell`,
    skillReqs: [{ skill: 'Magic', level: 41 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  435: {
    id: '435',
    label: `Cast High Level Alchemy`,
    description: `Cast the High Level Alchemy spell`,
    skillReqs: [{ skill: 'Magic', level: 55 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1827: {
    id: '1827',
    label: `Catch 10 Pike`,
    description: `Catch 10 Pike`,
    skillReqs: [{ skill: 'Fishing', level: 25 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  343: {
    id: '343',
    label: `Catch 100 Lobsters`,
    description: `Catch 100 Raw Lobsters whilst Fishing`,
    skillReqs: [{ skill: 'Fishing', level: 40 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1825: {
    id: '1825',
    label: `Catch 25 Sardines`,
    description: `Catch 25 Sardines`,
    skillReqs: [{ skill: 'Fishing', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  345: {
    id: '345',
    label: `Catch 50 Salmon`,
    description: `Catch 50 Raw Salmon whilst Fishing`,
    skillReqs: [{ skill: 'Fishing', level: 30 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  344: {
    id: '344',
    label: `Catch 50 Swordfish`,
    description: `Catch 50 Raw Swordfish whilst Fishing`,
    skillReqs: [{ skill: 'Fishing', level: 50 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1614: {
    id: '1614',
    label: `Catch a Grey Chinchompa`,
    description: `Catch a Grey Chinchompa`,
    skillReqs: [{ skill: 'Hunter', level: 53 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  382: {
    id: '382',
    label: `Catch a Swamp Lizard or Salamander`,
    description: `Catch either a Swamp Lizard or any kind of Salamander`,
    skillReqs: [
      { skill: 'Hunter', level: 29 },
      { skill: 'Hunter', level: 47 },
      { skill: 'Hunter', level: 59 },
      { skill: 'Hunter', level: 67 },
      { skill: 'Hunter', level: 79 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  285: {
    id: '285',
    label: `Check a grown Fruit Tree`,
    description: `Check the health of any Fruit Tree you've grown`,
    skillReqs: [{ skill: 'Farming', level: 27 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  292: {
    id: '292',
    label: `Chop 100 Willow Logs`,
    description: `Chop 100 Willow Logs from Willow Trees`,
    skillReqs: [{ skill: 'Woodcutting', level: 30 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  293: {
    id: '293',
    label: `Chop Some Logs With a Rune Axe`,
    description: `Chop any kind of logs using a Rune Axe`,
    skillReqs: [{ skill: 'Woodcutting', level: 41 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1217: {
    id: '1217',
    label: `Chop some Rising Roots`,
    description: `Chop some Rising Roots spawned via Forestry`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1788: {
    id: '1788',
    label: `Clean 50 Grimy Cadantine`,
    description: `Clean 50 Grimy Cadantine`,
    skillReqs: [{ skill: 'Herblore', level: 65 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1786: {
    id: '1786',
    label: `Clean 50 Grimy Ranarr Weed`,
    description: `Clean 50 Grimy Ranarr Weed`,
    skillReqs: [{ skill: 'Herblore', level: 25 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  395: {
    id: '395',
    label: `Clean a Grimy Avantoe`,
    description: `Clean a Grimy Avantoe`,
    skillReqs: [{ skill: 'Herblore', level: 48 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1524: {
    id: '1524',
    label: `Complete 1 Slayer Task`,
    description: `Complete 1 Slayer Task`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1207: {
    id: '1207',
    label: `Complete 10 Mahogany homes contracts`,
    description: `Complete 10 Mahogany homes contracts`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1208: {
    id: '1208',
    label: `Complete 25 Mahogany homes contracts`,
    description: `Complete 25 Mahogany homes contracts`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  389: {
    id: '389',
    label: `Complete 50 Laps of a Rooftop Agility Course`,
    description: `Complete 50 laps of any Rooftop Agility Course`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1205: {
    id: '1205',
    label: `Complete a Mahogany homes adept contract`,
    description: `Complete a Mahogany Homes adept contract`,
    skillReqs: [{ skill: 'Construction', level: 50 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1203: {
    id: '1203',
    label: `Complete a Mahogany homes beginner contract`,
    description: `Complete a Mahogany Homes beginner contract`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1204: {
    id: '1204',
    label: `Complete a Mahogany homes novice contract`,
    description: `Complete a Mahogany Homes novice contract`,
    skillReqs: [{ skill: 'Construction', level: 20 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  763: {
    id: '763',
    label: `Complete the Easy Western Provinces Diary`,
    description: `Complete all of the easy tasks in the Western Provinces Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin', 'Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1621: {
    id: '1621',
    label: `Complete the Evil Bob random event`,
    description: `Complete the Evil Bob random event`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1219: {
    id: '1219',
    label: `Complete the Flowering Bush event`,
    description: `Complete the Flowering Bush event spawned via Forestry`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1625: {
    id: '1625',
    label: `Complete the Maze random event`,
    description: `Complete the Maze random event`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  764: {
    id: '764',
    label: `Complete the Medium Western Provinces Diary`,
    description: `Complete all of the medium tasks in the Western Provinces Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin', 'Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1519: {
    id: '1519',
    label: `Complete the Pheasant Forestry Event`,
    description: `Complete the Pheasant event spawned via Forestry`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1628: {
    id: '1628',
    label: `Complete the Pillory random event`,
    description: `Complete the Pillory random event`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1627: {
    id: '1627',
    label: `Complete the Pinball random event`,
    description: `Complete the Pinball random event`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1622: {
    id: '1622',
    label: `Complete the Postie Pete random event`,
    description: `Complete the Postie Pete random event`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1629: {
    id: '1629',
    label: `Complete the Prison Pete random event`,
    description: `Complete the Prison Pete random event`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1518: {
    id: '1518',
    label: `Complete the Ritual Forestry Event`,
    description: `Complete the Ritual event spawned via Forestry`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1218: {
    id: '1218',
    label: `Complete the Struggling Sapling event`,
    description: `Complete the Struggling Sapling event spawned via Forestry`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1631: {
    id: '1631',
    label: `Complete the Surprise Exam random event`,
    description: `Complete the Surprise Exam random event`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1826: {
    id: '1826',
    label: `Cook 10 Sardines`,
    description: `Cook 10 Raw Sardines`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  300: {
    id: '300',
    label: `Cook 100 Lobsters`,
    description: `Cook 100 Raw Lobsters`,
    skillReqs: [{ skill: 'Cooking', level: 40 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1828: {
    id: '1828',
    label: `Cook 20 Pike`,
    description: `Cook 20 Raw Pike`,
    skillReqs: [{ skill: 'Cooking', level: 20 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  367: {
    id: '367',
    label: `Craft 200 Essence Into Runes`,
    description: `Use Runecrafting Altars to craft 200 essence into runes of any type`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  368: {
    id: '368',
    label: `Craft 4 Runes With 1 Essence`,
    description: `Use a Runecrafting Altar to craft 4 of any type of rune using a single essence`,
    skillReqs: [{ skill: 'Runecraft', level: 57 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  336: {
    id: '336',
    label: `Craft a Ruby Amulet`,
    description: `Craft a Ruby Amulet`,
    skillReqs: [{ skill: 'Crafting', level: 50 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  335: {
    id: '335',
    label: `Craft an Emerald Ring`,
    description: `Craft an Emerald Ring`,
    skillReqs: [{ skill: 'Crafting', level: 27 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  369: {
    id: '369',
    label: `Craft Any Combination Rune`,
    description: `Use a Runecrafting Altar to craft any type of combination rune`,
    skillReqs: [{ skill: 'Runecraft', level: 6 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1351: {
    id: '1351',
    label: `Create a green d'hide shield`,
    description: `Create a green d'hide shield`,
    skillReqs: [{ skill: 'Crafting', level: 62 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1368: {
    id: '1368',
    label: `Create a Guthix Rest Tea`,
    description: `Create a Guthix Rest Tea`,
    skillReqs: [{ skill: 'Herblore', level: 18 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1820: {
    id: '1820',
    label: `Cut 50 Maple Logs`,
    description: `Cut 50 Maple Logs`,
    skillReqs: [{ skill: 'Woodcutting', level: 45 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  62: {
    id: '62',
    label: `Defeat a Lesser Demon`,
    description: `Defeat a Lesser Demon`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  322: {
    id: '322',
    label: `Defeat a Superior Slayer Creature`,
    description: `Defeat any Superior Slayer Creature`,
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  15: {
    id: '15',
    label: `Eat some Purple Sweets`,
    description: `Eat some Purple Sweets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  1335: {
    id: '1335',
    label: `Equip a frog mask`,
    description: `Equip a frog mask`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  418: {
    id: '418',
    label: `Equip a Full Adamant Set`,
    description: `Equip an Adamant Platebody, a Adamant Full Helm and either some Adamant Platelegs or an Adamant Plateskirt`,
    skillReqs: [{ skill: 'Defence', level: 30 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  423: {
    id: '423',
    label: `Equip a Full Blue Dragonhide Set`,
    description: `Equip a Blue Dragonhide Body, some Blue Dragonhide Chaps and some Blue Dragonhide Vambraces`,
    skillReqs: [
      { skill: 'Ranged', level: 50 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  422: {
    id: '422',
    label: `Equip a Full Green Dragonhide Set`,
    description: `Equip a Green Dragonhide Body, some Green Dragonhide Chaps and some Green Dragonhide Vambraces`,
    skillReqs: [
      { skill: 'Ranged', level: 40 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  417: {
    id: '417',
    label: `Equip a Full Mithril Set`,
    description: `Equip a Mithril Platebody, a Mithril Full Helm and either some Mithril Platelegs or a Mithril Plateskirt`,
    skillReqs: [{ skill: 'Defence', level: 20 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  424: {
    id: '424',
    label: `Equip a Full Red Dragonhide Set`,
    description: `Equip a Red Dragonhide Body, some Red Dragonhide Chaps and some Red Dragonhide Vambraces`,
    skillReqs: [
      { skill: 'Ranged', level: 60 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  593: {
    id: '593',
    label: `Equip a Granite Shield`,
    description: `Equip a Granite Shield`,
    skillReqs: [
      { skill: 'Defence', level: 50 },
      { skill: 'Strength', level: 50 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  1012: {
    id: '1012',
    label: `Equip a Lava Battlestaff`,
    description: `Equip a Lava Battlestaff`,
    skillReqs: [
      { skill: 'Attack', level: 30 },
      { skill: 'Magic', level: 30 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  616: {
    id: '616',
    label: `Equip a Leaf-Bladed Sword`,
    description: `Equip a Leaf-Bladed Sword`,
    skillReqs: [
      { skill: 'Attack', level: 50 },
      { skill: 'Slayer', level: 55 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  426: {
    id: '426',
    label: `Equip a Maple Shortbow`,
    description: `Equip a Maple Shortbow`,
    skillReqs: [{ skill: 'Ranged', level: 30 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  412: {
    id: '412',
    label: `Equip a Mithril Weapon`,
    description: `Equip any Mithril weapon`,
    skillReqs: [{ skill: 'Attack', level: 20 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  75: {
    id: '75',
    label: `Equip a Piece of a Mystic Set`,
    description: `Equip any piece of any Mystic robe set`,
    skillReqs: [
      { skill: 'Magic', level: 40 },
      { skill: 'Defence', level: 20 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1618: {
    id: '1618',
    label: `Equip a piece of Beekeeper's Outfit`,
    description: `Equip a piece of Beekeeper's Outfit`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1620: {
    id: '1620',
    label: `Equip a piece of Camouflage outfit`,
    description: `Equip a piece of Camouflage outfit`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1626: {
    id: '1626',
    label: `Equip a piece of Mime Outfit`,
    description: `Equip a piece of Mime Outfit`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1623: {
    id: '1623',
    label: `Equip a piece of Zombie Outfit`,
    description: `Equip a piece of Zombie Outfit`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  414: {
    id: '414',
    label: `Equip a Rune Weapon`,
    description: `Equip any Rune weapon`,
    skillReqs: [{ skill: 'Attack', level: 40 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  19: {
    id: '19',
    label: `Equip a Trimmed Amulet`,
    description: `Equip a Trimmed Amulet`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  1074: {
    id: '1074',
    label: `Equip a Willow Shield`,
    description: `Equip a Willow Shield`,
    skillReqs: [
      { skill: 'Defence', level: 30 },
      { skill: 'Fletching', level: 42 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  74: {
    id: '74',
    label: `Equip a Wizard Robe and Hat`,
    description: `Equip any wizard robe along with any wizard hat`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  427: {
    id: '427',
    label: `Equip a Yew Shortbow`,
    description: `Equip a Yew Shortbow`,
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  413: {
    id: '413',
    label: `Equip an Adamant Weapon`,
    description: `Equip any Adamant weapon`,
    skillReqs: [{ skill: 'Attack', level: 30 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  433: {
    id: '433',
    label: `Equip an Elemental Battlestaff or Mystic Staff`,
    description: `Equip either an elemental battlestaff or an elemental mystic staff`,
    skillReqs: [
      { skill: 'Magic', level: 30 },
      { skill: 'Attack', level: 30 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  381: {
    id: '381',
    label: `Equip full Graahk, Larupia or Kyatt`,
    description: `Equip a full set of Graahk, Larupia or Kyatt attire`,
    skillReqs: [
      { skill: 'Hunter', level: 38 },
      { skill: 'Hunter', level: 28 },
      { skill: 'Hunter', level: 52 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1073: {
    id: '1073',
    label: `Equip some Black armour`,
    description: `Equip either a Black Platebody, some Black Platelegs or a Black Full Helm`,
    skillReqs: [{ skill: 'Defence', level: 10 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  1072: {
    id: '1072',
    label: `Equip some Steel armour`,
    description: `Equip either a Steel Platebody, some Steel Platelegs or a Steel Full Helm`,
    skillReqs: [{ skill: 'Defence', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  29: {
    id: '29',
    label: `Fill 15 Hard Clue Collection Log Slots`,
    description: `Fill 15 slots in the Hard Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  35: {
    id: '35',
    label: `Fill 20 Easy Clue Collection Log Slots`,
    description: `Fill 20 slots in the Easy Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  34: {
    id: '34',
    label: `Fill 20 Medium Clue Collection Log Slots`,
    description: `Fill 20 slots in the Medium Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  27: {
    id: '27',
    label: `Fill 3 Elite Clue Collection Log Slots`,
    description: `Fill 3 slots in the Elite Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  32: {
    id: '32',
    label: `Fill 3 Hard Clue Collection Log Slots`,
    description: `Fill 3 slots in the Hard Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  39: {
    id: '39',
    label: `Fill 5 Beginner Clue Collection Log Slots`,
    description: `Fill 5 slots in the Beginner Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '',
  },
  38: {
    id: '38',
    label: `Fill 5 Easy Clue Collection Log Slots`,
    description: `Fill 5 slots in the Easy Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  33: {
    id: '33',
    label: `Fill 5 Medium Clue Collection Log Slots`,
    description: `Fill 5 slots in the Medium Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  284: {
    id: '284',
    label: `Fill a Bucket With Supercompost`,
    description: `Fill a Bucket with Supercompost from a Compost Bin`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  983: {
    id: '983',
    label: `Fill a Large Pouch`,
    description: `Fill a Large Pouch with Essence`,
    skillReqs: [{ skill: 'Runecraft', level: 50 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  361: {
    id: '361',
    label: `Fill a Medium S.T.A.S.H. Unit`,
    description: `Build a Medium S.T.A.S.H. unit and fill it with the relevant items`,
    skillReqs: [{ skill: 'Construction', level: 42 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  1530: {
    id: '1530',
    label: `Find the Needle`,
    description: `Dig in a hay stack for a needle`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1358: {
    id: '1358',
    label: `Fletch 1000 arrow shafts`,
    description: `Fletch 1000 arrow shafts`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  312: {
    id: '312',
    label: `Fletch 150 Iron Arrows`,
    description: `Fletch 150 Iron Arrows`,
    skillReqs: [{ skill: 'Fletching', level: 15 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1357: {
    id: '1357',
    label: `Fletch 25 Oak Stocks`,
    description: `Fletch 25 Oak Stocks`,
    skillReqs: [{ skill: 'Fletching', level: 24 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  313: {
    id: '313',
    label: `Fletch a Willow Shortbow (u)`,
    description: `Fletch a Willow Shortbow (u)`,
    skillReqs: [{ skill: 'Fletching', level: 35 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  315: {
    id: '315',
    label: `Fletch some Broad Arrows or Bolts`,
    description: `Fletch either some Broad Arrows or some Broad Bolts`,
    skillReqs: [{ skill: 'Fletching', level: 52 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  54: {
    id: '54',
    label: `Gain 10 Unique Items From Beginner Clues`,
    description: `Gain 10 unique items from Beginner Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '',
  },
  52: {
    id: '52',
    label: `Gain 10 Unique Items From Easy Clues`,
    description: `Gain 10 unique items from Easy Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  50: {
    id: '50',
    label: `Gain 10 Unique Items From Medium Clues`,
    description: `Gain 10 unique items from Medium Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  45: {
    id: '45',
    label: `Gain 20 Unique Items From Hard Clues`,
    description: `Gain 20 unique items from Hard Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  49: {
    id: '49',
    label: `Gain 35 Unique Items From Easy Clues`,
    description: `Gain 35 unique items from Easy Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  48: {
    id: '48',
    label: `Gain 5 Unique Items From Hard Clues`,
    description: `Gain 5 unique items from Hard Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  59: {
    id: '59',
    label: `Gain a Unique Item From a Beginner Clue`,
    description: `Gain a unique item from a Beginner Clue Scroll Reward Casket`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '',
  },
  55: {
    id: '55',
    label: `Gain a Unique Item From a Hard Clue`,
    description: `Gain a unique item from a Hard Clue Scroll Reward Casket`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  53: {
    id: '53',
    label: `Gain a Unique Item From a Master Clue`,
    description: `Gain a unique item from a Master Clue Scroll Reward Casket`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  58: {
    id: '58',
    label: `Gain a Unique Item From a Medium Clue`,
    description: `Gain a unique item from a Medium Clue Scroll Reward Casket`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  57: {
    id: '57',
    label: `Gain a Unique Item From an Easy Clue`,
    description: `Gain a unique item from an Easy Clue Scroll Reward Casket`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  283: {
    id: '283',
    label: `Harvest an Irit Leaf`,
    description: `Harvest an Irit Leaf from any Herb patch`,
    skillReqs: [{ skill: 'Farming', level: 44 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1580: {
    id: '1580',
    label: `Kill 5 Bunnies`,
    description: `Kill 5 Bunnies`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1280: {
    id: '1280',
    label: `Kill three chickens in 6 seconds`,
    description: `Kill three chickens in 6 seconds`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1538: {
    id: '1538',
    label: `Land a hoop on a stick`,
    description: `Successfully land a hoop on a stick in the PoH minigame`,
    skillReqs: [{ skill: 'Construction', level: 30 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  376: {
    id: '376',
    label: `Light a Bullseye Lantern`,
    description: `Light a Bullseye Lantern`,
    skillReqs: [{ skill: 'Firemaking', level: 49 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  778: {
    id: '778',
    label: `Make 20 Stamina Potions`,
    description: `Make 20 Stamina Potions`,
    skillReqs: [{ skill: 'Herblore', level: 77 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  394: {
    id: '394',
    label: `Make 30 Prayer Potions`,
    description: `Make 30 Prayer Potions`,
    skillReqs: [{ skill: 'Herblore', level: 38 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  396: {
    id: '396',
    label: `Make a 4 dose potion`,
    description: `Make any 4 dose potion using an Amulet of Chemistry`,
    skillReqs: [
      { skill: 'Magic', level: 27 },
      { skill: 'Crafting', level: 34 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  301: {
    id: '301',
    label: `Make a Pineapple Pizza`,
    description: `Make a Pineapple Pizza`,
    skillReqs: [{ skill: 'Cooking', level: 65 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1154: {
    id: '1154',
    label: `Make some Flour`,
    description: `Make some Flour in a windmill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1372: {
    id: '1372',
    label: `Mine 15 coal`,
    description: `Mine 15 coal`,
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  306: {
    id: '306',
    label: `Mine 50 Iron Ore`,
    description: `Mine 50 Iron Ore`,
    skillReqs: [{ skill: 'Mining', level: 15 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1823: {
    id: '1823',
    label: `Mine 50 Mithril Ore`,
    description: `Mine 50 Mithril Ore`,
    skillReqs: [{ skill: 'Mining', level: 55 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  307: {
    id: '307',
    label: `Mine some Ore With a Rune Pickaxe`,
    description: `Mine any ore using a Rune Pickaxe`,
    skillReqs: [{ skill: 'Mining', level: 41 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  277: {
    id: '277',
    label: `Obtain 800 Coins From Coin Pouches At Once`,
    description: `Open a stack of Coin Pouches and obtain at least 800 Coins`,
    skillReqs: [{ skill: 'Thieving', level: 40 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  308: {
    id: '308',
    label: `Obtain a Clue Geode While Mining`,
    description: `Obtain any kind of clue geode whilst Mining a rock`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  305: {
    id: '305',
    label: `Obtain a Gem While Mining`,
    description: `Obtain any kind of gem whilst Mining a rock`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  386: {
    id: '386',
    label: `Obtain a Mark of Grace`,
    description: `Obtain a Mark of Grace from any Rooftop Agility Course`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  274: {
    id: '274',
    label: `Open 28 Coin Pouches At Once`,
    description: `Open 28 Coin Pouches at once`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1630: {
    id: '1630',
    label: `Open the Mystery Box`,
    description: `A boat is a boat, but the mystery box could be anything, even a boat!`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  86: {
    id: '86',
    label: `Pickpocket a Master Farmer`,
    description: `Successfully pickpocket from a Master farmer`,
    skillReqs: [{ skill: 'Thieving', level: 38 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  134: {
    id: '134',
    label: `Reach a Prayer Bonus of 15`,
    description: `Equip enough item to reach a Prayer bonus of 15 or more`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  216: {
    id: '216',
    label: `Reach Base Level 10`,
    description: `Reach level 10 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  217: {
    id: '217',
    label: `Reach Base Level 20`,
    description: `Reach level 20 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  218: {
    id: '218',
    label: `Reach Base Level 30`,
    description: `Reach level 30 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  215: {
    id: '215',
    label: `Reach Base Level 5`,
    description: `Reach level 5 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  403: {
    id: '403',
    label: `Reach Combat Level 50`,
    description: `Reach Combat Level 50`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '',
  },
  404: {
    id: '404',
    label: `Reach Combat Level 75`,
    description: `Reach Combat Level 75`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '',
  },
  203: {
    id: '203',
    label: `Reach Total Level 100`,
    description: `Reach a Total Level of 100`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  204: {
    id: '204',
    label: `Reach Total Level 250`,
    description: `Reach a Total Level of 250`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  205: {
    id: '205',
    label: `Reach Total Level 500`,
    description: `Reach a Total Level of 500`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  206: {
    id: '206',
    label: `Reach Total Level 750`,
    description: `Reach a Total Level of 750`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  1304: {
    id: '1304',
    label: `Read a prayer book near a lectern`,
    description: `Read a Prayer book near a lectern`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1330: {
    id: '1330',
    label: `Scrape some blue dragonhide`,
    description: `Scrape some blue dragonhide`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  320: {
    id: '320',
    label: `Slay 250 Creatures`,
    description: `Slay 250 creatures whilst on a Slayer Task`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  352: {
    id: '352',
    label: `Smelt a Steel Bar`,
    description: `Use a Furnace to smelt a Steel Bar`,
    skillReqs: [{ skill: 'Smithing', level: 30 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1375: {
    id: '1375',
    label: `Smith 10 Steel bolts (unf)`,
    description: `Use an Anvil to smith 10 Steel bolts (unf)`,
    skillReqs: [{ skill: 'Smithing', level: 33 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  349: {
    id: '349',
    label: `Smith 150 Iron Arrowtips`,
    description: `Use an Anvil to smith 150 Iron Arrowtips`,
    skillReqs: [{ skill: 'Smithing', level: 20 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1824: {
    id: '1824',
    label: `Smith 250 Mithril bolts (unf)`,
    description: `Use an Anvil to smith 250 Mithril bolts (unf)`,
    skillReqs: [{ skill: 'Smithing', level: 53 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  351: {
    id: '351',
    label: `Smith a Steel Platebody`,
    description: `Use an Anvil to smith a Steel Platebody`,
    skillReqs: [{ skill: 'Smithing', level: 48 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  298: {
    id: '298',
    label: `Successfully Cook 5 Pieces of Food`,
    description: `Cook 5 pieces of food in a row without burning them`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  432: {
    id: '432',
    label: `Teleport Using Law Runes`,
    description: `Cast any teleport spell that uses Law Runes`,
    skillReqs: [
      { skill: 'Magic', level: 25 },
      { skill: 'Magic', level: 6 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1624: {
    id: '1624',
    label: `Trade a herb with Jekyll`,
    description: `Trade a herb with Jekyll for a potion`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  387: {
    id: '387',
    label: `Use the Protect from Melee Prayer`,
    description: `Use the Protect from Melee Prayer`,
    skillReqs: [{ skill: 'Prayer', level: 43 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  804: {
    id: '804',
    label: `Charge an Amulet of Glory in the Heroes' Guild`,
    description: `Charge an Amulet of Glory at the Fountain of Heroes in the Heroes' Guild`,
    skillReqs: [{ skill: 'Hunter', level: 83 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  828: {
    id: '828',
    label: `Complete a Game of Intermediate Pest Control`,
    description: `Complete a game of Intermediate Pest Control or higher`,
    skillReqs: [{ skill: 'Combat', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  827: {
    id: '827',
    label: `Complete a Game of Novice Pest Control`,
    description: `Complete a game of Novice Pest Control or higher`,
    skillReqs: [{ skill: 'Combat', level: 40 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  815: {
    id: '815',
    label: `Complete the Easy Falador Diary`,
    description: `Complete all of the easy tasks in the Falador Achievement Diary`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  768: {
    id: '768',
    label: `Complete the Falador Agility Course`,
    description: `Complete a lap of the Falador Rooftop Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 50 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  816: {
    id: '816',
    label: `Complete the Medium Falador Diary`,
    description: `Complete all of the medium tasks in the Falador Achievement Diary`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1813: {
    id: '1813',
    label: `Consume a Saradomin's Light`,
    description: `Consume a Saradomin's Light`,
    skillReqs: [{ skill: 'Agility', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  775: {
    id: '775',
    label: `Craft a Body Rune`,
    description: `Craft a Body Rune from Essence at the Body Altar`,
    skillReqs: [{ skill: 'Runecraft', level: 20 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  805: {
    id: '805',
    label: `Defeat a Black Demon in Asgarnia`,
    description: `Defeat a Black Demon in Asgarnia`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  803: {
    id: '803',
    label: `Defeat a Blue Dragon in Asgarnia`,
    description: `Defeat a Blue Dragon in Asgarnia`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  787: {
    id: '787',
    label: `Defeat a Skeletal Wyvern`,
    description: `Defeat a Skeletal Wyvern in the Asgarnian Ice Dungeon`,
    skillReqs: [{ skill: 'Slayer', level: 72 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1331: {
    id: '1331',
    label: `Defeat the Evil chicken`,
    description: `Defeat the Evil chicken`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  782: {
    id: '782',
    label: `Defeat the Giant Mole`,
    description: `Defeat the Giant Mole beneath Falador`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '',
  },
  813: {
    id: '813',
    label: `Defeat the Giant Mole 150 Times`,
    description: `Defeat the Giant Mole beneath Falador 150 times`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '',
  },
  812: {
    id: '812',
    label: `Defeat the Giant Mole 50 Times`,
    description: `Defeat the Giant Mole beneath Falador 50 times`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '',
  },
  773: {
    id: '773',
    label: `Enter the Crafting Guild`,
    description: `Enter the Crafting Guild`,
    skillReqs: [{ skill: 'Crafting', level: 40 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  824: {
    id: '824',
    label: `Enter the Warriors' Guild`,
    description: `Enter the Warriors' Guild`,
    skillReqs: [{ skill: 'Attack', level: 99 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1812: {
    id: '1812',
    label: `Equip a Defender`,
    description: `Equip a Defender`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1251: {
    id: '1251',
    label: `Equip Amy's Saw`,
    description: `Obtain and Equip Amy's Saw from Mahogany Homes`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1075: {
    id: '1075',
    label: `Equip an Imcando Hammer`,
    description: `Equip an Imcando hammer`,
    skillReqs: [{ skill: 'Mining', level: 14 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1550: {
    id: '1550',
    label: `Hang a Painting of a Watermill`,
    description: `Hang a Painting of Lumbridge Watermill in your PoH`,
    skillReqs: [{ skill: 'Construction', level: 44 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  779: {
    id: '779',
    label: `Harvest Any Herb at the Troll Stronghold`,
    description: `Harvest any herb you've grown at the Troll Stronghold`,
    skillReqs: [{ skill: 'Farming', level: 9 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1617: {
    id: '1617',
    label: `Kill 5 Spinners`,
    description: `Kill 5 Spinners in Pest Control`,
    skillReqs: [{ skill: 'Combat', level: 40 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  1338: {
    id: '1338',
    label: `Make 50 Ancient Brews`,
    description: `Make 50 Ancient Brews`,
    skillReqs: [{ skill: 'Herblore', level: 85 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  771: {
    id: '771',
    label: `Obtain 20 Golden Nuggets`,
    description: `Obtain 20 Golden Nuggets from the Motherlode Mine beneath Falador`,
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1252: {
    id: '1252',
    label: `Obtain the Plank Sack`,
    description: `Obtain the Plank Sack from Mahogany Homes`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1076: {
    id: '1076',
    label: `Open an Ornate Lockbox`,
    description: `Open an Ornate Lockbox from the Camdozaal Vault`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  830: {
    id: '830',
    label: `Open the Crystal Chest`,
    description: `Open the Crystal Chest in Taverley`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  711: {
    id: '711',
    label: `Set Up a Dwarf Cannon`,
    description: `Set Up a Dwarf Cannon`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1543: {
    id: '1543',
    label: `Throw a Shot Put 12 yards`,
    description: `Throw a Shot Put 12 yards in the Warriors Guild`,
    skillReqs: [{ skill: 'Attack', level: 99 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  822: {
    id: '822',
    label: `Turn in 100 Mole Claws to Wyson the Gardener`,
    description: `Turn in 100 Mole Claws to Wyson the Gardener in Falador`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '',
  },
  821: {
    id: '821',
    label: `Unlock a Gate in Taverley Dungeon`,
    description: `Unlock a gate in Taverley Dungeon using the Dusty Key`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1085: {
    id: '1085',
    label: `10 Tempoross Kills`,
    description: `Help the Spirit Anglers subdue the Tempoross 10 times`,
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  868: {
    id: '868',
    label: `Cast Ice Rush`,
    description: `Cast the Ice Rush spell`,
    skillReqs: [{ skill: 'Magic', level: 58 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  859: {
    id: '859',
    label: `Catch 30 Orange Salamanders`,
    description: `Catch 30 Orange Salamanders at Uzer`,
    skillReqs: [{ skill: 'Hunter', level: 47 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1319: {
    id: '1319',
    label: `Commune a Pharoah's Sceptre to the Necropolis`,
    description: `Commune a Pharoah's Sceptre to the Necropolis`,
    skillReqs: [{ skill: 'Thieving', level: 21 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  896: {
    id: '896',
    label: `Complete Shadow of the Storm`,
    description: `Complete the Shadow of the Storm quest`,
    skillReqs: [{ skill: 'Crafting', level: 30 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1407: {
    id: '1407',
    label: `Complete Sleeping Giants`,
    description: `Complete Sleeping Giants quest`,
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  886: {
    id: '886',
    label: `Complete Spirits of the Elid`,
    description: `Complete the Spirits of the Elid quest`,
    skillReqs: [
      { skill: 'Magic', level: 33 },
      { skill: 'Ranged', level: 37 },
      { skill: 'Mining', level: 37 },
      { skill: 'Thieving', level: 37 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  877: {
    id: '877',
    label: `Complete the Easy Desert Diary`,
    description: `Complete all of the easy tasks in the Desert Achievement Diary`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  897: {
    id: '897',
    label: `Complete The Golem`,
    description: `Complete The Golem quest`,
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Thieving', level: 25 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  878: {
    id: '878',
    label: `Complete the Medium Desert Diary`,
    description: `Complete all of the medium tasks in the Desert Achievement Diary`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  855: {
    id: '855',
    label: `Craft a Lava Rune at the Fire Altar`,
    description: `Craft a Lava Rune at the Fire Altar`,
    skillReqs: [{ skill: 'Runecraft', level: 23 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1572: {
    id: '1572',
    label: `Craft some Pottery In Sophanem`,
    description: `Craft some Pottery In Sophanem`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1237: {
    id: '1237',
    label: `Create the Divine Rune pouch`,
    description: `Create the Divine Rune pouch`,
    skillReqs: [{ skill: 'Crafting', level: 75 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1044: {
    id: '1044',
    label: `Defeat a Crocodile`,
    description: `Defeat a Crocodile in the Kharidian Desert`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  865: {
    id: '865',
    label: `Defeat a Kalphite Guardian`,
    description: `Defeat a Kalphite Guardian in the Kharidian Desert`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1247: {
    id: '1247',
    label: `Equip the Colossal Blade`,
    description: `Obtain and Equip the Colossal Blade from Giant's Foundry`,
    skillReqs: [
      { skill: 'Smithing', level: 15 },
      { skill: 'Attack', level: 60 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1250: {
    id: '1250',
    label: `Equip the Tome of Water`,
    description: `Obtain and Equip the Tome of Water from Tempoross`,
    skillReqs: [
      { skill: 'Fishing', level: 35 },
      { skill: 'Magic', level: 50 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  1214: {
    id: '1214',
    label: `Giants' Foundry 10 handins`,
    description: `Hand in 10 successful swords to Kovac`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1212: {
    id: '1212',
    label: `Giants' Foundry 100 quality sword`,
    description: `Hand in a sword with at least 100 quality in Giants' Foundry`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1211: {
    id: '1211',
    label: `Giants' Foundry 50 quality sword`,
    description: `Hand in a sword with at least 50 quality in Giants' Foundry`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1190: {
    id: '1190',
    label: `Guardians of the Rift 1 Rift closed`,
    description: `Close the Rift in the Temple of the Eye`,
    skillReqs: [{ skill: 'Runecraft', level: 27 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1191: {
    id: '1191',
    label: `Guardians of the Rift 10 Rifts closed`,
    description: `Close the Rift in the Temple of the Eye 10 times`,
    skillReqs: [{ skill: 'Runecraft', level: 27 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1570: {
    id: '1570',
    label: `Kill 10 Locusts`,
    description: `Kill 10 Locusts`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1574: {
    id: '1574',
    label: `Kill 30 Bandits`,
    description: `Kill 30 Bandits`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1220: {
    id: '1220',
    label: `Kill a Kalphite with the Keris Partisan`,
    description: `Kill a Kalphite with the Keris Partisan`,
    skillReqs: [{ skill: 'Attack', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1046: {
    id: '1046',
    label: `Make a Combat Potion`,
    description: `Make a Combat Potion`,
    skillReqs: [{ skill: 'Herblore', level: 36 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1571: {
    id: '1571',
    label: `Mine 15 Granite in the Necropolis`,
    description: `Mine 15 Granite in the Necropolis`,
    skillReqs: [{ skill: 'Mining', level: 45 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  860: {
    id: '860',
    label: `Mine 30 Chunks of Granite`,
    description: `Mine 30 chunks of Granite at the Kharidian Desert Quarry`,
    skillReqs: [{ skill: 'Mining', level: 45 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1249: {
    id: '1249',
    label: `Obtain the Big Harpoonfish`,
    description: `Obtain the Big Harpoonfish from Tempoross`,
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  844: {
    id: '844',
    label: `Pick a Autumn Sq'irk`,
    description: `Pick a Autumn Sq'irk in the Sorceress's Garden`,
    skillReqs: [{ skill: 'Thieving', level: 45 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  843: {
    id: '843',
    label: `Pick a Spring Sq'irk`,
    description: `Pick a Spring Sq'irk in the Sorceress's Garden`,
    skillReqs: [{ skill: 'Thieving', level: 25 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  850: {
    id: '850',
    label: `Pickpocket a Bandit in the Bandit Camp`,
    description: `Pickpocket a Bandit in the Kharidian Desert's Bandit Camp`,
    skillReqs: [{ skill: 'Thieving', level: 53 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  890: {
    id: '890',
    label: `Pray at the Elidinis Statuette`,
    description: `Pray at the Elidinis Statuette in Nardah`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  834: {
    id: '834',
    label: `Room 4 of Pyramid Plunder`,
    description: `Search the Golden Chest in Room 4 of Pyramid Plunder in Sophanem`,
    skillReqs: [{ skill: 'Thieving', level: 51 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  835: {
    id: '835',
    label: `Room 5 of Pyramid Plunder`,
    description: `Search the Golden Chest in Room 5 of Pyramid Plunder in Sophanem`,
    skillReqs: [{ skill: 'Thieving', level: 61 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  836: {
    id: '836',
    label: `Room 6 of Pyramid Plunder`,
    description: `Search the Golden Chest in Room 6 of Pyramid Plunder in Sophanem`,
    skillReqs: [{ skill: 'Thieving', level: 71 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1534: {
    id: '1534',
    label: `Stun a monster`,
    description: `Use a blackjack to stun a monster`,
    skillReqs: [
      { skill: 'Defence', level: 30 },
      { skill: 'Thieving', level: 30 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  847: {
    id: '847',
    label: `Turn in 10 Spring Sq'irkjuices to Osman`,
    description: `Turn in 10 Spring Sq'irkjuices to Osman in Al Kharid in one go`,
    skillReqs: [{ skill: 'Thieving', level: 25 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  853: {
    id: '853',
    label: `Turn in a Pyramid Top to Simon Templeton`,
    description: `Turn in a Pyramid Top to Simon Templeton at the Agility Pyramid`,
    skillReqs: [{ skill: 'Agility', level: 30 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1833: {
    id: '1833',
    label: `Catch a Sabre-toothed Kebbit`,
    description: `Catch a Sabre-toothed Kebbit`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1584: {
    id: '1584',
    label: `Catch a Sapphire Glacialis`,
    description: `Catch a Sapphire Glacialis`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  577: {
    id: '577',
    label: `Catch a Snowy Knight`,
    description: `Catch a Snowy Knight`,
    skillReqs: [{ skill: 'Hunter', level: 35 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  633: {
    id: '633',
    label: `Complete Royal Trouble`,
    description: `Complete the Royal Trouble quest`,
    skillReqs: [
      { skill: 'Agility', level: 40 },
      { skill: 'Slayer', level: 40 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  623: {
    id: '623',
    label: `Complete the Easy Fremennik Diary`,
    description: `Complete all of the easy tasks in the Fremennik Achievement Diary`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  624: {
    id: '624',
    label: `Complete the Medium Fremennik Diary`,
    description: `Complete all of the medium tasks in the Fremennik Achievement Diary`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  574: {
    id: '574',
    label: `Complete the Penguin Agility Course`,
    description: `Complete a lap of the Penguin Agility Course`,
    skillReqs: [
      { skill: 'Agility', level: 30 },
      { skill: 'Construction', level: 34 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  628: {
    id: '628',
    label: `Complete Throne of Miscellania`,
    description: `Complete the Throne of Miscellania quest`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  573: {
    id: '573',
    label: `Craft 50 Astral Runes`,
    description: `Craft 50 Astral Runes from Essence at the Astral Altar`,
    skillReqs: [{ skill: 'Runecraft', level: 40 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  598: {
    id: '598',
    label: `Defeat a Dagannoth in the Fremennik Province`,
    description: `Defeat a Dagannoth in the Fremennik Province`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  611: {
    id: '611',
    label: `Defeat a Jelly in the Fremennik Province`,
    description: `Defeat a Jelly in the Fremennik Province`,
    skillReqs: [{ skill: 'Slayer', level: 52 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  589: {
    id: '589',
    label: `Defeat a Kurask in the Fremennik Province`,
    description: `Defeat a Kurask in the Fremennik Province`,
    skillReqs: [{ skill: 'Slayer', level: 70 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  594: {
    id: '594',
    label: `Defeat a Troll in the Fremennik Province`,
    description: `Defeat a Troll in the Fremennik Province`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  606: {
    id: '606',
    label: `Defeat a Turoth in the Fremennik Province`,
    description: `Defeat a Turoth in the Fremennik Province`,
    skillReqs: [{ skill: 'Slayer', level: 55 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  627: {
    id: '627',
    label: `Deposit an Item Using Peer the Seer`,
    description: `Deposit an item into your Bank using Peer the Seer`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1836: {
    id: '1836',
    label: `Equip a Damaged God book`,
    description: `Equip a Damaged God book`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1291: {
    id: '1291',
    label: `Equip a full set of Yakhide Armour`,
    description: `Equip a full set of Yakhide Armour`,
    skillReqs: [
      { skill: 'Crafting', level: 46 },
      { skill: 'Defence', level: 20 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  603: {
    id: '603',
    label: `Equip a Helm of Neitiznot`,
    description: `Equip a Helm of Neitiznot`,
    skillReqs: [{ skill: 'Defence', level: 55 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  1583: {
    id: '1583',
    label: `Fill up 20 buckets of sand in Rellekka`,
    description: `Fill up 20 buckets of sand in Rellekka`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1293: {
    id: '1293',
    label: `Kill 8 penguins within 5 seconds`,
    description: `Kill 8 penguins within 5 seconds`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1807: {
    id: '1807',
    label: `Kill a Brine Rat`,
    description: `Kill a Brine Rat`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1843: {
    id: '1843',
    label: `Kill a Suqah`,
    description: `Kill a Suqah`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1841: {
    id: '1841',
    label: `Kill a Wallasalki`,
    description: `Kill a Wallasalki`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1838: {
    id: '1838',
    label: `Loot a Lyre`,
    description: `Kill someone and obtain their Lyre`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1802: {
    id: '1802',
    label: `Open a Frozen Cache`,
    description: `Open a Frozen cache`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  1844: {
    id: '1844',
    label: `Steal a Chisel`,
    description: `Steal a Chisel from a crafting stall in Keldagrim`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1582: {
    id: '1582',
    label: `Steal a Cow bell in Rellekka`,
    description: `Steal a Cow bell in Rellekka`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1834: {
    id: '1834',
    label: `Steal a Fish`,
    description: `Steal some fish from a Fish stall`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1835: {
    id: '1835',
    label: `Steal a Wooden Stock`,
    description: `Steal a Wooden Stock from a Crossbow Stall`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  579: {
    id: '579',
    label: `Unlock Free Use of the Blast Furnace`,
    description: `Unlock free use of the Keldagrim Blast Furnace by speaking with the Foreman`,
    skillReqs: [{ skill: 'Smithing', level: 60 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  580: {
    id: '580',
    label: `Use Some Icy Basalt to Teleport to Weiss`,
    description: `Use some Icy Basalt to teleport to Weiss`,
    skillReqs: [{ skill: 'Mining', level: 72 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  612: {
    id: '612',
    label: `Use the Special Attack of a Dragon Axe`,
    description: `Use the special attack of a Dragon Axe`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  696: {
    id: '696',
    label: `Catch a Monkfish`,
    description: `Catch a Monkfish at the Piscatoris Fishing Colony`,
    skillReqs: [{ skill: 'Fishing', level: 62 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  694: {
    id: '694',
    label: `Catch a Red Salamander`,
    description: `Catch a Red Salamander outside the Ourania Altar`,
    skillReqs: [{ skill: 'Hunter', level: 59 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  698: {
    id: '698',
    label: `Complete a Fishing Trawler Game`,
    description: `Complete a Fishing Trawler game at Port Khazard`,
    skillReqs: [{ skill: 'Fishing', level: 15 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1155: {
    id: '1155',
    label: `Complete Fishing Contest`,
    description: `Complete the Fishing Contest Quest`,
    skillReqs: [{ skill: 'Fishing', level: 10 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  741: {
    id: '741',
    label: `Complete Monk's Friend`,
    description: `Complete the Monk's Friend quest`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  745: {
    id: '745',
    label: `Complete Sea Slug`,
    description: `Complete the Sea Slug quest`,
    skillReqs: [{ skill: 'Firemaking', level: 30 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  687: {
    id: '687',
    label: `Complete the Barbarian Outpost Agility Course`,
    description: `Complete a lap of the Barbarian Outpost Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 35 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1053: {
    id: '1053',
    label: `Complete the Easy Ardougne Diary`,
    description: `Complete all of the easy tasks in the Ardougne Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  759: {
    id: '759',
    label: `Complete the Easy Kandarin Diary`,
    description: `Complete all of the easy tasks in the Kandarin Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1054: {
    id: '1054',
    label: `Complete the Medium Ardougne Diary`,
    description: `Complete all of the medium tasks in the Ardougne Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  760: {
    id: '760',
    label: `Complete the Medium Kandarin Diary`,
    description: `Complete all of the medium tasks in the Kandarin Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  746: {
    id: '746',
    label: `Complete the Observatory Quest`,
    description: `Complete the Observatory Quest`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  743: {
    id: '743',
    label: `Complete Tower of Life`,
    description: `Complete the Tower of Life quest`,
    skillReqs: [{ skill: 'Construction', level: 10 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  708: {
    id: '708',
    label: `Defeat a Tortoise With Riders in Kandarin`,
    description: `Defeat a Tortoise with riders in Kandarin`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  719: {
    id: '719',
    label: `Defeat the Thermonuclear Smoke Devil`,
    description: `Defeat the Thermonuclear Smoke Devil in the Smoke Devil Dungeon`,
    skillReqs: [{ skill: 'Slayer', level: 93 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.THERMY,
    prerequisite: '',
  },
  1153: {
    id: '1153',
    label: `Drink a Poison Chalice`,
    description: `Drink a Poison Chalice`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  695: {
    id: '695',
    label: `Enter the Fishing Guild`,
    description: `Enter the Fishing Guild`,
    skillReqs: [{ skill: 'Fishing', level: 68 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  714: {
    id: '714',
    label: `Enter the Ranging Guild`,
    description: `Enter the Ranging Guild`,
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  717: {
    id: '717',
    label: `Enter the Wizards' Guild`,
    description: `Enter the Wizards' Guild in Yanille`,
    skillReqs: [{ skill: 'Magic', level: 66 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  709: {
    id: '709',
    label: `Equip a Dragon Scimitar`,
    description: `Equip a Dragon Scimitar`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  749: {
    id: '749',
    label: `Equip a Marksman Chompy Hat`,
    description: `Equip a Marksman Chompy Hat`,
    skillReqs: [
      { skill: 'Fletching', level: 5 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CHOMPIES,
    prerequisite: '',
  },
  754: {
    id: '754',
    label: `Equip a Monkey Backpack`,
    description: `Equip a Monkey Backpack`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  693: {
    id: '693',
    label: `Equip a Spottier Cape`,
    description: `Equip a Spottier Cape`,
    skillReqs: [{ skill: 'Hunter', level: 69 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  750: {
    id: '750',
    label: `Equip an Ogre Forester Chompy Hat`,
    description: `Equip an Ogre Forester Chompy Hat`,
    skillReqs: [
      { skill: 'Fletching', level: 5 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CHOMPIES,
    prerequisite: '',
  },
  1535: {
    id: '1535',
    label: `Get 250 Target points`,
    description: `Achieve 250 points in the Target minigame in the Ranging Guild`,
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1442: {
    id: '1442',
    label: `Kill a Newtroost`,
    description: `Kill a Newtroost underneath the Tower of Life`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1441: {
    id: '1441',
    label: `Kill a Swordchick`,
    description: `Kill a Swordchick underneath the Tower of Life`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1815: {
    id: '1815',
    label: `Light a Pyre Ship`,
    description: `Light a Barbarian Pyre Ship`,
    skillReqs: [
      { skill: 'Firemaking', level: 11 },
      { skill: 'Crafting', level: 11 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  701: {
    id: '701',
    label: `Move Your House to Yanille`,
    description: `Move your Player Owned House to Yanille`,
    skillReqs: [{ skill: 'Construction', level: 50 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  684: {
    id: '684',
    label: `Pickpocket a Knight of Ardougne 50 Times`,
    description: `Pickpocket a Knight of Ardougne 50 times`,
    skillReqs: [{ skill: 'Thieving', level: 55 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1444: {
    id: '1444',
    label: `Sell 20 Iron Sheets`,
    description: `Sell 20 Iron Sheets to Franklin Caranos`,
    skillReqs: [{ skill: 'Smithing', level: 45 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1817: {
    id: '1817',
    label: `Steal from a Fur Stall`,
    description: `Steal from a Fur Stall in Kandarin`,
    skillReqs: [{ skill: 'Thieving', level: 35 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1818: {
    id: '1818',
    label: `Steal from a Silver Stall`,
    description: `Steal from a Silver Stall in Kandarin`,
    skillReqs: [{ skill: 'Thieving', level: 50 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1819: {
    id: '1819',
    label: `Steal from a Spice Stall`,
    description: `Steal from a Spice Stall in Kandarin`,
    skillReqs: [{ skill: 'Thieving', level: 65 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  691: {
    id: '691',
    label: `Trap a Spined Larupia in the Feldip Hills`,
    description: `Trap a Spined Larupia in the Feldip Hills`,
    skillReqs: [{ skill: 'Hunter', level: 31 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1527: {
    id: '1527',
    label: `Use a Herring on a mighty tree`,
    description: `Use a herring on the mightiest of trees`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  738: {
    id: '738',
    label: `Win a Game of Castle Wars`,
    description: `Win a game of Castle Wars`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  639: {
    id: '639',
    label: `Buy a Snapdragon From Pirate Jackie the Fruit`,
    description: `Buy a Snapdragon From Pirate Jackie the Fruit in Brimhaven`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  646: {
    id: '646',
    label: `Catch 50 Karambwan`,
    description: `Catch 50 Karambwan on Karamja`,
    skillReqs: [{ skill: 'Fishing', level: 65 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  645: {
    id: '645',
    label: `Catch a Salmon on Karamja`,
    description: `Catch a Salmon on Karamja`,
    skillReqs: [{ skill: 'Fishing', level: 30 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  671: {
    id: '671',
    label: `Complete the Easy Karamja Diary`,
    description: `Complete all of the easy tasks in the Karamja Achievement Diary`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  672: {
    id: '672',
    label: `Complete the Medium Karamja Diary`,
    description: `Complete all of the medium tasks in the Karamja Achievement Diary`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  648: {
    id: '648',
    label: `Craft 50 Nature Runes`,
    description: `Craft 50 Nature Runes from Essence at the Nature Altar`,
    skillReqs: [{ skill: 'Runecraft', level: 44 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  658: {
    id: '658',
    label: `Defeat a Greater Demon on Karamja`,
    description: `Defeat a Greater Demon on Karamja`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  670: {
    id: '670',
    label: `Defeat a Steel Dragon on Karamja`,
    description: `Defeat a Steel Dragon on Karamja`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  650: {
    id: '650',
    label: `Defeat a TzHaar`,
    description: `Defeat any TzHaar in Mor Ul Rek`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  683: {
    id: '683',
    label: `Enter the Brimhaven Dungeon`,
    description: `Enter the Brimhaven Dungeon`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  676: {
    id: '676',
    label: `Enter the Tai Bwo Wannai Hardwood Grove`,
    description: `Enter the Hardwood Grove in Tai Bwo Wannai`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  656: {
    id: '656',
    label: `Equip a Toktz-Ket-Xil`,
    description: `Equip a Toktz-Ket-Xil`,
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  662: {
    id: '662',
    label: `Equip a Toktz-Xil-Ak`,
    description: `Equip a Toktz-Xil-Ak`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  663: {
    id: '663',
    label: `Equip a Toktz-Xil-Ek`,
    description: `Equip a Toktz-Xil-Ek`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  651: {
    id: '651',
    label: `Equip an Obsidian Cape`,
    description: `Equip an Obsidian Cape`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1529: {
    id: '1529',
    label: `Sleep in Paramaya Inn`,
    description: `Pay the barkeep to sleep in Paramaya Inn, in Shilo Village`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  640: {
    id: '640',
    label: `Take a Shortcut Across the Shilo Village River`,
    description: `Use the Stepping Stones Agility Shortcut in Shilo Village`,
    skillReqs: [{ skill: 'Agility', level: 32 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  68: {
    id: '68',
    label: `10 Wintertodt Kills`,
    description: `Help the Pyromancers subdue the Wintertodt 10 times`,
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.WINTERTODT,
    prerequisite: '',
  },
  101: {
    id: '101',
    label: `150 Lizardmen Shaman Kills`,
    description: `Help the Shayzien House by killing 150 Lizardmen shamans`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  69: {
    id: '69',
    label: `25 Wintertodt Kills`,
    description: `Help the Pyromancers subdue the Wintertodt 25 times`,
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.WINTERTODT,
    prerequisite: '',
  },
  100: {
    id: '100',
    label: `50 Lizardmen Shaman Kills`,
    description: `Help the Shayzien House by killing 50 Lizardmen shamans`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  104: {
    id: '104',
    label: `50 Sarachnis Kills`,
    description: `Kill Sarachnis in the Forthos Dungeon 50 times`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '',
  },
  1601: {
    id: '1601',
    label: `Capture 10 sandworms`,
    description: `Capture 10 sandworms`,
    skillReqs: [{ skill: 'Hunter', level: 15 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1124: {
    id: '1124',
    label: `Cast Degrime Spell Full Clean`,
    description: `Clean 26 herbs at the same time using the Degrime spell`,
    skillReqs: [{ skill: 'Magic', level: 70 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  178: {
    id: '178',
    label: `Cast Kourend Castle Teleport`,
    description: `Cast the spell Kourend Castle Teleport after unlocking it by reading transportation incantations`,
    skillReqs: [{ skill: 'Magic', level: 48 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1607: {
    id: '1607',
    label: `Chop 15 Yew Logs in Shayzien`,
    description: `Chop 15 Yew Logs in Shayzien`,
    skillReqs: [{ skill: 'Woodcutting', level: 60 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1598: {
    id: '1598',
    label: `Chop 25 Juniper Logs`,
    description: `Chop 25 Juniper Logs`,
    skillReqs: [{ skill: 'Woodcutting', level: 42 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  72: {
    id: '72',
    label: `Complete 10 Farming Contracts`,
    description: `Complete 10 Farming Contracts for Guildmaster Jane in the Farming Guild`,
    skillReqs: [{ skill: 'Farming', level: 45 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1632: {
    id: '1632',
    label: `Complete the Garden of Death`,
    description: `Complete the Garden of Death`,
    skillReqs: [{ skill: 'Farming', level: 20 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1604: {
    id: '1604',
    label: `Complete the Shayzien Basic Agility Course`,
    description: `Complete a lap of the Shayzien Basic Agility Course`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1599: {
    id: '1599',
    label: `Create 100 Juniper Charcoal`,
    description: `Create 100 Juniper Charcoal`,
    skillReqs: [{ skill: 'Woodcutting', level: 42 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1058: {
    id: '1058',
    label: `Defeat a Drake`,
    description: `Defeat a Drake in the Mount Karuulm Slayer Dungeon`,
    skillReqs: [{ skill: 'Slayer', level: 84 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1597: {
    id: '1597',
    label: `Dig 25 Saltpetre`,
    description: `Dig 25 Saltpetre`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  81: {
    id: '81',
    label: `Enter the Farming Guild's Mid Tier`,
    description: `Enter the mid tier of the Farming Guild in the Kebos Lowlands`,
    skillReqs: [{ skill: 'Farming', level: 65 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  87: {
    id: '87',
    label: `Enter the Woodcutting Guild`,
    description: `Enter the Woodcutting Guild in Hosidius`,
    skillReqs: [{ skill: 'Woodcutting', level: 60 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1593: {
    id: '1593',
    label: `Equip the Cursed Amulet of Magic`,
    description: `Equip the Cursed Amulet of Magic`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  177: {
    id: '177',
    label: `Equip Xeric's Talisman`,
    description: `Obtain and equip Xeric's talisman`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1127: {
    id: '1127',
    label: `Inferior Demonbane Demon Kill`,
    description: `Kill a demon using the Inferior Demonbane spell`,
    skillReqs: [{ skill: 'Magic', level: 44 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1596: {
    id: '1596',
    label: `Kill 5 Sand Crabs`,
    description: `Kill 5 Sand Crabs`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1595: {
    id: '1595',
    label: `Kill a King Sand Crab`,
    description: `Kill a King Sand Crab`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1611: {
    id: '1611',
    label: `Kill a Moss, Fire & Hill Giant in Kourend`,
    description: `Kill a Moss, Fire & Hill Giant in Kourend`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  135: {
    id: '135',
    label: `Kourend and Kebos Easy Diary Tasks`,
    description: `Complete all tasks in the easy tier of the Kourend and Kebos achievement diary`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  136: {
    id: '136',
    label: `Kourend and Kebos Medium Diary Tasks`,
    description: `Complete all tasks in the medium tier of the Kourend and Kebos achievement diary`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1592: {
    id: '1592',
    label: `Make 50 Oak Planks in Kourend`,
    description: `Make 50 Oak Planks in Kourend`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1634: {
    id: '1634',
    label: `Mine 50 Volcanic Sulphur`,
    description: `Mine 50 Volcanic Sulphur`,
    skillReqs: [{ skill: 'Mining', level: 42 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1591: {
    id: '1591',
    label: `Offer an egg to a shrine`,
    description: `Offer an egg to a shrine in the woodcutting guild`,
    skillReqs: [{ skill: 'Woodcutting', level: 60 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  77: {
    id: '77',
    label: `Plant 100 Golovanova Seeds`,
    description: `Plant 100 Golovanova seeds in the Tithe Farm minigame`,
    skillReqs: [{ skill: 'Farming', level: 34 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  181: {
    id: '181',
    label: `Purchase a Seed Box`,
    description: `Purchase a Seed box from the Tithe Farm minigame`,
    skillReqs: [{ skill: 'Farming', level: 34 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1610: {
    id: '1610',
    label: `Restore 14 Prayer points in Hosidius`,
    description: `Restore exactly 14 Prayer Points when praying at an Altar in Hosidius`,
    skillReqs: [{ skill: 'Prayer', level: 14 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1285: {
    id: '1285',
    label: `Smelt a mithril bar in a volcanic fissure`,
    description: `Smelt a Mithril bar in a volcanic Fissure`,
    skillReqs: [{ skill: 'Smithing', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1636: {
    id: '1636',
    label: `Smith Shayzien (1)`,
    description: `Smith any piece of Shayzien (1)`,
    skillReqs: [
      { skill: 'Smithing', level: 45 },
      { skill: 'Mining', level: 65 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1608: {
    id: '1608',
    label: `Smith Steel in Kourend Castle`,
    description: `Smith Steel in Kourend Castle`,
    skillReqs: [{ skill: 'Smithing', level: 30 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  142: {
    id: '142',
    label: `Steal 1 Artefact`,
    description: `Steal an artefact for Captain Khaled in Piscarilius`,
    skillReqs: [{ skill: 'Thieving', level: 49 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  143: {
    id: '143',
    label: `Steal 15 Artefacts`,
    description: `Steal 10 artefacts for Captain Khaled in Piscarilius`,
    skillReqs: [{ skill: 'Thieving', level: 49 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  76: {
    id: '76',
    label: `Steal a Golovanova Fruit Top`,
    description: `Steal a Golovanova fruit top from a fruit stall in Hosidius`,
    skillReqs: [{ skill: 'Thieving', level: 25 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1612: {
    id: '1612',
    label: `Talk to the Voice of Yama`,
    description: `Talk to the Voice of Yama`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  120: {
    id: '120',
    label: `Teleport to Xeric's Honour`,
    description: `Teleport to Xeric's Honour using Xeric's talisman`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  146: {
    id: '146',
    label: `Turn in 10 Library Books`,
    description: `Find and turn in 10 books in the Arceuus Library`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  507: {
    id: '507',
    label: `Build a Bank on Fossil Island`,
    description: `Build a Bank at the Museum Camp on Fossil Island`,
    skillReqs: [{ skill: 'Construction', level: 21 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  445: {
    id: '445',
    label: `Catch 50 Implings in Puro-Puro`,
    description: `Catch 50 Implings in Puro-Puro`,
    skillReqs: [{ skill: 'Hunter', level: 17 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  465: {
    id: '465',
    label: `Chop a Sulliuscep Cap`,
    description: `Chop a Sulliuscep Cap on Fossil Island`,
    skillReqs: [{ skill: 'Woodcutting', level: 65 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1316: {
    id: '1316',
    label: `Churn some butter`,
    description: `Use a churn to make some butter`,
    skillReqs: [{ skill: 'Cooking', level: 38 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  460: {
    id: '460',
    label: `Complete 10 Laps of the Draynor Agility Course`,
    description: `Complete 10 laps of the Draynor Rooftop Agility Course`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1523: {
    id: '1523',
    label: `Complete 10 Laps of the Varrock Agility Course`,
    description: `Complete 10 laps of the Varrock Rooftop Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 30 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  447: {
    id: '447',
    label: `Complete a Volcanic Mine Game`,
    description: `Complete a Volcanic Mine game on Fossil Island`,
    skillReqs: [{ skill: 'Mining', level: 50 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  499: {
    id: '499',
    label: `Complete Demon Slayer`,
    description: `Complete the Demon Slayer quest`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  500: {
    id: '500',
    label: `Complete Sheep Shearer`,
    description: `Complete the Sheep Shearer quest`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  488: {
    id: '488',
    label: `Complete the Easy Lumbridge & Draynor Diary`,
    description: `Complete all of the easy tasks in the Lumbridge & Draynor Achievement Diary`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1047: {
    id: '1047',
    label: `Complete the Easy Varrock Diary`,
    description: `Complete all of the easy tasks in the Varrock Achievement Diary`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  489: {
    id: '489',
    label: `Complete the Medium Lumbridge & Draynor Diary`,
    description: `Complete all of the medium tasks in the Lumbridge & Draynor Achievement Diary`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1048: {
    id: '1048',
    label: `Complete the Medium Varrock Diary`,
    description: `Complete all of the medium tasks in the Varrock Achievement Diary`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  503: {
    id: '503',
    label: `Complete Vampyre Slayer`,
    description: `Complete the Vampyre Slayer quest`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  463: {
    id: '463',
    label: `Craft 50 Cosmic Runes`,
    description: `Craft 50 Cosmic Runes from Essence at the Cosmic Altar from Essence`,
    skillReqs: [{ skill: 'Runecraft', level: 27 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  462: {
    id: '462',
    label: `Craft 50 Water Runes`,
    description: `Craft 50 Water Runes from Essence at the Water Altar`,
    skillReqs: [{ skill: 'Runecraft', level: 5 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1757: {
    id: '1757',
    label: `Defeat 50 Tormented Demons`,
    description: `Defeat 50 Tormented Demons`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  467: {
    id: '467',
    label: `Defeat a Fossil Island Wyvern`,
    description: `Defeat any type of Wyvern on Fossil Island`,
    skillReqs: [{ skill: 'Slayer', level: 66 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1756: {
    id: '1756',
    label: `Defeat a Tormented Demon`,
    description: `Defeat a Tormented Demon`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  476: {
    id: '476',
    label: `Defeat Bryophyta`,
    description: `Defeat Bryophyta in Varrock Sewers`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BRYOPHYTA,
    prerequisite: '',
  },
  472: {
    id: '472',
    label: `Defeat Obor`,
    description: `Defeat Obor in Edgeville Dungeon`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.OBOR,
    prerequisite: '',
  },
  1648: {
    id: '1648',
    label: `Defeat Scurrius`,
    description: `Defeat Scurrius`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SCURRIUS,
    prerequisite: '',
  },
  1649: {
    id: '1649',
    label: `Defeat Scurrius 10 times`,
    description: `Defeat Scurrius 10 times`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SCURRIUS,
    prerequisite: '',
  },
  1650: {
    id: '1650',
    label: `Defeat Scurrius 25 times`,
    description: `Defeat Scurrius 25 times`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SCURRIUS,
    prerequisite: '',
  },
  439: {
    id: '439',
    label: `Dismantle 20 Filled Bird Houses`,
    description: `Dismantle 20 filled Bird Houses on Fossil Island`,
    skillReqs: [
      { skill: 'Hunter', level: 5 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  501: {
    id: '501',
    label: `Drink From the Tears of Guthix`,
    description: `Drink from the Tears of Guthix in the Lumbridge Swamp Caves`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1526: {
    id: '1526',
    label: `Eat Eel sushi`,
    description: `Eat the Dorgeshuun delicacy, Eel sushi`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  451: {
    id: '451',
    label: `Enter the Cooks' Guild`,
    description: `Enter the Cooks' Guild west of Varrock`,
    skillReqs: [{ skill: 'Cooking', level: 32 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  479: {
    id: '479',
    label: `Equip a Dorgeshuun Crossbow`,
    description: `Equip a Dorgeshuun Crossbow`,
    skillReqs: [{ skill: 'Ranged', level: 28 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1830: {
    id: '1830',
    label: `Equip a Rat Bone Weapon`,
    description: `Obtain and equip any Rat Bone weaponry`,
    skillReqs: [{ skill: 'Attack', level: 50 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SCURRIUS,
    prerequisite: '',
  },
  502: {
    id: '502',
    label: `Equip Some Fancy Boots or Fighting Boots`,
    description: `Equip either a pair of Fancy Boots or a pair of Fighting Boots`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1254: {
    id: '1254',
    label: `Equip the Forestry Basket`,
    description: `Obtain and Equip the Forestry Basket`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  505: {
    id: '505',
    label: `Fully Unlock the Mycelium Transportation System`,
    description: `Unlock every destination for the Mycelium Transportation System on Fossil Island`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1531: {
    id: '1531',
    label: `Get a Gem from a Gorak`,
    description: `Obtain a gem drop from a Gorak`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1528: {
    id: '1528',
    label: `Make a pie for Romily`,
    description: `Make a pie for Romily, north west of Varrock`,
    skillReqs: [{ skill: 'Cooking', level: 32 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1613: {
    id: '1613',
    label: `Mine 25 Pure Essence`,
    description: `Mine 25 Pure Essence`,
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1329: {
    id: '1329',
    label: `Open the Dark Chest`,
    description: `Open the Dark Chest`,
    skillReqs: [{ skill: 'Thieving', level: 28 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  458: {
    id: '458',
    label: `Pickpocket a Bullseye Lantern From a Cave Goblin`,
    description: `Pickpocket a Bullseye Lantern from a Cave Goblin`,
    skillReqs: [{ skill: 'Thieving', level: 36 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  454: {
    id: '454',
    label: `Pickpocket a H.A.M. Member`,
    description: `Pickpocket any H.A.M. member at their hideout`,
    skillReqs: [{ skill: 'Thieving', level: 15 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  455: {
    id: '455',
    label: `Pickpocket a Varrock Guard`,
    description: `Pickpocket any Varrock Guard`,
    skillReqs: [{ skill: 'Thieving', level: 40 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1532: {
    id: '1532',
    label: `Provide Terry a buckle`,
    description: `Show Terry Balando in the Digsite a Belt Buckle`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  509: {
    id: '509',
    label: `Use a Digsite Pendant to Teleport to Fossil Island`,
    description: `Use a Digsite Pendant to teleport to Fossil Island`,
    skillReqs: [{ skill: 'Magic', level: 49 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  450: {
    id: '450',
    label: `Use the Range in Lumbridge Castle`,
    description: `Use the Range in Lumbridge Castle to cook some food`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1133: {
    id: '1133',
    label: `Win a Game of Soul Wars`,
    description: `Win a game of Soul Wars`,
    skillReqs: [
      { skill: 'Combat', level: 40 },
      { skill: 'Overall', level: 500 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  965: {
    id: '965',
    label: `Achieve 100% Shades of Mort'Ton Sanctity`,
    description: `Achieve 100% Sanctity during the Shades of Mort'ton minigame`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  917: {
    id: '917',
    label: `Assemble a Slayer Helm`,
    description: `Assemble a Slayer Helm`,
    skillReqs: [
      { skill: 'Slayer', level: 58 },
      { skill: 'Crafting', level: 55 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1354: {
    id: '1354',
    label: `Burn 20 Pyre Logs`,
    description: `Burn 20 Pyre Logs`,
    skillReqs: [{ skill: 'Firemaking', level: 5 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  912: {
    id: '912',
    label: `Catch a Swamp Lizard in Morytania`,
    description: `Catch a Swamp Lizard in Morytania`,
    skillReqs: [{ skill: 'Hunter', level: 29 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1589: {
    id: '1589',
    label: `Chop 100 Blisterwood Logs`,
    description: `Chop 100 Blisterwood Logs`,
    skillReqs: [{ skill: 'Woodcutting', level: 62 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  958: {
    id: '958',
    label: `Complete a Hard Temple Trek`,
    description: `Complete a hard Temple Trek`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  957: {
    id: '957',
    label: `Complete a Medium Temple Trek`,
    description: `Complete a medium Temple Trek`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.OTHER,
    prerequisite: '',
  },
  908: {
    id: '908',
    label: `Complete the Canifis Agility Course`,
    description: `Complete a lap of the Canifis Rooftop Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 40 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  952: {
    id: '952',
    label: `Complete the Easy Morytania Diary`,
    description: `Complete all of the easy tasks in the Morytania Achievement Diary`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  953: {
    id: '953',
    label: `Complete the Medium Morytania Diary`,
    description: `Complete all of the medium tasks in the Morytania Achievement Diary`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  909: {
    id: '909',
    label: `Complete the Werewolf Agility Course`,
    description: `Complete a lap of the Werewolf Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 60 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  915: {
    id: '915',
    label: `Craft a Rune Using Daeyalt Essence`,
    description: `Craft any rune using Daeyalt Essence`,
    skillReqs: [{ skill: 'Mining', level: 60 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1262: {
    id: '1262',
    label: `Create the long rope shortcut in Darkmeyer`,
    description: `Create the long rope agility shortcut in Darkmeyer`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  921: {
    id: '921',
    label: `Defeat a Fiyr Shade`,
    description: `Defeat a Fiyr Shade`,
    skillReqs: [{ skill: 'Firemaking', level: 65 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  918: {
    id: '918',
    label: `Defeat a Gargoyle in Morytania`,
    description: `Defeat a Gargoyle in Morytania`,
    skillReqs: [{ skill: 'Slayer', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  925: {
    id: '925',
    label: `Defeat a Vyrewatch Sentinel`,
    description: `Defeat a Vyrewatch Sentinel in Darkmeyer`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  926: {
    id: '926',
    label: `Defeat an Abyssal Demon in Morytania`,
    description: `Defeat an Abyssal Demon in Morytania`,
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1588: {
    id: '1588',
    label: `Equip a full set of Vyre Noble`,
    description: `Equip a full set of Vyre Noble`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  934: {
    id: '934',
    label: `Equip a Granite Hammer or Granite Ring`,
    description: `Equip either a Granite Hammer or a Granite Ring`,
    skillReqs: [
      { skill: 'Slayer', level: 75 },
      { skill: 'Strength', level: 50 },
      { skill: 'Attack', level: 50 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '',
  },
  929: {
    id: '929',
    label: `Equip a Piece of any Barrows Armour Set`,
    description: `Equip any piece of any Barrows armour set`,
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
      { skill: 'Ranged', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1128: {
    id: '1128',
    label: `Equip a Piece of Zealot's Robes`,
    description: `Equip either a Zealot's helm, a Zealot's robe top, a Zealot's robe bottom or a Zealot's boots`,
    skillReqs: [{ skill: 'Firemaking', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  967: {
    id: '967',
    label: `Equip a Salve Amulet (e)`,
    description: `Equip a Salve Amulet (e)`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1326: {
    id: '1326',
    label: `Feed a ghast some sweets`,
    description: `Feed a ghast some sweets`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  898: {
    id: '898',
    label: `Floor 1 of the Hallowed Sepulchre`,
    description: `Complete floor 1 of the Hallowed Sepulchre in Darkmeyer`,
    skillReqs: [{ skill: 'Agility', level: 52 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  899: {
    id: '899',
    label: `Floor 2 of the Hallowed Sepulchre`,
    description: `Complete floor 2 of the Hallowed Sepulchre in Darkmeyer`,
    skillReqs: [{ skill: 'Agility', level: 62 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  900: {
    id: '900',
    label: `Floor 3 of the Hallowed Sepulchre`,
    description: `Complete floor 3 of the Hallowed Sepulchre in Darkmeyer`,
    skillReqs: [{ skill: 'Agility', level: 72 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1307: {
    id: '1307',
    label: `Have the Taxidermist stuff something for you`,
    description: `Have the Taxidermist stuff something for you in Canifis`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  932: {
    id: '932',
    label: `Loot a Barrows Chest`,
    description: `Loot a Barrows Chest once`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1131: {
    id: '1131',
    label: `Open a Black Chest`,
    description: `Open a Black Chest from Shades of Mort'ton`,
    skillReqs: [{ skill: 'Firemaking', level: 35 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  963: {
    id: '963',
    label: `Pet Frank`,
    description: `Pet Darkmeyer's goodest boy`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  906: {
    id: '906',
    label: `Purchase a Reward From the Hallowed Sepulchre`,
    description: `Purchase a reward from the Hallowed Sepulchre`,
    skillReqs: [{ skill: 'Agility', level: 52 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1586: {
    id: '1586',
    label: `Smelt a Steel Bar in Port Phasmatys`,
    description: `Smelt a Steel Bar in Port Phasmatys`,
    skillReqs: [{ skill: 'Smithing', level: 30 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1546: {
    id: '1546',
    label: `Smuggle some Rum`,
    description: `Use the Ring of Charos to smuggle some Karamja rum through customs`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1320: {
    id: '1320',
    label: `Telegrab a Bloody bracer`,
    description: `Telegrab a Bloody bracer in Slepe`,
    skillReqs: [{ skill: 'Magic', level: 33 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1585: {
    id: '1585',
    label: `Worship the Ectofuntus`,
    description: `Worship the Ectofuntus`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  519: {
    id: '519',
    label: `Catch a Crystal Impling`,
    description: `Catch a Crystal Impling in Prifddinas`,
    skillReqs: [{ skill: 'Hunter', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  517: {
    id: '517',
    label: `Check a grown Papaya Tree in Lletya`,
    description: `Check the health of a Papaya Tree you've grown in Lletya`,
    skillReqs: [{ skill: 'Farming', level: 57 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1579: {
    id: '1579',
    label: `Chop 100 Maples in Tirannwn`,
    description: `Chop 100 Maples in Tirannwn`,
    skillReqs: [{ skill: 'Woodcutting', level: 45 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  533: {
    id: '533',
    label: `Defeat a Bloodveld in Tirannwn`,
    description: `Defeat a Bloodveld in Tirannwn`,
    skillReqs: [{ skill: 'Slayer', level: 50 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  537: {
    id: '537',
    label: `Defeat a Kurask in Tirannwn`,
    description: `Defeat a Kurask in Tirannwn`,
    skillReqs: [{ skill: 'Slayer', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  529: {
    id: '529',
    label: `Defeat an Elf in Tirannwn`,
    description: `Defeat an Elf in Tirannwn`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  516: {
    id: '516',
    label: `Harvest Some Sweetcorn in Prifddinas`,
    description: `Harvest some Sweetcorn from the Prifddinas Allotment patch`,
    skillReqs: [{ skill: 'Farming', level: 20 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1387: {
    id: '1387',
    label: `Kill a Black Dragon in Tirannwn`,
    description: `Kill a Black Dragon in Tirannwn`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1578: {
    id: '1578',
    label: `Mine 25 gold rocks in Tirannwn`,
    description: `Mine 25 gold rocks in Tirannwn`,
    skillReqs: [{ skill: 'Mining', level: 40 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  527: {
    id: '527',
    label: `Move Your House to Prifddinas`,
    description: `Move your Player Owned House to Prifddinas`,
    skillReqs: [{ skill: 'Construction', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1766: {
    id: '1766',
    label: `Pick up 10 Whiteberries in Tirannwn`,
    description: `Pick up 10 Whiteberries in Tirannwn`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1301: {
    id: '1301',
    label: `Ring all of the Prifddinas bells`,
    description: `Ring all of the Prifddinas bells`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1767: {
    id: '1767',
    label: `Successfully hop over the Tripwire in Tirannwn`,
    description: `Successfully hop over the Tripwire in Tirannwn`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1768: {
    id: '1768',
    label: `Thieve a Diamond from a Gem Stall in Tirannwn`,
    description: `Thieve a Diamond from a Gem Stall in Tirannwn`,
    skillReqs: [{ skill: 'Thieving', level: 75 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1769: {
    id: '1769',
    label: `Thieve a tiara from a Silver Stall in Tirannwn`,
    description: `Thieve a tiara from a Silver Stall in Tirannwn`,
    skillReqs: [{ skill: 'Thieving', level: 50 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  557: {
    id: '557',
    label: `Use an Elven Teleport Crystal`,
    description: `Use an Elven Teleport Crystal`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1743: {
    id: '1743',
    label: `1 Amoxliatl Kill`,
    description: `Kill the Amoxliatl in Varlamore`,
    skillReqs: [{ skill: 'Slayer', level: 48 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.AMOXLIATL,
    prerequisite: '',
  },
  1730: {
    id: '1730',
    label: `1 Hueycoatl Kill`,
    description: `Kill the Hueycoatl in Varlamore`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HUEYCOATL,
    prerequisite: '',
  },
  1753: {
    id: '1753',
    label: `Activate the Statue of Ates`,
    description: `Activate the Statue of Ates in Aldarin`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.AMOXLIATL,
    prerequisite: '',
  },
  1652: {
    id: '1652',
    label: `Build a Quetzal Landing Site`,
    description: `Build a Quetzal Landing Site`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1658: {
    id: '1658',
    label: `Complete 10 Hunter Rumours`,
    description: `Complete 10 Hunter Rumours`,
    skillReqs: [{ skill: 'Hunter', level: 46 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1746: {
    id: '1746',
    label: `Complete 10 laps of the Varlamore Agility Course`,
    description: `Complete 10 laps of the Colossal Wyrm Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 50 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1659: {
    id: '1659',
    label: `Complete 25 Hunter Rumours`,
    description: `Complete 25 Hunter Rumours`,
    skillReqs: [{ skill: 'Hunter', level: 46 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1657: {
    id: '1657',
    label: `Complete a Hunter Rumour`,
    description: `Complete a Hunter Rumour`,
    skillReqs: [{ skill: 'Hunter', level: 46 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1721: {
    id: '1721',
    label: `Complete Meat and Greet`,
    description: `Complete Meat and Greet`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1664: {
    id: '1664',
    label: `Complete Wave 1 of Fortis Colosseum`,
    description: `Complete Wave 1 of Fortis Colosseum`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1656: {
    id: '1656',
    label: `Create a Quetzal Whistle`,
    description: `Create a Quetzal Whistle`,
    skillReqs: [{ skill: 'Hunter', level: 46 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1740: {
    id: '1740',
    label: `Equip a piece of Alchemists outfit`,
    description: `Equip a piece of Alchemists outfit from the Mixology shop`,
    skillReqs: [{ skill: 'Herblore', level: 60 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1677: {
    id: '1677',
    label: `Equip a piece of Blood Moon armour`,
    description: `Equip a piece of Blood Moon armour`,
    skillReqs: [
      { skill: 'Strength', level: 75 },
      { skill: 'Defence', level: 50 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1681: {
    id: '1681',
    label: `Equip a piece of Blue Moon armour`,
    description: `Equip a piece of Blue Moon armour`,
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Defence', level: 50 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1679: {
    id: '1679',
    label: `Equip a piece of Eclipse Moon armour`,
    description: `Equip a piece of Eclipse Moon armour`,
    skillReqs: [
      { skill: 'Ranged', level: 75 },
      { skill: 'Defence', level: 50 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1733: {
    id: '1733',
    label: `Equip a piece of Hueycoatl armour`,
    description: `Equip a piece of Hueycoatl armour`,
    skillReqs: [
      { skill: 'Crafting', level: 86 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HUEYCOATL,
    prerequisite: '',
  },
  1741: {
    id: '1741',
    label: `Equip Pendant of Ates`,
    description: `Equip the Pendant of Ates`,
    skillReqs: [{ skill: 'Slayer', level: 48 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.AMOXLIATL,
    prerequisite: '',
  },
  1779: {
    id: '1779',
    label: `Fill a Grape Barrel for the Foreman`,
    description: `Fill a Grape Barrel for the Foreman in Aldarin`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1701: {
    id: '1701',
    label: `Fish a House Key`,
    description: `Fish a House Key`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1751: {
    id: '1751',
    label: `Give a Dwarf Redberry Antelope`,
    description: `Give a Dwarf Redberry Antelope`,
    skillReqs: [{ skill: 'Hunter', level: 72 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1778: {
    id: '1778',
    label: `Investigate Meztlan`,
    description: `Investigate Meztlan and find out why he was banished from the lab`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1752: {
    id: '1752',
    label: `Kill 10 Frost Crabs`,
    description: `Kill 10 Frost Crabs`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1750: {
    id: '1750',
    label: `Kill a Dire Wolf`,
    description: `Kill a Dire Wolf in Varlamore`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1694: {
    id: '1694',
    label: `Mine 20 Mithril Ore in the Stonecutter Outpost`,
    description: `Mine 20 Mithril Ore in the Stonecutter Outpost`,
    skillReqs: [{ skill: 'Mining', level: 55 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1691: {
    id: '1691',
    label: `Mine 250 Blessed Bone Shards`,
    description: `Mine 250 Blessed Bone Shards`,
    skillReqs: [{ skill: 'Mining', level: 41 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1690: {
    id: '1690',
    label: `Mine a Calcified Deposit`,
    description: `Mine a Calcified Deposit`,
    skillReqs: [{ skill: 'Mining', level: 41 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1782: {
    id: '1782',
    label: `Obtain a Cake from a Nest`,
    description: `Obtain a Cake from a Nest`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1709: {
    id: '1709',
    label: `Open an Adept Hunter's Loot Sack`,
    description: `Open an Adept Hunter's Loot Sack`,
    skillReqs: [{ skill: 'Hunter', level: 57 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1702: {
    id: '1702',
    label: `Pay an Urchin for Information`,
    description: `Pay an Urchin for Information in the Stealing Valuables minigame`,
    skillReqs: [{ skill: 'Thieving', level: 50 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1705: {
    id: '1705',
    label: `Steal 100 Valuables`,
    description: `Steal 100 Valuables`,
    skillReqs: [{ skill: 'Thieving', level: 50 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1700: {
    id: '1700',
    label: `Steal 15 House Keys`,
    description: `Steal 15 House Keys`,
    skillReqs: [{ skill: 'Thieving', level: 50 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1704: {
    id: '1704',
    label: `Steal 25 Valuables`,
    description: `Steal 25 Valuables`,
    skillReqs: [{ skill: 'Thieving', level: 50 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1699: {
    id: '1699',
    label: `Steal a House Key`,
    description: `Steal a House Key`,
    skillReqs: [{ skill: 'Thieving', level: 50 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1698: {
    id: '1698',
    label: `Steal from the Fortis Spice Stall`,
    description: `Steal from the Fortis Spice Stall`,
    skillReqs: [{ skill: 'Thieving', level: 65 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1685: {
    id: '1685',
    label: `Subdue the Moons of Peril`,
    description: `Subdue the Moons of Peril`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1686: {
    id: '1686',
    label: `Subdue the Moons of Peril 10 times`,
    description: `Subdue the Moons of Peril 10 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1687: {
    id: '1687',
    label: `Subdue the Moons of Peril 25 times`,
    description: `Subdue the Moons of Peril 25 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1692: {
    id: '1692',
    label: `Teleport to Cam Torum using a Calcified Moth`,
    description: `Teleport to Cam Torum using a Calcified Moth`,
    skillReqs: [{ skill: 'Mining', level: 41 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1654: {
    id: '1654',
    label: `Teleport to Civitas illa Fortis`,
    description: `Teleport to Civitas illa Fortis using the standard spellbook`,
    skillReqs: [{ skill: 'Magic', level: 54 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1655: {
    id: '1655',
    label: `Teleport to the Fortis Colosseum`,
    description: `Teleport to the Fortis Colosseum via the Ring of Dueling`,
    skillReqs: [{ skill: 'Magic', level: 27 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  971: {
    id: '971',
    label: `Bury Some Lava Dragon Bones`,
    description: `Bury some Lava Dragon Bones`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1024: {
    id: '1024',
    label: `Cast Claws of Guthix`,
    description: `Cast the Claws of Guthix spell outside of the Mage Arena`,
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1025: {
    id: '1025',
    label: `Cast Flames of Zamorak`,
    description: `Cast the Flames of Zamorak spell outside of the Mage Arena`,
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1023: {
    id: '1023',
    label: `Cast Saradomin Strike`,
    description: `Cast the Saradomin Strike spell outside of the Mage Arena`,
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  977: {
    id: '977',
    label: `Catch a Black Chinchompa`,
    description: `Catch a Black Chinchompa in the Wilderness`,
    skillReqs: [{ skill: 'Hunter', level: 73 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  976: {
    id: '976',
    label: `Catch a Black Salamander`,
    description: `Catch a Black Salamander in the Wilderness`,
    skillReqs: [{ skill: 'Hunter', level: 67 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1026: {
    id: '1026',
    label: `Complete the Easy Wilderness Diary`,
    description: `Complete all of the easy tasks in the Wilderness Achievement Diary`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1027: {
    id: '1027',
    label: `Complete the Medium Wilderness Diary`,
    description: `Complete all of the medium tasks in the Wilderness Achievement Diary`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  975: {
    id: '975',
    label: `Complete the Wilderness Agility Course`,
    description: `Complete a lap of the Wilderness Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 52 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  998: {
    id: '998',
    label: `Defeat a Green Dragon in the Wilderness`,
    description: `Defeat a Green Dragon in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  986: {
    id: '986',
    label: `Defeat a Lava Dragon in the Wilderness`,
    description: `Defeat a Lava Dragon in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  995: {
    id: '995',
    label: `Defeat the Chaos Fanatic`,
    description: `Defeat the Chaos Fanatic in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  987: {
    id: '987',
    label: `Defeat the Crazy Archaeologist`,
    description: `Defeat the Crazy Archaeologist in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1296: {
    id: '1296',
    label: `Destroy the one ring to rule them all`,
    description: `Destroy the one ring to rule them all`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1036: {
    id: '1036',
    label: `Enter the Deep Wilderness Dungeon`,
    description: `Enter the Deep Wilderness Dungeon`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1014: {
    id: '1014',
    label: `Equip a Bracelet of Ethereum`,
    description: `Equip a Bracelet of Ethereum`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1001: {
    id: '1001',
    label: `Equip a Fedora`,
    description: `Equip a Fedora`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1021: {
    id: '1021',
    label: `Equip a God Cape`,
    description: `Equip either a Saradomin Cape, a Guthix Cape or a Zamorak Cape`,
    skillReqs: [{ skill: 'Magic', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1011: {
    id: '1011',
    label: `Obtain an Ecumenical Key`,
    description: `Obtain an Ecumenical Key as a drop in the Wilderness God Wars Dungeon`,
    skillReqs: [{ skill: 'Strength', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1343: {
    id: '1343',
    label: `Open 1 Muddy Chest`,
    description: `Open 1 Muddy Chest`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1644: {
    id: '1644',
    label: `Open 1 Zombie Pirate Locker`,
    description: `Open 1 Zombie Pirate Locker`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1341: {
    id: '1341',
    label: `Open 15 Larran's Chests`,
    description: `Open 15 of either Larran's Small Chest or Larran's Big Chest`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1039: {
    id: '1039',
    label: `Open One of Larran's Chests`,
    description: `Open either Larran's Small Chest or Larran's Big Chest`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1017: {
    id: '1017',
    label: `Order a Drink at The Old Nite`,
    description: `Order a drink at The Old Nite`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  969: {
    id: '969',
    label: `Sacrifice Some Bones at the Chaos Temple`,
    description: `Sacrifice some Bones at the Western Chaos Temple altar`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  982: {
    id: '982',
    label: `Use the Abyss`,
    description: `Use the Abyss to access a Runecrafting Altar`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MEDIUM,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  164: {
    id: '164',
    label: `1 Master Clue Scroll`,
    description: `Open a Reward casket for completing a master clue scroll`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  1452: {
    id: '1452',
    label: `100 Collection log slots`,
    description: `Obtain 100 unique Collection Log slots`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  99: {
    id: '99',
    label: `100 Superior Slayer Encounters`,
    description: `Defeat 100 superior foes while on a Slayer Task`,
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1453: {
    id: '1453',
    label: `200 Collection log slots`,
    description: `Obtain 200 unique Collection Log slots`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  165: {
    id: '165',
    label: `25 Master Clue Scrolls`,
    description: `Open 25 Reward caskets for completing master clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  162: {
    id: '162',
    label: `75 Elite Clue Scrolls`,
    description: `Open 75 Reward caskets for completing elite clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  158: {
    id: '158',
    label: `75 Hard Clue Scrolls`,
    description: `Open 75 Reward caskets for completing hard clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  199: {
    id: '199',
    label: `Achieve Your First Level 70`,
    description: `Reach level 70 in any skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  200: {
    id: '200',
    label: `Achieve Your First Level 80`,
    description: `Reach level 80 in any skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  1539: {
    id: '1539',
    label: `Beat Jacky Jester`,
    description: `Successfully copy Jacky the Jester to win`,
    skillReqs: [{ skill: 'Construction', level: 39 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1353: {
    id: '1353',
    label: `Blow 100 Light Orbs`,
    description: `Blow 100 Light Orbs`,
    skillReqs: [{ skill: 'Crafting', level: 87 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  570: {
    id: '570',
    label: `Build a Gilded Altar`,
    description: `Build a Gilded Altar in a Chapel in your Player Owned House`,
    skillReqs: [{ skill: 'Construction', level: 75 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  377: {
    id: '377',
    label: `Burn 100 Yew Logs`,
    description: `Burn 100 Yew Logs`,
    skillReqs: [{ skill: 'Firemaking', level: 60 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  378: {
    id: '378',
    label: `Burn Some Magic Logs`,
    description: `Burn some Magic logs`,
    skillReqs: [{ skill: 'Firemaking', level: 75 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  438: {
    id: '438',
    label: `Cast a Surge Spell`,
    description: `Cast any surge spell`,
    skillReqs: [{ skill: 'Magic', level: 81 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  437: {
    id: '437',
    label: `Cast a Wave Spell`,
    description: `Cast any wave spell`,
    skillReqs: [{ skill: 'Magic', level: 62 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  346: {
    id: '346',
    label: `Catch 100 Shark`,
    description: `Catch 100 Raw Shark whilst Fishing`,
    skillReqs: [{ skill: 'Fishing', level: 76 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1615: {
    id: '1615',
    label: `Catch 50 Grey Chinchompas`,
    description: `Catch 50 Grey Chinchompas`,
    skillReqs: [{ skill: 'Hunter', level: 53 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  384: {
    id: '384',
    label: `Catch a Dragon Impling`,
    description: `Catch a Dragon Impling`,
    skillReqs: [{ skill: 'Hunter', level: 83 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1822: {
    id: '1822',
    label: `Chop 100 Yew Logs`,
    description: `Chop 100 Yew Logs`,
    skillReqs: [{ skill: 'Woodcutting', level: 60 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  295: {
    id: '295',
    label: `Chop 75 Magic Logs`,
    description: `Chop 75 Magic logs from Magic Trees`,
    skillReqs: [{ skill: 'Woodcutting', level: 75 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1787: {
    id: '1787',
    label: `Clean 100 Grimy Avantoe`,
    description: `Clean 100 Grimy Avantoe`,
    skillReqs: [{ skill: 'Herblore', level: 48 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1789: {
    id: '1789',
    label: `Clean 50 Grimy Lantadyme`,
    description: `Clean 50 Grimy Lantadyme`,
    skillReqs: [{ skill: 'Herblore', level: 67 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1119: {
    id: '1119',
    label: `Combat Achievements Easy Tier`,
    description: `Obtain enough points to unlock the easy tier of Combat Achievements`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.EASY,
    prerequisite: '',
  },
  1413: {
    id: '1413',
    label: `Complete 1 Speed Task`,
    description: `Complete a Combat Achievement Speed task`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1414: {
    id: '1414',
    label: `Complete 5 Speed Tasks`,
    description: `Complete 5 Combat Achievement Speed tasks`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1209: {
    id: '1209',
    label: `Complete 50 Mahogany homes contracts`,
    description: `Complete 50 Mahogany Homes contracts`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1206: {
    id: '1206',
    label: `Complete a Mahogany homes expert contract`,
    description: `Complete a Mahogany Homes expert contract`,
    skillReqs: [{ skill: 'Construction', level: 70 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  765: {
    id: '765',
    label: `Complete the Hard Western Provinces Diary`,
    description: `Complete all of the hard tasks in the Western Provinces Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin', 'Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1347: {
    id: '1347',
    label: `Cook 100 pies`,
    description: `Cook 100 pies`,
    skillReqs: [{ skill: 'Cooking', level: 10 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  302: {
    id: '302',
    label: `Cook 100 Sharks`,
    description: `Cook 100 Raw Sharks`,
    skillReqs: [{ skill: 'Cooking', level: 80 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1348: {
    id: '1348',
    label: `Cook 20 Redberry Pies`,
    description: `Cook 20 Redberry Pies`,
    skillReqs: [{ skill: 'Cooking', level: 10 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  334: {
    id: '334',
    label: `Craft 100 Unpowered Orbs`,
    description: `Craft 100 Unpowered Orbs`,
    skillReqs: [{ skill: 'Crafting', level: 46 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  370: {
    id: '370',
    label: `Craft 2500 Essence Into Runes`,
    description: `Use Runecrafting Altars to craft 2500 essence into runes of any type`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  338: {
    id: '338',
    label: `Craft 30 Blue Dragonhide Bodies`,
    description: `Craft 30 Blue Dragonhide Bodies`,
    skillReqs: [{ skill: 'Crafting', level: 71 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  337: {
    id: '337',
    label: `Craft a Dragonstone Amulet`,
    description: `Craft a Dragonstone Amulet`,
    skillReqs: [{ skill: 'Crafting', level: 80 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1352: {
    id: '1352',
    label: `Create a black d'hide shield`,
    description: `Create a black d'hide shield`,
    skillReqs: [
      { skill: 'Crafting', level: 83 },
      { skill: 'Fletching', level: 92 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  314: {
    id: '314',
    label: `Create a Mithril Grapple`,
    description: `Create a Mithril Grapple`,
    skillReqs: [
      { skill: 'Fletching', level: 59 },
      { skill: 'Smithing', level: 59 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  326: {
    id: '326',
    label: `Defeat 10 Superior Slayer Creatures`,
    description: `Defeat 10 Superior Slayer Creatures`,
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  327: {
    id: '327',
    label: `Defeat 20 Superior Slayer Creatures`,
    description: `Defeat 20 Superior Slayer Creatures`,
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  436: {
    id: '436',
    label: `Equip a Combination Battlestaff or Mystic Staff`,
    description: `Equip either a Combination Battlestaff or a Combination Mystic Staff`,
    skillReqs: [
      { skill: 'Magic', level: 30 },
      { skill: 'Attack', level: 30 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1002: {
    id: '1002',
    label: `Equip a Dragon Pickaxe`,
    description: `Equip a Dragon Pickaxe`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  415: {
    id: '415',
    label: `Equip a Dragon Weapon`,
    description: `Equip any Dragon weapon`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  425: {
    id: '425',
    label: `Equip a Full Black Dragonhide Set`,
    description: `Equip a Black Dragonhide Body, some Black Dragonhide Chaps and some Black Dragonhide Vambraces`,
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  20: {
    id: '20',
    label: `Equip a Full God Dragonhide Set`,
    description: `Equip a Blessed Coif, Body and Chaps with all pieces aligned to the same god`,
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  17: {
    id: '17',
    label: `Equip a Full God Rune Set`,
    description: `Equip a full set of God Rune Armour with all pieces aligned to the same god`,
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  419: {
    id: '419',
    label: `Equip a Full Rune Set`,
    description: `Equip a Rune Platebody, a Rune Full Helm and either some Rune Platelegs or a Rune Plateskirt`,
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  13: {
    id: '13',
    label: `Equip a Full Vestment Set`,
    description: `Equip a full set of Vestment robes with all pieces aligned to the same god`,
    skillReqs: [
      { skill: 'Prayer', level: 60 },
      { skill: 'Magic', level: 40 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  12: {
    id: '12',
    label: `Equip a Gilded or Trimmed Wizard Item`,
    description: `Equip either a Gilded armour item or trimmed wizard item`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  428: {
    id: '428',
    label: `Equip a Magic Shortbow`,
    description: `Equip a Magic Shortbow`,
    skillReqs: [{ skill: 'Ranged', level: 50 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  125: {
    id: '125',
    label: `Equip a Mist Battlestaff`,
    description: `Obtain and equip a Mist battlestaff`,
    skillReqs: [
      { skill: 'Slayer', level: 5 },
      { skill: 'Attack', level: 30 },
      { skill: 'Magic', level: 30 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  429: {
    id: '429',
    label: `Equip a Rune Crossbow`,
    description: `Equip a Rune Crossbow`,
    skillReqs: [{ skill: 'Ranged', level: 61 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1520: {
    id: '1520',
    label: `Equip a two-handed Axe`,
    description: `Obtain and Equip a two-handed woodcutting axe from Forestry`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  23: {
    id: '23',
    label: `Equip an Ornament Kit Item`,
    description: `Equip any item that has an Ornament Kit attached to it`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  1793: {
    id: '1793',
    label: `Equip one unique Echo Item`,
    description: `Equip one unique Echo Item`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  25: {
    id: '25',
    label: `Equip Some Ranger Boots`,
    description: `Equip a pair of Ranger Boots`,
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1795: {
    id: '1795',
    label: `Equip three unique Echo Items`,
    description: `Equip three unique Echo Items`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1794: {
    id: '1794',
    label: `Equip two unique Echo Items`,
    description: `Equip two unique Echo Items`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  299: {
    id: '299',
    label: `Ferment 100 Jugs of Wine`,
    description: `Successfully ferment 100 Jugs of Wine`,
    skillReqs: [{ skill: 'Cooking', level: 35 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  40: {
    id: '40',
    label: `Fill 10 Beginner Clue Collection Log Slots`,
    description: `Fill 10 slots in the Beginner Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '',
  },
  28: {
    id: '28',
    label: `Fill 10 Elite Clue Collection Log Slots`,
    description: `Fill 10 slots in the Elite Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  8: {
    id: '8',
    label: `Fill 25 Elite Clue Collection Log Slots`,
    description: `Fill 25 slots in the Elite Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  30: {
    id: '30',
    label: `Fill 30 Hard Clue Collection Log Slots`,
    description: `Fill 30 slots in the Hard Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  31: {
    id: '31',
    label: `Fill 40 Medium Clue Collection Log Slots`,
    description: `Fill 40 slots in the Medium Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  9: {
    id: '9',
    label: `Fill 5 Master Clue Collection Log Slots`,
    description: `Fill 5 slots in the Master Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  36: {
    id: '36',
    label: `Fill 50 Easy Clue Collection Log Slots`,
    description: `Fill 50 slots in the Easy Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.EASY,
    prerequisite: '',
  },
  984: {
    id: '984',
    label: `Fill a Giant Pouch`,
    description: `Fill a Giant Pouch with Essence`,
    skillReqs: [{ skill: 'Runecraft', level: 75 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  362: {
    id: '362',
    label: `Fill a Hard S.T.A.S.H. Unit`,
    description: `Build a Hard S.T.A.S.H. unit and fill it with the relevant items`,
    skillReqs: [{ skill: 'Construction', level: 55 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  1360: {
    id: '1360',
    label: `Fletch 200 Magic Longbow (u)`,
    description: `Fletch 200 Magic Longbow (u)`,
    skillReqs: [{ skill: 'Fletching', level: 85 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1361: {
    id: '1361',
    label: `Fletch a Magic Shield`,
    description: `Fletch a Magic Shield`,
    skillReqs: [{ skill: 'Fletching', level: 87 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  317: {
    id: '317',
    label: `Fletch a Rune Crossbow`,
    description: `Fletch a Rune Crossbow`,
    skillReqs: [{ skill: 'Fletching', level: 69 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  43: {
    id: '43',
    label: `Gain 10 Unique Items From Elite Clues`,
    description: `Gain 10 unique items from Elite Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  41: {
    id: '41',
    label: `Gain 10 Unique Items From Master Clues`,
    description: `Gain 10 unique items from Master Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  47: {
    id: '47',
    label: `Gain 25 Unique Items From Medium Clues`,
    description: `Gain 25 unique items from Medium Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MEDIUM,
    prerequisite: '',
  },
  51: {
    id: '51',
    label: `Gain 35 Unique Items From Beginner Clues`,
    description: `Gain 35 unique items from Beginner Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '',
  },
  46: {
    id: '46',
    label: `Gain 50 Unique Items From Hard Clues`,
    description: `Gain 50 unique items from Hard Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.HARD,
    prerequisite: '',
  },
  56: {
    id: '56',
    label: `Gain a Unique Item From an Elite Clue`,
    description: `Gain a unique item from an Elite Clue Scroll Reward Casket`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  1790: {
    id: '1790',
    label: `Kill 1 unique Echo Boss`,
    description: `Kill 1 unique Echo Boss`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1791: {
    id: '1791',
    label: `Kill 2 unique Echo Bosses`,
    description: `Kill 2 unique Echo Bosses`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1792: {
    id: '1792',
    label: `Kill 3 unique Echo Bosses`,
    description: `Kill 3 unique Echo Bosses`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  777: {
    id: '777',
    label: `Make 20 Ranging Potions`,
    description: `Make 20 Ranging Potions`,
    skillReqs: [{ skill: 'Herblore', level: 72 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  397: {
    id: '397',
    label: `Make a Saradomin Brew`,
    description: `Make a Saradomin Brew`,
    skillReqs: [{ skill: 'Herblore', level: 81 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  309: {
    id: '309',
    label: `Mine 50 Adamantite Ore`,
    description: `Mine 50 Adamantite Ore`,
    skillReqs: [{ skill: 'Mining', level: 70 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1210: {
    id: '1210',
    label: `Mine a shooting star`,
    description: `Mine a shooting star`,
    skillReqs: [{ skill: 'Mining', level: 10 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1619: {
    id: '1619',
    label: `Obtain a Kebab from the Certer Brothers`,
    description: `Obtain a Kebab from the Certer Brothers`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1391: {
    id: '1391',
    label: `Obtain a Magic seed from a birds nest`,
    description: `Obtain a Magic seed from a birds nest`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  66: {
    id: '66',
    label: `Reach a Prayer Bonus of 30`,
    description: `Equip enough items to reach a Prayer Bonus of 30 or more`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  219: {
    id: '219',
    label: `Reach Base Level 40`,
    description: `Reach level 40 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  220: {
    id: '220',
    label: `Reach Base Level 50`,
    description: `Reach level 50 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  221: {
    id: '221',
    label: `Reach Base Level 60`,
    description: `Reach level 60 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  222: {
    id: '222',
    label: `Reach Base Level 70`,
    description: `Reach level 70 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  405: {
    id: '405',
    label: `Reach Combat Level 100`,
    description: `Reach Combat Level 100`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '',
  },
  406: {
    id: '406',
    label: `Reach Combat Level 110`,
    description: `Reach Combat Level 110`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '',
  },
  207: {
    id: '207',
    label: `Reach Total Level 1000`,
    description: `Reach a Total Level of 1000`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  208: {
    id: '208',
    label: `Reach Total Level 1250`,
    description: `Reach a Total Level of 1250`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  209: {
    id: '209',
    label: `Reach Total Level 1500`,
    description: `Reach a Total Level of 1500`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  210: {
    id: '210',
    label: `Reach Total Level 1750`,
    description: `Reach a Total Level of 1750`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  65: {
    id: '65',
    label: `Restore 75 Prayer Points at an Altar`,
    description: `Restore 75 or more Prayer Points at any altar`,
    skillReqs: [{ skill: 'Prayer', level: 75 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  354: {
    id: '354',
    label: `Smelt a Runite Bar`,
    description: `Use a Furnace to smelt a Runite Bar`,
    skillReqs: [{ skill: 'Smithing', level: 85 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1265: {
    id: '1265',
    label: `Smith a Rune Item`,
    description: `Smith a Runite Item`,
    skillReqs: [{ skill: 'Smithing', level: 85 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  279: {
    id: '279',
    label: `Steal From a Gem Stall`,
    description: `Steal from a Gem Stall`,
    skillReqs: [{ skill: 'Thieving', level: 75 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  829: {
    id: '829',
    label: `Complete a Game of Veteran Pest Control`,
    description: `Complete a game of Veteran Pest Control`,
    skillReqs: [{ skill: 'Combat', level: 100 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  819: {
    id: '819',
    label: `Complete A Porcine of Interest`,
    description: `Complete the A Porcine of Interest quest`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  817: {
    id: '817',
    label: `Complete the Hard Falador Diary`,
    description: `Complete all of the hard tasks in the Falador Achievement Diary`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  776: {
    id: '776',
    label: `Craft 50 Law Runes`,
    description: `Craft 50 Law Runes from Essence at the Law Altar`,
    skillReqs: [{ skill: 'Runecraft', level: 54 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  809: {
    id: '809',
    label: `Defeat Any God Wars Dungeon Boss 100 Times`,
    description: `Defeat any one of the God Wars Dungeon bosses 100 times`,
    skillReqs: [
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  810: {
    id: '810',
    label: `Defeat Any God Wars Dungeon Boss 250 Times`,
    description: `Defeat any one of the God Wars Dungeon bosses 250 times`,
    skillReqs: [
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  807: {
    id: '807',
    label: `Defeat Cerberus`,
    description: `Defeat Cerberus in the Taverley Dungeon`,
    skillReqs: [{ skill: 'Slayer', level: 91 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  1393: {
    id: '1393',
    label: `Defeat Cerberus 150 times`,
    description: `Defeat Cerberus 150 times`,
    skillReqs: [{ skill: 'Slayer', level: 91 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  1392: {
    id: '1392',
    label: `Defeat Cerberus 50 times`,
    description: `Defeat Cerberus 50 times`,
    skillReqs: [{ skill: 'Slayer', level: 91 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  801: {
    id: '801',
    label: `Defeat Commander Zilyana`,
    description: `Defeat Commander Zilyana in the God Wars Dungeon`,
    skillReqs: [{ skill: 'Agility', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  793: {
    id: '793',
    label: `Defeat General Graardor`,
    description: `Defeat General Graardor in the God Wars Dungeon`,
    skillReqs: [{ skill: 'Strength', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  788: {
    id: '788',
    label: `Defeat K'ril Tsutsaroth`,
    description: `Defeat K'ril Tsutsaroth in the God Wars Dungeon`,
    skillReqs: [{ skill: 'Hitpoints', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  797: {
    id: '797',
    label: `Defeat Kree'arra`,
    description: `Defeat Kree'arra in the God Wars Dungeon`,
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1156: {
    id: '1156',
    label: `Defeat Nex`,
    description: `Defeat Nex in the God Wars Dungeon`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1158: {
    id: '1158',
    label: `Defeat Nex 150 Times`,
    description: `Defeat Nex in the God Wars Dungeon 150 times`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1157: {
    id: '1157',
    label: `Defeat Nex 50 Times`,
    description: `Defeat Nex in the God Wars Dungeon 50 times`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  814: {
    id: '814',
    label: `Defeat the Giant Mole 300 Times`,
    description: `Defeat the Giant Mole beneath Falador 300 times`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOLE,
    prerequisite: '',
  },
  1171: {
    id: '1171',
    label: `Defeat Whisperer`,
    description: `Defeat Whisperer`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1173: {
    id: '1173',
    label: `Defeat Whisperer 150 times`,
    description: `Defeat Whisperer 150 times`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1172: {
    id: '1172',
    label: `Defeat Whisperer 50 times`,
    description: `Defeat Whisperer 50 times`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  783: {
    id: '783',
    label: `Equip a Dragon Defender`,
    description: `Equip a Dragon Defender`,
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  772: {
    id: '772',
    label: `Equip a Full Prospector Outfit`,
    description: `Equip a full set of Prospector gear`,
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  770: {
    id: '770',
    label: `Equip a Full Rogue Outfit`,
    description: `Equip a full set of Rogue gear`,
    skillReqs: [
      { skill: 'Thieving', level: 50 },
      { skill: 'Agility', level: 50 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  820: {
    id: '820',
    label: `Equip a Full Void Knight Set`,
    description: `Equip a full set of Void Knight equipment`,
    skillReqs: [
      { skill: 'Attack', level: 42 },
      { skill: 'Strength', level: 42 },
      { skill: 'Defence', level: 42 },
      { skill: 'Hitpoints', level: 42 },
      { skill: 'Magic', level: 42 },
      { skill: 'Ranged', level: 42 },
      { skill: 'Prayer', level: 22 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  808: {
    id: '808',
    label: `Equip a Pair of Dragon Boots`,
    description: `Equip a pair of Dragon Boots`,
    skillReqs: [
      { skill: 'Defence', level: 60 },
      { skill: 'Slayer', level: 83 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1139: {
    id: '1139',
    label: `Equip a Piece of the Golden Prospector set`,
    description: `Equip either a Golden prospector helmet, a Golden prospector jacket, some Golden prospector legs or Golden prospector boots`,
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  794: {
    id: '794',
    label: `Equip a Saradomin Sword`,
    description: `Equip a Saradomin Sword`,
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Agility', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1164: {
    id: '1164',
    label: `Equip some Zaryte Vambraces`,
    description: `Obtain and equip some Zaryte Vambraces`,
    skillReqs: [
      { skill: 'Ranged', level: 80 },
      { skill: 'Defence', level: 45 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NEX,
    prerequisite: '',
  },
  826: {
    id: '826',
    label: `Keep the Veteran Void Knight Above 150 Hitpoints`,
    description: `Complete a game of Veteran Pest Control without letting the Void Knight fall below 150 Hitpoints`,
    skillReqs: [{ skill: 'Combat', level: 100 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.PC,
    prerequisite: '',
  },
  1137: {
    id: '1137',
    label: `Purchase a Celestial ring`,
    description: `Purchase a Celestial ring from Dusuri's Star Shop`,
    skillReqs: [{ skill: 'Mining', level: 10 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1086: {
    id: '1086',
    label: `25 Tempoross Kills`,
    description: `Help the Spirit Anglers subdue the Tempoross 25 times`,
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  869: {
    id: '869',
    label: `Cast Ice Blitz`,
    description: `Cast the Ice Blitz spell`,
    skillReqs: [{ skill: 'Magic', level: 82 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  866: {
    id: '866',
    label: `Cast Ice Burst`,
    description: `Cast the Ice Burst spell`,
    skillReqs: [{ skill: 'Magic', level: 70 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  858: {
    id: '858',
    label: `Check a grown Potato Cactus`,
    description: `Check the health of a Potato Cactus you've grown in Al Kharid`,
    skillReqs: [{ skill: 'Farming', level: 64 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1408: {
    id: '1408',
    label: `Complete Beneath Cursed Sands`,
    description: `Complete Beneath Cursed Sands quest`,
    skillReqs: [
      { skill: 'Agility', level: 62 },
      { skill: 'Crafting', level: 55 },
      { skill: 'Firemaking', level: 55 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.MASTER,
    prerequisite: '',
  },
  879: {
    id: '879',
    label: `Complete the Hard Desert Diary`,
    description: `Complete all of the hard tasks in the Desert Achievement Diary`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1186: {
    id: '1186',
    label: `Complete Tombs of Amascut`,
    description: `Complete Tombs of Amascut on normal or Expert`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1187: {
    id: '1187',
    label: `Complete Tombs of Amascut 25 times`,
    description: `Complete Tombs of Amascut on normal or Expert 25 times`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1240: {
    id: '1240',
    label: `Create the Colossal Rune Pouch`,
    description: `Create the Colossal Rune Pouch from Guardians of the Rift`,
    skillReqs: [
      { skill: 'Runecraft', level: 27 },
      { skill: 'Crafting', level: 56 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1176: {
    id: '1176',
    label: `Defeat Leviathan`,
    description: `Defeat Leviathan`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1178: {
    id: '1178',
    label: `Defeat Leviathan 150 times`,
    description: `Defeat Leviathan 150 times`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1177: {
    id: '1177',
    label: `Defeat Leviathan 50 times`,
    description: `Defeat Leviathan 50 times`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  864: {
    id: '864',
    label: `Defeat the Kalphite Queen`,
    description: `Defeat the Kalphite Queen in the Kalphite Lair`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KQ,
    prerequisite: '',
  },
  875: {
    id: '875',
    label: `Defeat the Kalphite Queen 150 Times`,
    description: `Defeat the Kalphite Queen in the Kalphite Lair 150 times`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KQ,
    prerequisite: '',
  },
  874: {
    id: '874',
    label: `Defeat the Kalphite Queen 50 Times`,
    description: `Defeat the Kalphite Queen in the Kalphite Lair 50 times`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KQ,
    prerequisite: '',
  },
  876: {
    id: '876',
    label: `Equip a Dragon 2-Handed Sword`,
    description: `Equip a Dragon 2-Handed Sword in the Kharidian Desert`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1246: {
    id: '1246',
    label: `Equip a full set of the Smith's outfit`,
    description: `Equip a full set of the Smith's outfit, purchased from the Giant's Foundry minigame`,
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1241: {
    id: '1241',
    label: `Equip the Abyssal Lantern`,
    description: `Obtain and Equip the Abyssal Lantern`,
    skillReqs: [{ skill: 'Runecraft', level: 27 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1235: {
    id: '1235',
    label: `Equip the Lightbearer`,
    description: `Obtain and Equip the Lightbearer`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1213: {
    id: '1213',
    label: `Giants' Foundry 150 quality sword`,
    description: `Hand in a sword with at least 150 quality in Giants' Foundry`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1215: {
    id: '1215',
    label: `Giants' Foundry 25 handins`,
    description: `Hand in 25 successful swords to Kovac`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1216: {
    id: '1216',
    label: `Giants' Foundry 50 handins`,
    description: `Hand in 50 successful swords to Kovac`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1192: {
    id: '1192',
    label: `Guardians of the Rift 25 Rifts closed`,
    description: `Close the Rift in the Temple of the Eye 25 times`,
    skillReqs: [{ skill: 'Runecraft', level: 27 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1573: {
    id: '1573',
    label: `Have Drew create 500 buckets`,
    description: `Create 500 buckets of sand at Drew's sandstorm machine`,
    skillReqs: [{ skill: 'Mining', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  856: {
    id: '856',
    label: `Make 20 Magic Potions`,
    description: `Make 20 Magic Potions`,
    skillReqs: [{ skill: 'Herblore', level: 76 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1340: {
    id: '1340',
    label: `Make 50 Menaphite Remedies`,
    description: `Make 50 Menaphite Remedies`,
    skillReqs: [{ skill: 'Herblore', level: 88 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1248: {
    id: '1248',
    label: `Obtain the Fish Barrel`,
    description: `Obtain the Fish Barrel from Tempoross`,
    skillReqs: [{ skill: 'Fishing', level: 35 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.TEMPOROSS,
    prerequisite: '',
  },
  845: {
    id: '845',
    label: `Pick a Summer Sq'irk`,
    description: `Pick a Summer Sq'irk in the Sorceress's Garden`,
    skillReqs: [{ skill: 'Thieving', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  851: {
    id: '851',
    label: `Pickpocket a Menaphite Thug 50 Times`,
    description: `Knock out and then pickpocket a Menaphite Thug 50 times`,
    skillReqs: [{ skill: 'Thieving', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  837: {
    id: '837',
    label: `Room 7 of Pyramid Plunder`,
    description: `Search the Golden Chest in Room 7 of Pyramid Plunder in Sophanem`,
    skillReqs: [{ skill: 'Thieving', level: 81 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  838: {
    id: '838',
    label: `Room 8 of Pyramid Plunder`,
    description: `Search the Golden Chest in Room 8 of Pyramid Plunder in Sophanem`,
    skillReqs: [{ skill: 'Thieving', level: 91 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  839: {
    id: '839',
    label: `Room 8 of Pyramid Plunder 25 Times`,
    description: `Search the Golden Chest in Room 8 of Pyramid Plunder in Sophanem 25 times`,
    skillReqs: [{ skill: 'Thieving', level: 91 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  353: {
    id: '353',
    label: `Smith 1,000 Adamant Dart Tips`,
    description: `Use an Anvil to smith 1,000 Adamant Dart Tips`,
    skillReqs: [{ skill: 'Smithing', level: 74 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  848: {
    id: '848',
    label: `Turn in 25 Autumn Sq'irkjuices to Osman`,
    description: `Turn in 25 Autumn Sq'irkjuices to Osman in Al Kharid in one go`,
    skillReqs: [{ skill: 'Thieving', level: 45 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1804: {
    id: '1804',
    label: `Blast Furnace 100 Mithril Bars`,
    description: `Smelt 100 Mithril bars at the Blast Furnace`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  566: {
    id: '566',
    label: `Cast Moonclan Teleport`,
    description: `Cast the Moonclan Teleport spell`,
    skillReqs: [{ skill: 'Magic', level: 69 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  568: {
    id: '568',
    label: `Cast Spellbook Swap`,
    description: `Cast the Spellbook Swap spell`,
    skillReqs: [{ skill: 'Magic', level: 96 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  629: {
    id: '629',
    label: `Collect Miscellania Resources at Full Approval`,
    description: `Collect some resources on Miscellania with an approval rating of 100%`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1806: {
    id: '1806',
    label: `Complete 25 laps of the Rellekka Agility Course`,
    description: `Complete 25 laps of the Rellekka Agility Course`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  625: {
    id: '625',
    label: `Complete the Hard Fremennik Diary`,
    description: `Complete all of the hard tasks in the Fremennik Achievement Diary`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  575: {
    id: '575',
    label: `Complete the Rellekka Agility Course`,
    description: `Complete a lap of the Rellekka Rooftop Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 80 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  582: {
    id: '582',
    label: `Create a Catherby Teleport Tablet`,
    description: `Create a Catherby Teleport Tablet`,
    skillReqs: [{ skill: 'Magic', level: 87 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  584: {
    id: '584',
    label: `Defeat a Basilisk Knight`,
    description: `Defeat a Basilisk Knight in the Jormungand's Prison`,
    skillReqs: [{ skill: 'Slayer', level: 60 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  468: {
    id: '468',
    label: `Defeat a Rune Dragon`,
    description: `Defeat a Rune Dragon in the Lithkren Vault`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1181: {
    id: '1181',
    label: `Defeat Duke Sucellus`,
    description: `Defeat Duke Sucellus`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1183: {
    id: '1183',
    label: `Defeat Duke Sucellus 150 times`,
    description: `Defeat Duke Sucellus 150 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1182: {
    id: '1182',
    label: `Defeat Duke Sucellus 50 times`,
    description: `Defeat Duke Sucellus 50 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  614: {
    id: '614',
    label: `Defeat Each Dagannoth King 150 Times`,
    description: `Defeat all three of the Dagannoth Kings in the Waterbirth Island Dungeon 150 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  613: {
    id: '613',
    label: `Defeat Each Dagannoth King 50 Times`,
    description: `Defeat all three of the Dagannoth Kings in the Waterbirth Island Dungeon 50 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  1193: {
    id: '1193',
    label: `Defeat Phantom Muspah`,
    description: `Defeat Phantom Muspah`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  1195: {
    id: '1195',
    label: `Defeat Phantom Muspah 150 times`,
    description: `Defeat Phantom Muspah 150 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  1194: {
    id: '1194',
    label: `Defeat Phantom Muspah 50 times`,
    description: `Defeat Phantom Muspah 50 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  596: {
    id: '596',
    label: `Defeat the Dagannoth Kings Without Leaving`,
    description: `Defeat all three of the Dagannoth Kings in the Waterbirth Island Dungeon without leaving their area`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  585: {
    id: '585',
    label: `Defeat Vorkath`,
    description: `Defeat Vorkath on Ungael`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  609: {
    id: '609',
    label: `Defeat Vorkath 150 Times`,
    description: `Defeat Vorkath on Ungael 150 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  608: {
    id: '608',
    label: `Defeat Vorkath 50 Times`,
    description: `Defeat Vorkath on Ungael 50 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  599: {
    id: '599',
    label: `Equip a Berserker Ring`,
    description: `Equip a Berserker Ring`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  595: {
    id: '595',
    label: `Equip a Brine Sabre`,
    description: `Equip a Brine Sabre`,
    skillReqs: [
      { skill: 'Attack', level: 40 },
      { skill: 'Slayer', level: 47 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  622: {
    id: '622',
    label: `Equip a Full Rockshell Armour Set`,
    description: `Equip a full set of Rockshell armour`,
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  620: {
    id: '620',
    label: `Equip a Full Skeletal Armour Set`,
    description: `Equip a full set of Skeletal armour`,
    skillReqs: [
      { skill: 'Defence', level: 40 },
      { skill: 'Magic', level: 40 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  621: {
    id: '621',
    label: `Equip a Full Spined Armour Set`,
    description: `Equip a full set of Spined armour`,
    skillReqs: [
      { skill: 'Defence', level: 40 },
      { skill: 'Ranged', level: 40 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  619: {
    id: '619',
    label: `Equip a Leaf-Bladed Battleaxe`,
    description: `Equip a Leaf-Bladed Battleaxe in The Fremennik Provinces`,
    skillReqs: [
      { skill: 'Attack', level: 65 },
      { skill: 'Slayer', level: 70 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  617: {
    id: '617',
    label: `Equip a Mud Battlestaff`,
    description: `Equip a Mud Battlestaff`,
    skillReqs: [
      { skill: 'Magic', level: 30 },
      { skill: 'Attack', level: 30 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  604: {
    id: '604',
    label: `Equip a Seer's Ring`,
    description: `Equip a Seer's Ring`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  618: {
    id: '618',
    label: `Equip a Seercull`,
    description: `Equip a Seercull`,
    skillReqs: [{ skill: 'Ranged', level: 50 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  601: {
    id: '601',
    label: `Equip a Warrior Ring`,
    description: `Equip a Warrior Ring`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  607: {
    id: '607',
    label: `Equip an Archer's Ring`,
    description: `Equip an Archer's Ring`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  590: {
    id: '590',
    label: `Equip an Ava's Assembler`,
    description: `Equip an Ava's Assembler`,
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  1227: {
    id: '1227',
    label: `Equip the Ancient Sceptre`,
    description: `Obtain and Equip the Ancient Sceptre`,
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Attack', level: 50 },
      { skill: 'Strength', level: 60 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  576: {
    id: '576',
    label: `Harvest a Snapdragon in Weiss`,
    description: `Harvest a Snapdragon you've grown in Weiss`,
    skillReqs: [{ skill: 'Farming', level: 62 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1840: {
    id: '1840',
    label: `Kill a Rock Lobster`,
    description: `Kill a Rock lobster`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1803: {
    id: '1803',
    label: `Obtain a Frozen Cache from a Cache`,
    description: `Obtain a Frozen cache from another Frozen cache`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  1551: {
    id: '1551',
    label: `Trade with Bardur`,
    description: `Trade with Bardur, in Waterbirth island dungeon`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  578: {
    id: '578',
    label: `Trap a Sabre-toothed Kyatt`,
    description: `Trap a Sabre-toothed Kyatt`,
    skillReqs: [{ skill: 'Hunter', level: 55 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  581: {
    id: '581',
    label: `Use Your Portal Nexus to Teleport to Weiss`,
    description: `Use a Portal Nexus in your Player Owned House to teleport to Weiss`,
    skillReqs: [
      { skill: 'Mining', level: 72 },
      { skill: 'Construction', level: 72 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  702: {
    id: '702',
    label: `Check a grown Papaya Tree in the Gnome Stronghold`,
    description: `Check the health of a Papaya Tree you've grown in the Tree Gnome Stronghold`,
    skillReqs: [{ skill: 'Farming', level: 57 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  744: {
    id: '744',
    label: `Complete Elemental Workshop II`,
    description: `Complete the Elemental Workshop II quest`,
    skillReqs: [
      { skill: 'Magic', level: 20 },
      { skill: 'Smithing', level: 30 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1516: {
    id: '1516',
    label: `Complete Path of Glouphrie`,
    description: `Complete the Path of Glouphrie quest`,
    skillReqs: [
      { skill: 'Strength', level: 60 },
      { skill: 'Slayer', level: 56 },
      { skill: 'Thieving', level: 56 },
      { skill: 'Ranged', level: 47 },
      { skill: 'Agility', level: 45 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1055: {
    id: '1055',
    label: `Complete the Hard Ardougne Diary`,
    description: `Complete all of the hard tasks in the Ardougne Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  761: {
    id: '761',
    label: `Complete the Hard Kandarin Diary`,
    description: `Complete all of the hard tasks in the Kandarin Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  688: {
    id: '688',
    label: `Complete the Seers' Village Agility Course`,
    description: `Complete a lap of the Seers' Village Rooftop Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 60 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  731: {
    id: '731',
    label: `Defeat 150 Demonic Gorillas`,
    description: `Defeat 150 Demonic Gorillas in the Crash Site Cavern`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  718: {
    id: '718',
    label: `Defeat a Cave Kraken`,
    description: `Defeat a Cave Kraken in Kraken Cove`,
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  706: {
    id: '706',
    label: `Defeat a Demonic Gorilla`,
    description: `Defeat a Demonic Gorilla in the Crash Site Cavern`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  705: {
    id: '705',
    label: `Defeat a Mithril Dragon`,
    description: `Defeat a Mithril Dragon in the Ancient Cavern`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  707: {
    id: '707',
    label: `Defeat a Smoke Devil`,
    description: `Defeat a Smoke Devil in the Smoke Devil Dungeon`,
    skillReqs: [{ skill: 'Slayer', level: 93 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  729: {
    id: '729',
    label: `Defeat the Kraken Boss 150 Times`,
    description: `Defeat the Kraken boss in Kraken Cove 150 times`,
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '',
  },
  728: {
    id: '728',
    label: `Defeat the Kraken Boss 50 Times`,
    description: `Defeat the Kraken boss in Kraken Cove 50 times`,
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '',
  },
  748: {
    id: '748',
    label: `Defeat the Penance Queen`,
    description: `Defeat the Penance Queen in Barbarian Assault`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.BA,
    prerequisite: '',
  },
  699: {
    id: '699',
    label: `Equip a Full Angler's Outfit`,
    description: `Equip a full set of Angler gear`,
    skillReqs: [{ skill: 'Fishing', level: 34 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  755: {
    id: '755',
    label: `Equip a Karamja Monkey Backpack`,
    description: `Equip a Karamja Monkey Backpack`,
    skillReqs: [{ skill: 'Agility', level: 48 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  722: {
    id: '722',
    label: `Equip a Trident of the Seas`,
    description: `Equip a Trident of the Seas`,
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Slayer', level: 87 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '',
  },
  1515: {
    id: '1515',
    label: `Equip a Warped Sceptre`,
    description: `Obtain and Equip a Warped Sceptre`,
    skillReqs: [
      { skill: 'Magic', level: 62 },
      { skill: 'Slayer', level: 56 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  751: {
    id: '751',
    label: `Equip an Ogre Expert Chompy Hat`,
    description: `Equip an Ogre Expert Chompy Hat`,
    skillReqs: [
      { skill: 'Fletching', level: 5 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Crafting', level: 5 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.CHOMPIES,
    prerequisite: '',
  },
  1517: {
    id: '1517',
    label: `Feed Longramble`,
    description: `Deliver some Tangled Toads Legs to Longramble`,
    skillReqs: [{ skill: 'Cooking', level: 40 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  700: {
    id: '700',
    label: `Fletch 100 Maple Longbow (u) in Kandarin`,
    description: `Fletch 100 Maple Longbow (u) in Kandarin`,
    skillReqs: [{ skill: 'Fletching', level: 55 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1536: {
    id: '1536',
    label: `Get 750 Target points`,
    description: `Achieve 750 points in the Target minigame in the Ranging Guild`,
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1439: {
    id: '1439',
    label: `Kill a Frogeel`,
    description: `Kill a Frogeel underneath the Tower of Life`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1440: {
    id: '1440',
    label: `Kill a Jubster`,
    description: `Kill a Jubster underneath the Tower of Life`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  685: {
    id: '685',
    label: `Pickpocket a Hero`,
    description: `Pickpocket any Hero`,
    skillReqs: [{ skill: 'Thieving', level: 80 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1541: {
    id: '1541',
    label: `Pickpocket a King Worm`,
    description: `Pickpocket a gnome for a King Worm`,
    skillReqs: [{ skill: 'Thieving', level: 75 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1446: {
    id: '1446',
    label: `Score a Goal in Gnomeball`,
    description: `Score a Goal in Gnomeball`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1814: {
    id: '1814',
    label: `Trade in 1000 Minnows`,
    description: `Trade in 1000 Minnows in the Fishing guild`,
    skillReqs: [{ skill: 'Fishing', level: 75 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1303: {
    id: '1303',
    label: `Trade in a cat for death runes`,
    description: `Trade in a cat for death runes in Ardougne`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  726: {
    id: '726',
    label: `Use the Chivalry Prayer`,
    description: `Use the Chivalry Prayer`,
    skillReqs: [
      { skill: 'Prayer', level: 60 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  647: {
    id: '647',
    label: `Check a grown Calquat Tree`,
    description: `Check the health of a Calquat Tree you've grown on Karamja`,
    skillReqs: [{ skill: 'Farming', level: 72 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  673: {
    id: '673',
    label: `Complete the Hard Karamja Diary`,
    description: `Complete all of the hard tasks in the Karamja Achievement Diary`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1197: {
    id: '1197',
    label: `Complete Tzhaar-Ket-Rak's first challenge`,
    description: `Complete Tzhaar-Ket-Rak's first challenge`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  1198: {
    id: '1198',
    label: `Complete Tzhaar-Ket-Rak's second challenge`,
    description: `Complete Tzhaar-Ket-Rak's second challenge`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  1199: {
    id: '1199',
    label: `Complete Tzhaar-Ket-Rak's third challenge`,
    description: `Complete Tzhaar-Ket-Rak's third challenge`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  637: {
    id: '637',
    label: `Cook 100 Karambwans`,
    description: `Cook 100 Karambwan`,
    skillReqs: [{ skill: 'Fishing', level: 30 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1395: {
    id: '1395',
    label: `Cook 20 Karambwans in a row`,
    description: `Cook 20 Karambwans in a row without burning them`,
    skillReqs: [{ skill: 'Cooking', level: 30 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  652: {
    id: '652',
    label: `Equip a Full Obsidian Armour Set`,
    description: `Equip a full set of Obsidian armour`,
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  677: {
    id: '677',
    label: `Equip a Red Topaz Machete`,
    description: `Equip a Red Topaz Machete`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  660: {
    id: '660',
    label: `Equip Some Dragon Platelegs or a Dragon Plateskirt`,
    description: `Equip either some Dragon Platelegs or a Dragon Plateskirt`,
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  681: {
    id: '681',
    label: `Find a Gout Tuber`,
    description: `Find a Gout Tuber in Tai Bwo Wannai`,
    skillReqs: [{ skill: 'Woodcutting', level: 35 }],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  641: {
    id: '641',
    label: `Receive 30 Agility Arena Tickets With No Mistakes`,
    description: `Receive 30 Agility Arena Tickets without missing any pillars`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  657: {
    id: '657',
    label: `Survive a Hit From TzTok-Jad Without Prayer`,
    description: `Survive a Magic or Ranged hit from TzTok-Jad in the TzHaar Fight Cave without using a protection Prayer`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  110: {
    id: '110',
    label: `1 Alchemical Hydra Kill`,
    description: `Kill the Alchemical Hydra in Mount Karuulm`,
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  114: {
    id: '114',
    label: `1 Chambers of Xeric`,
    description: `Complete the Chambers of Xeric`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  168: {
    id: '168',
    label: `1 Mimic Kill`,
    description: `Kill the Mimic as part of a Treasure Trail`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  107: {
    id: '107',
    label: `1 Skotizo Kill`,
    description: `Kill Skotizo beneath the Catacombs of Kourend`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SKOTIZO,
    prerequisite: '',
  },
  108: {
    id: '108',
    label: `10 Skotizo Kills`,
    description: `Kill Skotizo beneath the Catacombs of Kourend 10 times`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SKOTIZO,
    prerequisite: '',
  },
  112: {
    id: '112',
    label: `150 Alchemical Hydra Kills`,
    description: `Kill the Alchemical Hydra in Mount Karuulm 150 times`,
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  105: {
    id: '105',
    label: `150 Sarachnis Kills`,
    description: `Kill Sarachnis in the Forthos Dungeon 150 times`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '',
  },
  115: {
    id: '115',
    label: `25 Chambers of Xeric`,
    description: `Complete the Chambers of Xeric Normal or Challenge Mode 25 times`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  102: {
    id: '102',
    label: `300 Lizardmen Shaman Kills`,
    description: `Help the Shayzien House by killing 300 Lizardmen shamans`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  106: {
    id: '106',
    label: `300 Sarachnis Kills`,
    description: `Kill Sarachnis in the Forthos Dungeon 300 times`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '',
  },
  169: {
    id: '169',
    label: `5 Mimic Kills`,
    description: `Kill the Mimic as part of a Treasure Trail 5 times`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.GENERAL,
    prerequisite: '',
  },
  111: {
    id: '111',
    label: `50 Alchemical Hydra Kills`,
    description: `Kill the Alchemical Hydra in Mount Karuulm 50 times`,
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  116: {
    id: '116',
    label: `50 Chambers of Xeric`,
    description: `Complete the Chambers of Xeric Normal or Challenge Mode 50 times`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  70: {
    id: '70',
    label: `50 Wintertodt Kills`,
    description: `Help the Pyromancers subdue the Wintertodt 50 times`,
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.WINTERTODT,
    prerequisite: '',
  },
  1062: {
    id: '1062',
    label: `Activate an Arcane or Dexterous Prayer Scroll`,
    description: `Activate an Arcane or Dexterous Prayer scroll`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1356: {
    id: '1356',
    label: `Burn 200 Redwood Logs`,
    description: `Burn 200 Redwood Logs`,
    skillReqs: [
      { skill: 'Firemaking', level: 90 },
      { skill: 'Woodcutting', level: 90 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  1266: {
    id: '1266',
    label: `Chop 200 Redwood Logs`,
    description: `Chop 200 Redwood Logs from Redwood Trees`,
    skillReqs: [{ skill: 'Woodcutting', level: 90 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  73: {
    id: '73',
    label: `Complete 50 Farming Contracts`,
    description: `Complete 50 Farming Contracts for Guildmaster Jane in the Farming Guild`,
    skillReqs: [{ skill: 'Farming', level: 45 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1605: {
    id: '1605',
    label: `Complete the Shayzien Advanced Agility Course`,
    description: `Complete a lap of the Shayzien Advanced Agility Course`,
    skillReqs: [{ skill: 'Attack', level: 45 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1603: {
    id: '1603',
    label: `Craft 100 Blood runes`,
    description: `Craft 100 Blood runes at the Kourend Blood Altar`,
    skillReqs: [
      { skill: 'Runecraft', level: 77 },
      { skill: 'Mining', level: 38 },
      { skill: 'Crafting', level: 38 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1635: {
    id: '1635',
    label: `Create 10 Dynamite`,
    description: `Create 10 Dynamite`,
    skillReqs: [
      { skill: 'Mining', level: 42 },
      { skill: 'Woodcutting', level: 42 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1639: {
    id: '1639',
    label: `Defeat a Gang Boss`,
    description: `Defeat a Gang Boss`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  82: {
    id: '82',
    label: `Enter the Farming Guild's High Tier`,
    description: `Enter the high tier of the Farming Guild in the Kebos Lowlands`,
    skillReqs: [{ skill: 'Farming', level: 85 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  130: {
    id: '130',
    label: `Equip a Brimstone Ring`,
    description: `Obtain and equip a Brimstone ring`,
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  124: {
    id: '124',
    label: `Equip a Dust Battlestaff`,
    description: `Obtain and equip a Dust battlestaff`,
    skillReqs: [
      { skill: 'Slayer', level: 5 },
      { skill: 'Attack', level: 30 },
      { skill: 'Magic', level: 30 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  186: {
    id: '186',
    label: `Equip a Fish Sack`,
    description: `Equip a Fish sack, purchased from Alry on Molch Island`,
    skillReqs: [
      { skill: 'Hunter', level: 35 },
      { skill: 'Fishing', level: 43 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1068: {
    id: '1068',
    label: `Equip a Sarachnis Cudgel`,
    description: `Equip a Sarachnis Cudgel`,
    skillReqs: [{ skill: 'Attack', level: 65 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SARACHNIS,
    prerequisite: '',
  },
  123: {
    id: '123',
    label: `Equip Boots of Brimstone`,
    description: `Obtain and equip a pair of Boots of brimstone`,
    skillReqs: [
      { skill: 'Slayer', level: 84 },
      { skill: 'Defence', level: 70 },
      { skill: 'Magic', level: 70 },
      { skill: 'Ranged', level: 70 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  128: {
    id: '128',
    label: `Equip Ferocious Gloves`,
    description: `Obtain and equip a pair of Ferocious gloves`,
    skillReqs: [
      { skill: 'Slayer', level: 95 },
      { skill: 'Attack', level: 80 },
      { skill: 'Defence', level: 80 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  183: {
    id: '183',
    label: `Equip the Pyromancer's Garb`,
    description: `Equip a full set of Pyromancer's garb, rewarded from the Wintertodt`,
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.WINTERTODT,
    prerequisite: '',
  },
  175: {
    id: '175',
    label: `Equip Tier 5 Shayzien Armour`,
    description: `Equip a full set of tier 5 Shayzien armour`,
    skillReqs: [{ skill: 'Defence', level: 20 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1362: {
    id: '1362',
    label: `Fletch a Redwood Shield`,
    description: `Fletch a Redwood Shield`,
    skillReqs: [
      { skill: 'Fletching', level: 92 },
      { skill: 'Woodcutting', level: 90 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1633: {
    id: '1633',
    label: `Harvest 25 Zamorak's Grapes`,
    description: `Harvest 25 Zamorak's Grapes`,
    skillReqs: [
      { skill: 'Farming', level: 36 },
      { skill: 'Prayer', level: 50 },
      { skill: 'Cooking', level: 65 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  137: {
    id: '137',
    label: `Kourend and Kebos Hard Diary Tasks`,
    description: `Complete all tasks in the hard tier of the Kourend and Kebos achievement diary`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  89: {
    id: '89',
    label: `Obtain Runite Ore at the Blast Mine`,
    description: `Obtain a piece of Runite ore while taking part in the Blast Mine`,
    skillReqs: [{ skill: 'Mining', level: 75 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  78: {
    id: '78',
    label: `Plant 100 Bologano Seeds`,
    description: `Plant 100 Bologano seeds in the Tithe Farm minigame`,
    skillReqs: [{ skill: 'Farming', level: 54 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  79: {
    id: '79',
    label: `Plant 100 Logavano Seeds`,
    description: `Plant 100 Logavano seeds in the Tithe Farm minigame`,
    skillReqs: [{ skill: 'Farming', level: 74 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1263: {
    id: '1263',
    label: `Plant an Anima Seed`,
    description: `Plant an Anima Seed in the Farming Guild`,
    skillReqs: [{ skill: 'Farming', level: 76 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  443: {
    id: '443',
    label: `Catch a Herbiboar 50 Times`,
    description: `Catch a Herbiboar on Fossil Island 50 times`,
    skillReqs: [
      { skill: 'Hunter', level: 80 },
      { skill: 'Herblore', level: 31 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  452: {
    id: '452',
    label: `Check a grown Magic Tree in Lumbridge`,
    description: `Check the health of a Magic Tree you've grown in Lumbridge`,
    skillReqs: [{ skill: 'Farming', level: 75 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  490: {
    id: '490',
    label: `Complete the Hard Lumbridge & Draynor Diary`,
    description: `Complete all of the hard tasks in the Lumbridge & Draynor Achievement Diary`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1049: {
    id: '1049',
    label: `Complete the Hard Varrock Diary`,
    description: `Complete all of the hard tasks in the Varrock Achievement Diary`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1758: {
    id: '1758',
    label: `Defeat 150 Tormented Demons`,
    description: `Defeat 150 Tormented Demons`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1759: {
    id: '1759',
    label: `Defeat 300 Tormented Demons`,
    description: `Defeat 300 Tormented Demons`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  480: {
    id: '480',
    label: `Defeat the Abyssal Sire`,
    description: `Defeat the Abyssal Sire in the Abyssal Nexus`,
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  473: {
    id: '473',
    label: `Equip a Hill Giant Club`,
    description: `Equip a Hill Giant Club`,
    skillReqs: [{ skill: 'Attack', level: 40 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.OBOR,
    prerequisite: '',
  },
  1761: {
    id: '1761',
    label: `Equip a Tormented Weapon`,
    description: `Equip either Emberlight, Purging staff or Scorching Bow`,
    skillReqs: [{ skill: 'Attack', level: 77 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1742: {
    id: '1742',
    label: `Equip a Zombie Axe`,
    description: `Equip a Zombie Axe`,
    skillReqs: [
      { skill: 'Smithing', level: 70 },
      { skill: 'Attack', level: 65 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  477: {
    id: '477',
    label: `Equip Bryophyta's Staff`,
    description: `Equip Bryophyta's Staff`,
    skillReqs: [
      { skill: 'Magic', level: 30 },
      { skill: 'Attack', level: 30 },
      { skill: 'Crafting', level: 62 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BRYOPHYTA,
    prerequisite: '',
  },
  1760: {
    id: '1760',
    label: `Equip Burning claws`,
    description: `Equip Burning claws`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  484: {
    id: '484',
    label: `Offer an Unsired to the Font of Consumption`,
    description: `Offer an Unsired to the Font of Consumption in the Abyssal Nexus`,
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  1641: {
    id: '1641',
    label: `Scurrius Combat Achievements`,
    description: `Complete all of the Combat Achievements for Scurrius`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SCURRIUS,
    prerequisite: '',
  },
  440: {
    id: '440',
    label: `Set up a Magic Bird House`,
    description: `Set up a Magic Bird House on Fossil Island`,
    skillReqs: [
      { skill: 'Hunter', level: 74 },
      { skill: 'Crafting', level: 75 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  464: {
    id: '464',
    label: `Use Enriched Bones on the Strange Machine`,
    description: `Use Enriched Bones on the Strange Machine on Fossil Island`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1723: {
    id: '1723',
    label: `1 Araxxor Kill`,
    description: `Kill the Araxxor in Morytania Spider cave`,
    skillReqs: [{ skill: 'Slayer', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ARAXXOR,
    prerequisite: '',
  },
  1725: {
    id: '1725',
    label: `150 Araxxor Kills`,
    description: `Kill the Araxxor in Morytania Spider cave 150 times`,
    skillReqs: [{ skill: 'Slayer', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ARAXXOR,
    prerequisite: '',
  },
  1724: {
    id: '1724',
    label: `50 Araxxor Kills`,
    description: `Kill the Araxxor in Morytania Spider cave 50 times`,
    skillReqs: [{ skill: 'Slayer', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ARAXXOR,
    prerequisite: '',
  },
  1355: {
    id: '1355',
    label: `Burn 20 Blisterwood Logs`,
    description: `Burn 20 Blisterwood Logs`,
    skillReqs: [
      { skill: 'Firemaking', level: 62 },
      { skill: 'Woodcutting', level: 62 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  960: {
    id: '960',
    label: `Complete Haunted Mine`,
    description: `Complete the Haunted Mine quest`,
    skillReqs: [{ skill: 'Crafting', level: 35 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  961: {
    id: '961',
    label: `Complete Sins of the Father`,
    description: `Complete the Sins of the Father quest`,
    skillReqs: [
      { skill: 'Woodcutting', level: 62 },
      { skill: 'Fletching', level: 60 },
      { skill: 'Crafting', level: 56 },
      { skill: 'Agility', level: 52 },
      { skill: 'Attack', level: 50 },
      { skill: 'Slayer', level: 50 },
      { skill: 'Magic', level: 49 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.MASTER,
    prerequisite: '',
  },
  954: {
    id: '954',
    label: `Complete the Hard Morytania Diary`,
    description: `Complete all of the hard tasks in the Morytania Achievement Diary`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1587: {
    id: '1587',
    label: `Cook 20 Sharks in Darkmeyer`,
    description: `Cook 20 Sharks in Darkmeyer`,
    skillReqs: [{ skill: 'Cooking', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  939: {
    id: '939',
    label: `Create an Amulet of Blood Fury`,
    description: `Create an Amulet of Blood Fury`,
    skillReqs: [
      { skill: 'Crafting', level: 90 },
      { skill: 'Magic', level: 87 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1078: {
    id: '1078',
    label: `Defeat Phosani's Nightmare`,
    description: `Defeat Phosani's Nightmare in the Sisterhood Sanctuary`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  949: {
    id: '949',
    label: `Defeat the Grotesque Guardians`,
    description: `Defeat the Grotesque Guardians at the Slayer Tower`,
    skillReqs: [{ skill: 'Slayer', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '',
  },
  951: {
    id: '951',
    label: `Defeat the Grotesque Guardians 150 Times`,
    description: `Defeat the Grotesque Guardians at the Slayer Tower 150 times`,
    skillReqs: [{ skill: 'Slayer', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '',
  },
  950: {
    id: '950',
    label: `Defeat the Grotesque Guardians 50 Times`,
    description: `Defeat the Grotesque Guardians at the Slayer Tower 50 times`,
    skillReqs: [{ skill: 'Slayer', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '',
  },
  919: {
    id: '919',
    label: `Defeat The Nightmare`,
    description: `Defeat The Nightmare in the Sisterhood Sanctuary`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1079: {
    id: '1079',
    label: `Defeat The Nightmare 25 times`,
    description: `Defeat The Nightmare or Phosani's Nightmare 25 times combined`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1129: {
    id: '1129',
    label: `Equip a Full Set of Zealot's Robes`,
    description: `Equip a Zealot's Helm, a Zealot's Robe Top, a Zealot's Robe Bottom and a Zealot's Boots`,
    skillReqs: [{ skill: 'Firemaking', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  937: {
    id: '937',
    label: `Equip a Nightmare Staff`,
    description: `Equip a normal Nightmare Staff`,
    skillReqs: [
      { skill: 'Magic', level: 72 },
      { skill: 'Hitpoints', level: 50 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  940: {
    id: '940',
    label: `Equip an Avernic Defender`,
    description: `Equip an Avernic Defender`,
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  930: {
    id: '930',
    label: `Equip any Full Barrows Armour Set`,
    description: `Equip a full set of any Barrows armour + weapon`,
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1729: {
    id: '1729',
    label: `Equip Aranea Boots`,
    description: `Equip Aranea Boots`,
    skillReqs: [{ skill: 'Slayer', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1506: {
    id: '1506',
    label: `Equip Full Ahrims Armour Set`,
    description: `Equip a full set of Ahrims items`,
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1508: {
    id: '1508',
    label: `Equip Full Dharoks Armour Set`,
    description: `Equip a full set of Dharoks items`,
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1507: {
    id: '1507',
    label: `Equip Full Guthans Armour Set`,
    description: `Equip a full set of Guthans items`,
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1511: {
    id: '1511',
    label: `Equip Full Karils Armour Set`,
    description: `Equip a full set of Karils items`,
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1510: {
    id: '1510',
    label: `Equip Full Torags Armour Set`,
    description: `Equip a full set of Torags items`,
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  1509: {
    id: '1509',
    label: `Equip Full Veracs Armour Set`,
    description: `Equip a full set of Veracs items`,
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Attack', level: 70 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.BARROWS,
    prerequisite: '',
  },
  901: {
    id: '901',
    label: `Floor 4 of the Hallowed Sepulchre`,
    description: `Complete floor 4 of the Hallowed Sepulchre in Darkmeyer`,
    skillReqs: [{ skill: 'Agility', level: 82 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  907: {
    id: '907',
    label: `Obtain Every Hallowed Tool`,
    description: `Obtain all four Hallowed Tools from the Hallowed Sepulchre`,
    skillReqs: [{ skill: 'Agility', level: 52 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1132: {
    id: '1132',
    label: `Open a Gold Chest`,
    description: `Open a Gold Chest from Shades of Mort'ton`,
    skillReqs: [{ skill: 'Firemaking', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.SHADES,
    prerequisite: '',
  },
  911: {
    id: '911',
    label: `Pickpocket a Vyre 50 Times`,
    description: `Pickpocket a Vyre 50 times`,
    skillReqs: [{ skill: 'Thieving', level: 82 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1322: {
    id: '1322',
    label: `Unlock permanent boat travel with Andras`,
    description: `Unlock permanent boat travel with Andras in Morytania`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  518: {
    id: '518',
    label: `Check a grown Crystal Tree`,
    description: `Check the health of a Crystal Tree you've grown in Prifddinas`,
    skillReqs: [{ skill: 'Farming', level: 74 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1385: {
    id: '1385',
    label: `Chop 100 Teak Logs in Prifddinas`,
    description: `Chop 100 Teak Logs in Prifddinas`,
    skillReqs: [{ skill: 'Woodcutting', level: 35 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1765: {
    id: '1765',
    label: `Chop 50 Magic Logs in Tirannwn`,
    description: `Chop 50 Magic logs in Tirannwn`,
    skillReqs: [{ skill: 'Woodcutting', level: 75 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1384: {
    id: '1384',
    label: `Complete 50 laps of the Prifddinas Agility Course`,
    description: `Complete 50 laps of the Prifddinas Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 75 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  535: {
    id: '535',
    label: `Complete the Corrupted Gauntlet`,
    description: `Complete the Corrupted Gauntlet in Prifddinas`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  534: {
    id: '534',
    label: `Complete the Gauntlet`,
    description: `Complete the Gauntlet in Prifddinas`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  522: {
    id: '522',
    label: `Complete the Prifddinas Agility Course`,
    description: `Complete a lap of the Prifddinas Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 75 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  523: {
    id: '523',
    label: `Complete the Prifddinas Agility Course in 1:10`,
    description: `Complete a lap of the Prifddinas Agility Course in 1:10 or less`,
    skillReqs: [{ skill: 'Agility', level: 75 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  525: {
    id: '525',
    label: `Craft 50 Death Runes`,
    description: `Craft 50 Death Runes from Essence at the Death Altar`,
    skillReqs: [{ skill: 'Runecraft', level: 65 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  530: {
    id: '530',
    label: `Craft a Piece of Crystal Armour`,
    description: `Use a Singing Bowl to craft any piece of Crystal armour`,
    skillReqs: [
      { skill: 'Smithing', level: 70 },
      { skill: 'Crafting', level: 70 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  558: {
    id: '558',
    label: `Craft an Eternal Teleport Crystal`,
    description: `Use a Singing Bowl to craft an Eternal Teleport Crystal`,
    skillReqs: [
      { skill: 'Smithing', level: 80 },
      { skill: 'Crafting', level: 80 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1762: {
    id: '1762',
    label: `Create 25 Divine Super Attack Potions`,
    description: `Create 25 Divine Super Attack Potions`,
    skillReqs: [{ skill: 'Herblore', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1763: {
    id: '1763',
    label: `Create 25 Divine Super Strength Potions`,
    description: `Create 25 Divine Super Strength Potions`,
    skillReqs: [{ skill: 'Herblore', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  542: {
    id: '542',
    label: `Defeat a Dark Beast in Tirannwn`,
    description: `Defeat a Dark Beast in Tirannwn`,
    skillReqs: [{ skill: 'Slayer', level: 90 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  555: {
    id: '555',
    label: `Defeat a Nechryael in Tirannwn`,
    description: `Defeat a Nechryael in Tirannwn`,
    skillReqs: [{ skill: 'Slayer', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  538: {
    id: '538',
    label: `Defeat Zalcano`,
    description: `Defeat Zalcano in Prifddinas`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.ZALCANO,
    prerequisite: '',
  },
  550: {
    id: '550',
    label: `Defeat Zalcano 50 Times`,
    description: `Defeat Zalcano in Prifddinas 50 times`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.ZALCANO,
    prerequisite: '',
  },
  540: {
    id: '540',
    label: `Defeat Zulrah`,
    description: `Defeat Zulrah at the Poison Waste`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  553: {
    id: '553',
    label: `Defeat Zulrah 50 Times`,
    description: `Defeat Zulrah at the Poison Waste 50 times`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  515: {
    id: '515',
    label: `Dissect 50 Sacred Eels`,
    description: `Dissect 50 Sacred Eels`,
    skillReqs: [
      { skill: 'Fishing', level: 87 },
      { skill: 'Cooking', level: 72 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  546: {
    id: '546',
    label: `Equip a Crystal Bow`,
    description: `Equip a Crystal Bow`,
    skillReqs: [
      { skill: 'Agility', level: 50 },
      { skill: 'Ranged', level: 70 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  549: {
    id: '549',
    label: `Equip a Crystal Shield`,
    description: `Equip a Crystal Shield`,
    skillReqs: [
      { skill: 'Agility', level: 50 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  544: {
    id: '544',
    label: `Equip a Dark Bow in Tirannwn`,
    description: `Equip a Dark Bow in Tirannwn`,
    skillReqs: [
      { skill: 'Ranged', level: 60 },
      { skill: 'Slayer', level: 90 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1512: {
    id: '1512',
    label: `Equip any piece of Crystal Armour`,
    description: `Equip either the Crystal Helmet, Body or Legs`,
    skillReqs: [{ skill: 'Defence', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  561: {
    id: '561',
    label: `Find Every Memoriam Crystal`,
    description: `Find every Memoriam Crystal in Prifddinas and add them to the Memoriam Device`,
    skillReqs: [{ skill: 'Agility', level: 75 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1388: {
    id: '1388',
    label: `Kill 50 Elves in Tirannwn`,
    description: `Kill 50 Elves in Tirannwn`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1808: {
    id: '1808',
    label: `Load a blowpipe with Rune Darts`,
    description: `Load a Toxic Blowpipe with Rune Darts`,
    skillReqs: [{ skill: 'Fletching', level: 53 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1386: {
    id: '1386',
    label: `Make 50 Teak Planks in Prifddinas`,
    description: `Make 50 Teak Planks in Prifddinas`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1378: {
    id: '1378',
    label: `Mine 200 Soft Clay in Tirannwn`,
    description: `Mine 200 Soft Clay in Tirannwn`,
    skillReqs: [{ skill: 'Mining', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  562: {
    id: '562',
    label: `Open the Enhanced Crystal Chest`,
    description: `Open the Enhanced Crystal Chest in Prifddinas`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  526: {
    id: '526',
    label: `Pickpocket an Elf 50 Times`,
    description: `Pickpocket an Elf 50 times`,
    skillReqs: [{ skill: 'Thieving', level: 85 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1513: {
    id: '1513',
    label: `Receive a Dragonstone Amulet from an Impling`,
    description: `Receive a Dragonstone Amulet from a Crystal Impling`,
    skillReqs: [{ skill: 'Hunter', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1389: {
    id: '1389',
    label: `Use a prayer altar to restore 90 prayer in Prifddinas`,
    description: `Use a prayer altar to restore 90 prayer points in Prifddinas`,
    skillReqs: [{ skill: 'Prayer', level: 90 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1745: {
    id: '1745',
    label: `150 Amoxliatl Kills`,
    description: `Kill the Amoxliatl in Varlamore 150 times`,
    skillReqs: [{ skill: 'Slayer', level: 48 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.AMOXLIATL,
    prerequisite: '',
  },
  1732: {
    id: '1732',
    label: `150 Hueycoatl Kills`,
    description: `Kill the Hueycoatl in Varlamore 150 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HUEYCOATL,
    prerequisite: '',
  },
  1744: {
    id: '1744',
    label: `50 Amoxliatl Kills`,
    description: `Kill the Amoxliatl in Varlamore 50 times`,
    skillReqs: [{ skill: 'Slayer', level: 48 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.AMOXLIATL,
    prerequisite: '',
  },
  1731: {
    id: '1731',
    label: `50 Hueycoatl Kills`,
    description: `Kill the Hueycoatl in Varlamore 50 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HUEYCOATL,
    prerequisite: '',
  },
  1772: {
    id: '1772',
    label: `Amoxliatl Combat Achievements`,
    description: `Complete all of the Combat Achievements for Amoxliatl`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.AMOXLIATL,
    prerequisite: '',
  },
  1697: {
    id: '1697',
    label: `Catch 25 Sunlight Antelopes`,
    description: `Catch 25 Sunlight Antelopes`,
    skillReqs: [{ skill: 'Hunter', level: 72 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1695: {
    id: '1695',
    label: `Check the health of Mahogany Tree in Marcellus's Patch`,
    description: `Check the health of Mahogany Tree in Marcellus's Patch`,
    skillReqs: [{ skill: 'Farming', level: 55 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1719: {
    id: '1719',
    label: `Chop 20 Magic Logs in Varlamore`,
    description: `Chop 20 Magic logs in Varlamore`,
    skillReqs: [{ skill: 'Woodcutting', level: 75 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1660: {
    id: '1660',
    label: `Complete 50 Hunter Rumours`,
    description: `Complete 50 Hunter Rumours`,
    skillReqs: [{ skill: 'Hunter', level: 46 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1661: {
    id: '1661',
    label: `Complete a Ribbiting Tale`,
    description: `Complete a Ribbiting Tale of a Lily Pad Labour Dispute`,
    skillReqs: [{ skill: 'Woodcutting', level: 15 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.NOVICE,
    prerequisite: '',
  },
  1810: {
    id: '1810',
    label: `Complete Death on the Isle`,
    description: `Complete Death on the Isle`,
    skillReqs: [
      { skill: 'Thieving', level: 34 },
      { skill: 'Agility', level: 32 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.INTERMEDIATE,
    prerequisite: '',
  },
  1770: {
    id: '1770',
    label: `Complete Perilous Moons`,
    description: `Complete the Perilous Moons quest`,
    skillReqs: [
      { skill: 'Slayer', level: 48 },
      { skill: 'Hunter', level: 20 },
      { skill: 'Fishing', level: 20 },
      { skill: 'Runecraft', level: 20 },
      { skill: 'Construction', level: 10 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.QUESTS,
    subcategory: CATEGORY.QUESTS.subcategories.EXPERIENCED,
    prerequisite: '',
  },
  1665: {
    id: '1665',
    label: `Complete Wave 6 of Fortis Colosseum`,
    description: `Complete Wave 6 of Fortis Colosseum`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1711: {
    id: '1711',
    label: `Cook 100 Moonlight Antelopes`,
    description: `Cook 100 Moonlight Antelopes`,
    skillReqs: [
      { skill: 'Hunter', level: 91 },
      { skill: 'Cooking', level: 92 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1689: {
    id: '1689',
    label: `Craft 1000 Sunfire Runes`,
    description: `Craft 1000 Sunfire Runes`,
    skillReqs: [{ skill: 'Runecraft', level: 33 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1693: {
    id: '1693',
    label: `Create 100 Jugs of Blessed Sunfire Wine`,
    description: `Create 100 Jugs of Blessed Sunfire Wine`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1166: {
    id: '1166',
    label: `Defeat Vardorvis`,
    description: `Defeat Vardorvis`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1168: {
    id: '1168',
    label: `Defeat Vardorvis 150 times`,
    description: `Defeat Vardorvis 150 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1167: {
    id: '1167',
    label: `Defeat Vardorvis 50 times`,
    description: `Defeat Vardorvis 50 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1670: {
    id: '1670',
    label: `Equip a piece of Sunfire Fanatic`,
    description: `Equip a piece of Sunfire Fanatic armour`,
    skillReqs: [
      { skill: 'Prayer', level: 60 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1674: {
    id: '1674',
    label: `Equip Echo Boots`,
    description: `Equip Echo Boots`,
    skillReqs: [{ skill: 'Defence', level: 75 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ARAXXOR,
    prerequisite: '',
  },
  1737: {
    id: '1737',
    label: `Equip full Alchemists outfit`,
    description: `Equip full Alchemists outfit from the Mixology shop`,
    skillReqs: [{ skill: 'Herblore', level: 60 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1678: {
    id: '1678',
    label: `Equip full Blood Moon armour`,
    description: `Equip full Blood Moon armour`,
    skillReqs: [
      { skill: 'Strength', level: 75 },
      { skill: 'Defence', level: 50 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1682: {
    id: '1682',
    label: `Equip full Blue Moon armour`,
    description: `Equip full Blue Moon armour`,
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Defence', level: 50 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1680: {
    id: '1680',
    label: `Equip full Eclipse Moon armour`,
    description: `Equip full Eclipse Moon armour`,
    skillReqs: [
      { skill: 'Ranged', level: 75 },
      { skill: 'Defence', level: 50 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1706: {
    id: '1706',
    label: `Equip full Guild Hunter Outfit`,
    description: `Equip full Guild Hunter Outfit`,
    skillReqs: [{ skill: 'Hunter', level: 46 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1734: {
    id: '1734',
    label: `Equip full Hueycoatl armour`,
    description: `Equip full Hueycoatl armour`,
    skillReqs: [
      { skill: 'Crafting', level: 88 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HUEYCOATL,
    prerequisite: '',
  },
  1671: {
    id: '1671',
    label: `Equip full Sunfire Fanatic`,
    description: `Equip a full set of Sunfire Fanatic armour`,
    skillReqs: [
      { skill: 'Prayer', level: 60 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1747: {
    id: '1747',
    label: `Equip Glacial Temotli`,
    description: `Equip the Glacial Temotli`,
    skillReqs: [
      { skill: 'Slayer', level: 48 },
      { skill: 'Attack', level: 55 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.AMOXLIATL,
    prerequisite: '',
  },
  1773: {
    id: '1773',
    label: `Hueycoatl Combat Achievements`,
    description: `Complete all of the Combat Achievements for Hueycoatl`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HUEYCOATL,
    prerequisite: '',
  },
  1739: {
    id: '1739',
    label: `Make 100 Prayer Regeneration Potions`,
    description: `Make 100 Prayer Regeneration Potions`,
    skillReqs: [{ skill: 'Herblore', level: 60 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1738: {
    id: '1738',
    label: `Make 50 Goading Potions`,
    description: `Make 50 Goading Potions`,
    skillReqs: [{ skill: 'Herblore', level: 60 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1707: {
    id: '1707',
    label: `Obtain the Huntsman's Kit`,
    description: `Obtain the Huntsman's Kit`,
    skillReqs: [{ skill: 'Hunter', level: 46 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1708: {
    id: '1708',
    label: `Open a Master Hunter's Loot Sack`,
    description: `Open a Master Hunter's Loot Sack`,
    skillReqs: [{ skill: 'Hunter', level: 91 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1735: {
    id: '1735',
    label: `Purchase the Reagents Pouch`,
    description: `Purchase the Reagents Pouch from the Mixology shop`,
    skillReqs: [{ skill: 'Herblore', level: 60 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1703: {
    id: '1703',
    label: `Steal a Blessed Bone Statuette`,
    description: `Steal a Blessed Bone Statuette`,
    skillReqs: [{ skill: 'Thieving', level: 50 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1688: {
    id: '1688',
    label: `Subdue the Moons of Peril 50 times`,
    description: `Subdue the Moons of Peril 50 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1662: {
    id: '1662',
    label: `Use the Bank Chest inside Fortis Colosseum`,
    description: `Use the Bank Chest inside Fortis Colosseum`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1663: {
    id: '1663',
    label: `Use the Fortis Salute emote`,
    description: `Use the Fortis Salute emote`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  972: {
    id: '972',
    label: `Catch 100 Dark Crabs`,
    description: `Catch 100 Dark Crabs in the Wilderness`,
    skillReqs: [{ skill: 'Fishing', level: 85 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1028: {
    id: '1028',
    label: `Complete the Hard Wilderness Diary`,
    description: `Complete all of the hard tasks in the Wilderness Achievement Diary`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  981: {
    id: '981',
    label: `Craft an Air Battlestaff in the Wilderness`,
    description: `Craft an Air Battlestaff in the Wilderness`,
    skillReqs: [{ skill: 'Crafting', level: 66 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1590: {
    id: '1590',
    label: `Cross the Chaos Temple Stone Shortcut`,
    description: `Cross the Chaos Temple Stone Shortcut`,
    skillReqs: [{ skill: 'Agility', level: 72 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  992: {
    id: '992',
    label: `Defeat Callisto`,
    description: `Defeat Callisto in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1405: {
    id: '1405',
    label: `Defeat Callisto 150 times`,
    description: `Defeat Callisto or Artio 150 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1404: {
    id: '1404',
    label: `Defeat Callisto 50 times`,
    description: `Defeat Callisto or Artio 50 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  991: {
    id: '991',
    label: `Defeat Scorpia`,
    description: `Defeat Scorpia in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  999: {
    id: '999',
    label: `Defeat the Chaos Elemental`,
    description: `Defeat the Chaos Elemental in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1004: {
    id: '1004',
    label: `Defeat the Corporeal Beast`,
    description: `Defeat the Corporeal Beast in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1005: {
    id: '1005',
    label: `Defeat the Corporeal Beast 50 Times`,
    description: `Defeat the Corporeal Beast in the Wilderness 50 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1000: {
    id: '1000',
    label: `Defeat the King Black Dragon`,
    description: `Defeat the King Black Dragon in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  988: {
    id: '988',
    label: `Defeat Venenatis`,
    description: `Defeat Venenatis in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1399: {
    id: '1399',
    label: `Defeat Venenatis 150 times`,
    description: `Defeat Venenatis or Spindel 150 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1398: {
    id: '1398',
    label: `Defeat Venenatis 50 times`,
    description: `Defeat Venenatis or Spindel 50 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  996: {
    id: '996',
    label: `Defeat Vet'ion`,
    description: `Defeat Vet'ion in the Wilderness`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1402: {
    id: '1402',
    label: `Defeat Vet'ion 150 times`,
    description: `Defeat Vet'ion or Calvar'ion 150 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1401: {
    id: '1401',
    label: `Defeat Vet'ion 50 times`,
    description: `Defeat Vet'ion or Calvar'ion 50 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  978: {
    id: '978',
    label: `Equip 100 Black Chinchompas`,
    description: `Equip a stack of at least 100 Black Chinchompas`,
    skillReqs: [
      { skill: 'Hunter', level: 73 },
      { skill: 'Ranged', level: 65 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  979: {
    id: '979',
    label: `Equip 250 Black Chinchompas`,
    description: `Equip a stack of at least 250 Black Chinchompas`,
    skillReqs: [
      { skill: 'Hunter', level: 73 },
      { skill: 'Ranged', level: 65 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1003: {
    id: '1003',
    label: `Equip a Dragon 2-Handed Sword in the Wilderness`,
    description: `Equip a Dragon 2-Handed Sword in the Wilderness`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1013: {
    id: '1013',
    label: `Equip an Enchanted Slayer Staff`,
    description: `Equip an Enchanted Slayer Staff`,
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Slayer', level: 55 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1022: {
    id: '1022',
    label: `Imbue a God Cape`,
    description: `Imbue either a Saradomin Cape, a Guthix Cape or a Zamorak Cape`,
    skillReqs: [{ skill: 'Magic', level: 75 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1294: {
    id: '1294',
    label: `Loot a dragonstone from the Rogues Castle`,
    description: `Loot a dragonstone from the chest in Rogue's Castle`,
    skillReqs: [{ skill: 'Thieving', level: 84 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1344: {
    id: '1344',
    label: `Open 10 Muddy Chests`,
    description: `Open 10 Muddy Chests`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1645: {
    id: '1645',
    label: `Open 15 Zombie Pirate Lockers`,
    description: `Open 15 Zombie Pirate Lockers`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1342: {
    id: '1342',
    label: `Open 50 Larran's Chests`,
    description: `Open 50 of either Larran's Small Chest or Larran's Big Chest`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1646: {
    id: '1646',
    label: `Open 50 Zombie Pirate Lockers`,
    description: `Open 50 Zombie Pirate Lockers`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1647: {
    id: '1647',
    label: `Redeem 50 Wilderness Agility Tickets`,
    description: `Redeem 50 Wilderness Agility Tickets`,
    skillReqs: [{ skill: 'Agility', level: 52 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  970: {
    id: '970',
    label: `Sacrifice Some Dragon Bones at the Chaos Temple`,
    description: `Sacrifice some Dragon Bones at the Western Chaos Temple altar`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1643: {
    id: '1643',
    label: `Use a teleport anchoring scroll`,
    description: `Use a teleport anchoring scroll`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.HARD,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1112: {
    id: '1112',
    label: `100 Combat Achievements`,
    description: `Complete 100 Combat Achievements`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1113: {
    id: '1113',
    label: `150 Combat Achievements`,
    description: `Complete 150 Combat Achievements`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1114: {
    id: '1114',
    label: `200 Combat Achievements`,
    description: `Complete 200 Combat Achievements`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1115: {
    id: '1115',
    label: `250 Combat Achievements`,
    description: `Complete 250 Combat Achievements`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1454: {
    id: '1454',
    label: `350 Collection log slots`,
    description: `Obtain 350 unique Collection Log slots`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  1111: {
    id: '1111',
    label: `50 Combat Achievements`,
    description: `Complete 50 Combat Achievements`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1455: {
    id: '1455',
    label: `500 Collection log slots`,
    description: `Obtain 500 unique Collection Log slots`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  166: {
    id: '166',
    label: `75 Master Clue Scrolls`,
    description: `Open 75 Reward caskets for completing master clue scrolls`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  201: {
    id: '201',
    label: `Achieve Your First Level 90`,
    description: `Reach level 90 in any skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  202: {
    id: '202',
    label: `Achieve Your First Level 95`,
    description: `Reach level 95 in any skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.MILESTONE,
    prerequisite: '',
  },
  127: {
    id: '127',
    label: `Activate an Imbued Heart`,
    description: `Obtain and activate an Imbued heart`,
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  411: {
    id: '411',
    label: `Add a Jar to a Display Case`,
    description: `Add a Jar to a Display Case in your Player Owned House Achievement Gallery`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1346: {
    id: '1346',
    label: `Build a Spirit tree & fairy ring`,
    description: `Build a Spirit tree & fairy ring in your Player Owned House`,
    skillReqs: [
      { skill: 'Construction', level: 95 },
      { skill: 'Farming', level: 83 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1616: {
    id: '1616',
    label: `Catch 500 Chinchompas`,
    description: `Catch 500 Chinchompas`,
    skillReqs: [{ skill: 'Hunter', level: 53 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  385: {
    id: '385',
    label: `Catch a Lucky Impling Bare-Handed`,
    description: `Catch a Lucky Impling bare-handed`,
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1121: {
    id: '1121',
    label: `Combat Achievements Hard Tier`,
    description: `Obtain enough points to unlock the hard tier of combat achievements`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.HARD,
    prerequisite: '',
  },
  1120: {
    id: '1120',
    label: `Combat Achievements Medium Tier`,
    description: `Obtain enough points to unlock the medium tier of combat achievements`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.MEDIUM,
    prerequisite: '',
  },
  1415: {
    id: '1415',
    label: `Complete 10 Speed Tasks`,
    description: `Complete 10 Combat Achievement Speed tasks`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1416: {
    id: '1416',
    label: `Complete 20 Speed Tasks`,
    description: `Complete 20 Combat Achievement Speed tasks`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  1417: {
    id: '1417',
    label: `Complete 30 Speed Tasks`,
    description: `Complete 30 Combat Achievement Speed tasks`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.GENERAL,
    prerequisite: '',
  },
  766: {
    id: '766',
    label: `Complete the Elite Western Provinces Diary`,
    description: `Complete all of the elite tasks in the Western Provinces Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin', 'Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  339: {
    id: '339',
    label: `Craft an Onyx Amulet`,
    description: `Craft an Onyx Amulet`,
    skillReqs: [{ skill: 'Crafting', level: 90 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  330: {
    id: '330',
    label: `Defeat 50 Superior Slayer Creatures`,
    description: `Defeat 50 Superior Slayer Creatures`,
    skillReqs: [{ skill: 'Slayer', level: 5 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1459: {
    id: '1459',
    label: `Equip a piece of Virtus`,
    description: `Equip either the Virtus Mask, Robe top, or Robe Bottoms`,
    skillReqs: [
      { skill: 'Magic', level: 78 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  416: {
    id: '416',
    label: `Equip an Abyssal Whip`,
    description: `Equip an Abyssal Whip`,
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Slayer', level: 85 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  126: {
    id: '126',
    label: `Equip an Eternal Slayer Ring`,
    description: `Craft and equip an Eternal slayer ring`,
    skillReqs: [
      { skill: 'Slayer', level: 5 },
      { skill: 'Crafting', level: 75 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  767: {
    id: '767',
    label: `Equip an Infernal Tool`,
    description: `Equip either an Infernal Axe, an Infernal Pickaxe or an Infernal Harpoon`,
    skillReqs: [
      { skill: 'Attack', level: 60 },
      { skill: 'Woodcutting', level: 61 },
      { skill: 'Fishing', level: 75 },
      { skill: 'Mining', level: 61 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  1260: {
    id: '1260',
    label: `Equip the Soulreaper Axe`,
    description: `Obtain and Equip the Soulreaper Axe`,
    skillReqs: [
      { skill: 'Attack', level: 80 },
      { skill: 'Strength', level: 80 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  10: {
    id: '10',
    label: `Fill 25 Master Clue Collection Log Slots`,
    description: `Fill 25 slots in the Master Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  37: {
    id: '37',
    label: `Fill Every Beginner Clue Collection Log Slot`,
    description: `Fill every slot in the Beginner Clue section of the Collection Log`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.BEGINNER,
    prerequisite: '',
  },
  1364: {
    id: '1364',
    label: `Fletch 100 Dragon Javelins`,
    description: `Fletch 100 Dragon Javelins`,
    skillReqs: [{ skill: 'Fletching', level: 92 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  44: {
    id: '44',
    label: `Gain 25 Unique Items From Elite Clues`,
    description: `Gain 25 unique items from Elite Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.ELITE,
    prerequisite: '',
  },
  42: {
    id: '42',
    label: `Gain 25 Unique Items From Master Clues`,
    description: `Gain 25 unique items from Master Clue Scroll Reward Caskets`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.MASTER,
    prerequisite: '',
  },
  1796: {
    id: '1796',
    label: `Kill 25 Echo Bosses`,
    description: `Kill 25 Echo Bosses`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  1797: {
    id: '1797',
    label: `Kill 75 Echo Bosses`,
    description: `Kill 75 Echo Bosses`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  399: {
    id: '399',
    label: `Make a Super Combat Potion`,
    description: `Make a Super Combat Potion`,
    skillReqs: [{ skill: 'Herblore', level: 90 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  245: {
    id: '245',
    label: `Obtain 25 Million Agility XP`,
    description: `Obtain 25 million experience in your Agility skill`,
    skillReqs: [{ skill: 'Agility', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  241: {
    id: '241',
    label: `Obtain 25 Million Construction XP`,
    description: `Obtain 25 million experience in your Construction skill`,
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  265: {
    id: '265',
    label: `Obtain 25 Million Cooking XP`,
    description: `Obtain 25 million experience in your Cooking skill`,
    skillReqs: [{ skill: 'Cooking', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  251: {
    id: '251',
    label: `Obtain 25 Million Crafting XP`,
    description: `Obtain 25 million experience in your Crafting skill`,
    skillReqs: [{ skill: 'Crafting', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  271: {
    id: '271',
    label: `Obtain 25 Million Farming XP`,
    description: `Obtain 25 million experience in your Farming skill`,
    skillReqs: [{ skill: 'Farming', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  267: {
    id: '267',
    label: `Obtain 25 Million Firemaking XP`,
    description: `Obtain 25 million experience in your Firemaking skill`,
    skillReqs: [{ skill: 'Firemaking', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  263: {
    id: '263',
    label: `Obtain 25 Million Fishing XP`,
    description: `Obtain 25 million experience in your Fishing skill`,
    skillReqs: [{ skill: 'Fishing', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  253: {
    id: '253',
    label: `Obtain 25 Million Fletching XP`,
    description: `Obtain 25 million experience in your Fletching skill`,
    skillReqs: [{ skill: 'Fletching', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  247: {
    id: '247',
    label: `Obtain 25 Million Herblore XP`,
    description: `Obtain 25 million experience in your Herblore skill`,
    skillReqs: [{ skill: 'Herblore', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  257: {
    id: '257',
    label: `Obtain 25 Million Hunter XP`,
    description: `Obtain 25 million experience in your Hunter skill`,
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  259: {
    id: '259',
    label: `Obtain 25 Million Mining XP`,
    description: `Obtain 25 million experience in your Mining skill`,
    skillReqs: [{ skill: 'Mining', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  235: {
    id: '235',
    label: `Obtain 25 Million Prayer XP`,
    description: `Obtain 25 million experience in your Prayer skill`,
    skillReqs: [{ skill: 'Prayer', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  239: {
    id: '239',
    label: `Obtain 25 Million Runecraft XP`,
    description: `Obtain 25 million experience in your Runecraft skill`,
    skillReqs: [{ skill: 'Runecraft', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  255: {
    id: '255',
    label: `Obtain 25 Million Slayer XP`,
    description: `Obtain 25 million experience in your Slayer skill`,
    skillReqs: [{ skill: 'Slayer', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  261: {
    id: '261',
    label: `Obtain 25 Million Smithing XP`,
    description: `Obtain 25 million experience in your Smithing skill`,
    skillReqs: [{ skill: 'Smithing', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  249: {
    id: '249',
    label: `Obtain 25 Million Thieving XP`,
    description: `Obtain 25 million experience in your Thieving skill`,
    skillReqs: [{ skill: 'Thieving', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  269: {
    id: '269',
    label: `Obtain 25 Million Woodcutting XP`,
    description: `Obtain 25 million experience in your Woodcutting skill`,
    skillReqs: [{ skill: 'Woodcutting', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1469: {
    id: '1469',
    label: `Obtain 35 Million Agility XP`,
    description: `Obtain 35 million experience in your Agility skill`,
    skillReqs: [{ skill: 'Agility', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1467: {
    id: '1467',
    label: `Obtain 35 Million Construction XP`,
    description: `Obtain 35 million experience in your Construction skill`,
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1479: {
    id: '1479',
    label: `Obtain 35 Million Cooking XP`,
    description: `Obtain 35 million experience in your Cooking skill`,
    skillReqs: [{ skill: 'Cooking', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1472: {
    id: '1472',
    label: `Obtain 35 Million Crafting XP`,
    description: `Obtain 35 million experience in your Crafting skill`,
    skillReqs: [{ skill: 'Crafting', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1482: {
    id: '1482',
    label: `Obtain 35 Million Farming XP`,
    description: `Obtain 35 million experience in your Farming skill`,
    skillReqs: [{ skill: 'Farming', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1480: {
    id: '1480',
    label: `Obtain 35 Million Firemaking XP`,
    description: `Obtain 35 million experience in your Firemaking skill`,
    skillReqs: [{ skill: 'Firemaking', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  1478: {
    id: '1478',
    label: `Obtain 35 Million Fishing XP`,
    description: `Obtain 35 million experience in your Fishing skill`,
    skillReqs: [{ skill: 'Fishing', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1473: {
    id: '1473',
    label: `Obtain 35 Million Fletching XP`,
    description: `Obtain 35 million experience in your Fletching skill`,
    skillReqs: [{ skill: 'Fletching', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1470: {
    id: '1470',
    label: `Obtain 35 Million Herblore XP`,
    description: `Obtain 35 million experience in your Herblore skill`,
    skillReqs: [{ skill: 'Herblore', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1475: {
    id: '1475',
    label: `Obtain 35 Million Hunter XP`,
    description: `Obtain 35 million experience in your Hunter skill`,
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1476: {
    id: '1476',
    label: `Obtain 35 Million Mining XP`,
    description: `Obtain 35 million experience in your Mining skill`,
    skillReqs: [{ skill: 'Mining', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1464: {
    id: '1464',
    label: `Obtain 35 Million Prayer XP`,
    description: `Obtain 35 million experience in your Prayer skill`,
    skillReqs: [{ skill: 'Prayer', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1466: {
    id: '1466',
    label: `Obtain 35 Million Runecraft XP`,
    description: `Obtain 35 million experience in your Runecraft skill`,
    skillReqs: [{ skill: 'Runecraft', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1474: {
    id: '1474',
    label: `Obtain 35 Million Slayer XP`,
    description: `Obtain 35 million experience in your Slayer skill`,
    skillReqs: [{ skill: 'Slayer', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1477: {
    id: '1477',
    label: `Obtain 35 Million Smithing XP`,
    description: `Obtain 35 million experience in your Smithing skill`,
    skillReqs: [{ skill: 'Smithing', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1471: {
    id: '1471',
    label: `Obtain 35 Million Thieving XP`,
    description: `Obtain 35 million experience in your Thieving skill`,
    skillReqs: [{ skill: 'Thieving', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1481: {
    id: '1481',
    label: `Obtain 35 Million Woodcutting XP`,
    description: `Obtain 35 million experience in your Woodcutting skill`,
    skillReqs: [{ skill: 'Woodcutting', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1492: {
    id: '1492',
    label: `Obtain 50 Million Agility XP`,
    description: `Obtain 50 million experience in your Agility skill`,
    skillReqs: [{ skill: 'Agility', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1483: {
    id: '1483',
    label: `Obtain 50 Million Attack XP`,
    description: `Obtain 50 million experience in your Attack skill`,
    skillReqs: [{ skill: 'Attack', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  1490: {
    id: '1490',
    label: `Obtain 50 Million Construction XP`,
    description: `Obtain 50 million experience in your Construction skill`,
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1502: {
    id: '1502',
    label: `Obtain 50 Million Cooking XP`,
    description: `Obtain 50 million experience in your Cooking skill`,
    skillReqs: [{ skill: 'Cooking', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1495: {
    id: '1495',
    label: `Obtain 50 Million Crafting XP`,
    description: `Obtain 50 million experience in your Crafting skill`,
    skillReqs: [{ skill: 'Crafting', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  1485: {
    id: '1485',
    label: `Obtain 50 Million Defence XP`,
    description: `Obtain 50 million experience in your Defence skill`,
    skillReqs: [{ skill: 'Defence', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  1505: {
    id: '1505',
    label: `Obtain 50 Million Farming XP`,
    description: `Obtain 50 million experience in your Farming skill`,
    skillReqs: [{ skill: 'Farming', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1503: {
    id: '1503',
    label: `Obtain 50 Million Firemaking XP`,
    description: `Obtain 50 million experience in your Firemaking skill`,
    skillReqs: [{ skill: 'Firemaking', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  1501: {
    id: '1501',
    label: `Obtain 50 Million Fishing XP`,
    description: `Obtain 50 million experience in your Fishing skill`,
    skillReqs: [{ skill: 'Fishing', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  1496: {
    id: '1496',
    label: `Obtain 50 Million Fletching XP`,
    description: `Obtain 50 million experience in your Fletching skill`,
    skillReqs: [{ skill: 'Fletching', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1493: {
    id: '1493',
    label: `Obtain 50 Million Herblore XP`,
    description: `Obtain 50 million experience in your Herblore skill`,
    skillReqs: [{ skill: 'Herblore', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1491: {
    id: '1491',
    label: `Obtain 50 Million Hitpoints XP`,
    description: `Obtain 50 million experience in your Hitpoints skill`,
    skillReqs: [{ skill: 'Hitpoints', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.HITPOINTS,
    prerequisite: '',
  },
  1498: {
    id: '1498',
    label: `Obtain 50 Million Hunter XP`,
    description: `Obtain 50 million experience in your Hunter skill`,
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1488: {
    id: '1488',
    label: `Obtain 50 Million Magic XP`,
    description: `Obtain 50 million experience in your Magic skill`,
    skillReqs: [{ skill: 'Magic', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1499: {
    id: '1499',
    label: `Obtain 50 Million Mining XP`,
    description: `Obtain 50 million experience in your Mining skill`,
    skillReqs: [{ skill: 'Mining', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1487: {
    id: '1487',
    label: `Obtain 50 Million Prayer XP`,
    description: `Obtain 50 million experience in your Prayer skill`,
    skillReqs: [{ skill: 'Prayer', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  1486: {
    id: '1486',
    label: `Obtain 50 Million Ranged XP`,
    description: `Obtain 50 million experience in your Ranged skill`,
    skillReqs: [{ skill: 'Ranged', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  1489: {
    id: '1489',
    label: `Obtain 50 Million Runecraft XP`,
    description: `Obtain 50 million experience in your Runecraft skill`,
    skillReqs: [{ skill: 'Runecraft', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1497: {
    id: '1497',
    label: `Obtain 50 Million Slayer XP`,
    description: `Obtain 50 million experience in your Slayer skill`,
    skillReqs: [{ skill: 'Slayer', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1500: {
    id: '1500',
    label: `Obtain 50 Million Smithing XP`,
    description: `Obtain 50 million experience in your Smithing skill`,
    skillReqs: [{ skill: 'Smithing', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1484: {
    id: '1484',
    label: `Obtain 50 Million Strength XP`,
    description: `Obtain 50 million experience in your Strength skill`,
    skillReqs: [{ skill: 'Strength', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.STRENGTH,
    prerequisite: '',
  },
  1494: {
    id: '1494',
    label: `Obtain 50 Million Thieving XP`,
    description: `Obtain 50 million experience in your Thieving skill`,
    skillReqs: [{ skill: 'Thieving', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1504: {
    id: '1504',
    label: `Obtain 50 Million Woodcutting XP`,
    description: `Obtain 50 million experience in your Woodcutting skill`,
    skillReqs: [{ skill: 'Woodcutting', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  400: {
    id: '400',
    label: `Obtain a Boss Pet`,
    description: `Obtain any Boss Pet`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GENERAL,
    prerequisite: '',
  },
  272: {
    id: '272',
    label: `Obtain a Skilling Pet`,
    description: `Obtain any Skilling Pet`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.GENERAL,
    prerequisite: '',
  },
  223: {
    id: '223',
    label: `Reach Base Level 80`,
    description: `Reach level 80 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  224: {
    id: '224',
    label: `Reach Base Level 90`,
    description: `Reach level 90 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  407: {
    id: '407',
    label: `Reach Combat Level 120`,
    description: `Reach Combat Level 120`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '',
  },
  408: {
    id: '408',
    label: `Reach Combat Level 126`,
    description: `Reach Combat Level 126`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.LEVEL,
    prerequisite: '',
  },
  244: {
    id: '244',
    label: `Reach Level 99 Agility`,
    description: `Reach level 99 in your Agility skill`,
    skillReqs: [{ skill: 'Agility', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  226: {
    id: '226',
    label: `Reach Level 99 Attack`,
    description: `Reach level 99 in your Attack skill`,
    skillReqs: [{ skill: 'Attack', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  240: {
    id: '240',
    label: `Reach Level 99 Construction`,
    description: `Reach level 99 in your Construction skill`,
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  264: {
    id: '264',
    label: `Reach Level 99 Cooking`,
    description: `Reach level 99 in your Cooking skill`,
    skillReqs: [{ skill: 'Cooking', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  250: {
    id: '250',
    label: `Reach Level 99 Crafting`,
    description: `Reach level 99 in your Crafting skill`,
    skillReqs: [{ skill: 'Crafting', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CRAFTING,
    prerequisite: '',
  },
  230: {
    id: '230',
    label: `Reach Level 99 Defence`,
    description: `Reach level 99 in your Defence skill`,
    skillReqs: [{ skill: 'Defence', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  270: {
    id: '270',
    label: `Reach Level 99 Farming`,
    description: `Reach level 99 in your Farming skill`,
    skillReqs: [{ skill: 'Farming', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  266: {
    id: '266',
    label: `Reach Level 99 Firemaking`,
    description: `Reach level 99 in your Firemaking skill`,
    skillReqs: [{ skill: 'Firemaking', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FIREMAKING,
    prerequisite: '',
  },
  262: {
    id: '262',
    label: `Reach Level 99 Fishing`,
    description: `Reach level 99 in your Fishing skill`,
    skillReqs: [{ skill: 'Fishing', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  252: {
    id: '252',
    label: `Reach Level 99 Fletching`,
    description: `Reach level 99 in your Fletching skill`,
    skillReqs: [{ skill: 'Fletching', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  246: {
    id: '246',
    label: `Reach Level 99 Herblore`,
    description: `Reach level 99 in your Herblore skill`,
    skillReqs: [{ skill: 'Herblore', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  242: {
    id: '242',
    label: `Reach Level 99 Hitpoints`,
    description: `Reach level 99 in your Hitpoints skill`,
    skillReqs: [{ skill: 'Hitpoints', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.HITPOINTS,
    prerequisite: '',
  },
  256: {
    id: '256',
    label: `Reach Level 99 Hunter`,
    description: `Reach level 99 in your Hunter skill`,
    skillReqs: [{ skill: 'Hunter', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  236: {
    id: '236',
    label: `Reach Level 99 Magic`,
    description: `Reach level 99 in your Magic skill`,
    skillReqs: [{ skill: 'Magic', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  258: {
    id: '258',
    label: `Reach Level 99 Mining`,
    description: `Reach level 99 in your Mining skill`,
    skillReqs: [{ skill: 'Mining', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  234: {
    id: '234',
    label: `Reach Level 99 Prayer`,
    description: `Reach level 99 in your Prayer skill`,
    skillReqs: [{ skill: 'Prayer', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  232: {
    id: '232',
    label: `Reach Level 99 Ranged`,
    description: `Reach level 99 in your Ranged skill`,
    skillReqs: [{ skill: 'Ranged', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  238: {
    id: '238',
    label: `Reach Level 99 Runecraft`,
    description: `Reach level 99 in your Runecraft skill`,
    skillReqs: [{ skill: 'Runecraft', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  254: {
    id: '254',
    label: `Reach Level 99 Slayer`,
    description: `Reach level 99 in your Slayer skill`,
    skillReqs: [{ skill: 'Slayer', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  260: {
    id: '260',
    label: `Reach Level 99 Smithing`,
    description: `Reach level 99 in your Smithing skill`,
    skillReqs: [{ skill: 'Smithing', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  228: {
    id: '228',
    label: `Reach Level 99 Strength`,
    description: `Reach level 99 in your Strength skill`,
    skillReqs: [{ skill: 'Strength', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.STRENGTH,
    prerequisite: '',
  },
  248: {
    id: '248',
    label: `Reach Level 99 Thieving`,
    description: `Reach level 99 in your Thieving skill`,
    skillReqs: [{ skill: 'Thieving', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  268: {
    id: '268',
    label: `Reach Level 99 Woodcutting`,
    description: `Reach level 99 in your Woodcutting skill`,
    skillReqs: [{ skill: 'Woodcutting', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  211: {
    id: '211',
    label: `Reach Total Level 2000`,
    description: `Reach a Total Level of 2000`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  212: {
    id: '212',
    label: `Reach Total Level 2100`,
    description: `Reach a Total Level of 2100`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  213: {
    id: '213',
    label: `Reach Total Level 2200`,
    description: `Reach a Total Level of 2200`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  328: {
    id: '328',
    label: `Slay an Abyssal Demon`,
    description: `Kill an Abyssal Demon whilst on an Abyssal Demon Slayer Task`,
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  355: {
    id: '355',
    label: `Smith a Dragonfire Shield`,
    description: `Use an Anvil to smith a Dragonfire Shield`,
    skillReqs: [{ skill: 'Smithing', level: 90 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  818: {
    id: '818',
    label: `Complete the Elite Falador Diary`,
    description: `Complete all of the elite tasks in the Falador Achievement Diary`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  811: {
    id: '811',
    label: `Defeat Any God Wars Dungeon Boss 500 Times`,
    description: `Defeat any one of the God Wars Dungeon bosses 500 times`,
    skillReqs: [
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1175: {
    id: '1175',
    label: `Defeat Awakened Whisperer`,
    description: `Defeat Awakened Whisperer`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1394: {
    id: '1394',
    label: `Defeat Cerberus 300 times`,
    description: `Defeat Cerberus 300 times`,
    skillReqs: [{ skill: 'Slayer', level: 91 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  1159: {
    id: '1159',
    label: `Defeat Nex 300 Times`,
    description: `Defeat Nex in the God Wars Dungeon 300 times`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1174: {
    id: '1174',
    label: `Defeat Whisperer 300 times`,
    description: `Defeat Whisperer 300 times`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  791: {
    id: '791',
    label: `Equip a Full Armadyl Armour Set`,
    description: `Equip a full set of Armadyl Armour`,
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  796: {
    id: '796',
    label: `Equip a Full Bandos Armour Set`,
    description: `Equip a full set of Bandos Armour`,
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  769: {
    id: '769',
    label: `Equip a Full Graceful Outfit`,
    description: `Equip a full set of Graceful gear`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1140: {
    id: '1140',
    label: `Equip a Full set of Golden Prospector`,
    description: `Equip a Golden prospector helmet, a Golden prospector jacket, some Golden prospector legs and Golden prospector boots`,
    skillReqs: [{ skill: 'Mining', level: 30 }],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1253: {
    id: '1253',
    label: `Equip a full set of the Carpenters Outfit`,
    description: `Equip a full set of the Carpenters Outfit`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  802: {
    id: '802',
    label: `Equip a Godsword`,
    description: `Equip any Godsword`,
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  790: {
    id: '790',
    label: `Equip a Piece of the Armadyl Armour Set`,
    description: `Equip any piece of the Armadyl Armour set`,
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  795: {
    id: '795',
    label: `Equip a Piece of the Bandos Armour Set`,
    description: `Equip any piece of the Bandos Armour set`,
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1160: {
    id: '1160',
    label: `Equip a Piece of Torva Armour`,
    description: `Equip either a Torva full helm, a Torva platebody or some Torva platelegs`,
    skillReqs: [
      { skill: 'Defence', level: 80 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NEX,
    prerequisite: '',
  },
  784: {
    id: '784',
    label: `Equip a Staff of the Dead`,
    description: `Equip a Staff of the Dead`,
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Magic', level: 75 },
      { skill: 'Hitpoints', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  789: {
    id: '789',
    label: `Equip a Zamorakian Spear`,
    description: `Equip a Zamorakian Spear`,
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Hitpoints', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1163: {
    id: '1163',
    label: `Equip a Zaryte Crossbow`,
    description: `Obtain and equip a Zaryte Crossbow`,
    skillReqs: [
      { skill: 'Ranged', level: 80 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NEX,
    prerequisite: '',
  },
  798: {
    id: '798',
    label: `Equip an Armadyl Crossbow`,
    description: `Equip an Armadyl Crossbow`,
    skillReqs: [
      { skill: 'Ranged', level: 70 },
      { skill: 'Agility', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  799: {
    id: '799',
    label: `Equip Some Primordial, Pegasian or Eternal Boots`,
    description: `Equip either some Primordial Boots, some Pegasian Boots or some Eternal Boots`,
    skillReqs: [
      { skill: 'Slayer', level: 91 },
      { skill: 'Defence', level: 75 },
      { skill: 'Magic', level: 60 },
      { skill: 'Runecraft', level: 60 },
      { skill: 'Attack', level: 75 },
      { skill: 'Ranged', level: 75 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CERBERUS,
    prerequisite: '',
  },
  1223: {
    id: '1223',
    label: `Equip the Bellator Ring`,
    description: `Obtain and Equip the Bellator Ring`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1420: {
    id: '1420',
    label: `The Whisperer Combat Achievements`,
    description: `Complete all of the Combat Achievements for The Whisperer`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  872: {
    id: '872',
    label: `Cast Ice Barrage`,
    description: `Cast the Ice Barrage spell`,
    skillReqs: [{ skill: 'Magic', level: 94 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  880: {
    id: '880',
    label: `Complete the Elite Desert Diary`,
    description: `Complete all of the elite tasks in the Desert Achievement Diary`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1189: {
    id: '1189',
    label: `Complete Tombs of Amascut 100 times`,
    description: `Complete Tombs of Amascut on normal or Expert 100 times`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1188: {
    id: '1188',
    label: `Complete Tombs of Amascut 50 times`,
    description: `Complete Tombs of Amascut on normal or Expert 50 times`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1042: {
    id: '1042',
    label: `Craft a Ghorrock Teleport Tablet`,
    description: `Craft a Ghorrock Teleport Tablet`,
    skillReqs: [{ skill: 'Magic', level: 96 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1180: {
    id: '1180',
    label: `Defeat Awakened Leviathan`,
    description: `Defeat Awakened Leviathan`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1179: {
    id: '1179',
    label: `Defeat Leviathan 300 times`,
    description: `Defeat Leviathan 300 times`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  867: {
    id: '867',
    label: `Equip a Dragon Chainbody in the Kharidian Desert`,
    description: `Equip a Dragon Chainbody in the Kharidian Desert`,
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KQ,
    prerequisite: '',
  },
  1242: {
    id: '1242',
    label: `Equip a full set of Raiment of the eye`,
    description: `Equip a full set of the Raiment of the Eye outfit, purchased from the Guardians of the Rift minigame`,
    skillReqs: [{ skill: 'Runecraft', level: 27 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1230: {
    id: '1230',
    label: `Equip a Piece of Masori Armour`,
    description: `Equip either a Masori Mask, a Masori Body or some Masori chaps`,
    skillReqs: [
      { skill: 'Ranged', level: 80 },
      { skill: 'Defence', level: 30 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  883: {
    id: '883',
    label: `Equip a Piece of the Infinity Robe Set`,
    description: `Equip any piece of the Infinity robe set`,
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Defence', level: 25 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1232: {
    id: '1232',
    label: `Equip Osmumten's Fang`,
    description: `Obtain and Equip Osmumten's Fang`,
    skillReqs: [{ skill: 'Attack', level: 82 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1228: {
    id: '1228',
    label: `Equip the Elidinis Ward`,
    description: `Obtain and Equip the Elidinis Ward`,
    skillReqs: [
      { skill: 'Magic', level: 80 },
      { skill: 'Defence', level: 80 },
      { skill: 'Prayer', level: 80 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1222: {
    id: '1222',
    label: `Equip the Venator Ring`,
    description: `Obtain and Equip the Venator Ring`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1365: {
    id: '1365',
    label: `Fletch 200 Dragon Darts`,
    description: `Fletch 200 Dragon Darts`,
    skillReqs: [{ skill: 'Fletching', level: 95 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FLETCHING,
    prerequisite: '',
  },
  1221: {
    id: '1221',
    label: `Hit 150 with the Keris Partisan`,
    description: `Hit 150 damage or more with the Keris Partisan`,
    skillReqs: [{ skill: 'Attack', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1051: {
    id: '1051',
    label: `Smith 1,000 Rune Dart Tips`,
    description: `Use an Anvil to smith 1,000 Rune Dart Tips`,
    skillReqs: [{ skill: 'Smithing', level: 89 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1423: {
    id: '1423',
    label: `The Leviathan Combat Achievements`,
    description: `Complete all of the Combat Achievements for The Leviathan`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  849: {
    id: '849',
    label: `Turn in 50 Summer Sq'irkjuices to Osman`,
    description: `Turn in 50 Summer Sq'irkjuices to Osman in Al Kharid`,
    skillReqs: [{ skill: 'Thieving', level: 65 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.THIEVING,
    prerequisite: '',
  },
  1805: {
    id: '1805',
    label: `Blast Furnace 100 Runite Bars`,
    description: `Smelt 100 Runite Bars at the Blast Furnace`,
    skillReqs: [{ skill: 'Smithing', level: 85 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  626: {
    id: '626',
    label: `Complete the Elite Fremennik Diary`,
    description: `Complete all of the elite tasks in the Fremennik Achievement Diary`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1429: {
    id: '1429',
    label: `Dagannoth Kings Combat Achievements`,
    description: `Complete all of the Combat Achievements for all three Dagannoth Kings`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  1185: {
    id: '1185',
    label: `Defeat Awakened Duke Sucellus`,
    description: `Defeat Awakened Duke Sucellus`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1184: {
    id: '1184',
    label: `Defeat Duke Sucellus 300 times`,
    description: `Defeat Duke Sucellus 300 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  615: {
    id: '615',
    label: `Defeat Each Dagannoth King 300 Times`,
    description: `Defeat all three of the Dagannoth Kings in the Waterbirth Island Dungeon 300 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  1196: {
    id: '1196',
    label: `Defeat Phantom Muspah 300 times`,
    description: `Defeat Phantom Muspah 300 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  605: {
    id: '605',
    label: `Defeat Vorkath 15 Times Without Leaving`,
    description: `Defeat Vorkath on Ungael 15 times without leaving his area`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  610: {
    id: '610',
    label: `Defeat Vorkath 300 Times`,
    description: `Defeat Vorkath on Ungael 300 times`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  602: {
    id: '602',
    label: `Defeat Vorkath 5 Times Without Special Damage`,
    description: `Defeat Vorkath on Ungael 5 times in a row without taking any damage from his special attacks and without leaving his area. Failing this task requires you to leave the instance to try again`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  1422: {
    id: '1422',
    label: `Duke Sucellus Combat Achievements`,
    description: `Complete all of the Combat Achievements for Duke Sucellus`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  630: {
    id: '630',
    label: `Equip a Completed God Book`,
    description: `Equip a completed God Book`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  469: {
    id: '469',
    label: `Equip a Dragon Crossbow`,
    description: `Equip a Dragon Crossbow`,
    skillReqs: [
      { skill: 'Ranged', level: 64 },
      { skill: 'Fletching', level: 78 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  470: {
    id: '470',
    label: `Equip a Dragon Platebody or Dragon Kiteshield`,
    description: `Equip either a Dragon Platebody or a Dragon Kiteshield`,
    skillReqs: [
      { skill: 'Defence', level: 60 },
      { skill: 'Smithing', level: 75 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  591: {
    id: '591',
    label: `Equip a Dragonbone Necklace`,
    description: `Equip a Dragonbone Necklace`,
    skillReqs: [{ skill: 'Prayer', level: 70 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  586: {
    id: '586',
    label: `Equip a Dragonfire Ward`,
    description: `Equip a Dragonfire Ward`,
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Ranged', level: 70 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  600: {
    id: '600',
    label: `Equip a Neitiznot Faceguard`,
    description: `Equip a Neitiznot Faceguard`,
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Slayer', level: 60 },
    ],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  597: {
    id: '597',
    label: `Equip Every Dagannoth King Ring`,
    description: `Equip an Archer's Ring, a Berserker Ring, a Seer's Ring and a Warrior Ring`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DKS,
    prerequisite: '',
  },
  1225: {
    id: '1225',
    label: `Equip the Magus Ring`,
    description: `Obtain and Equip the Magus Ring`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1244: {
    id: '1244',
    label: `Equip the Venator Bow`,
    description: `Obtain and Equip the Venator Bow`,
    skillReqs: [{ skill: 'Ranged', level: 80 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  1339: {
    id: '1339',
    label: `Make 50 Forgotten Brews`,
    description: `Make 50 Forgotten Brews`,
    skillReqs: [{ skill: 'Herblore', level: 91 }],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1431: {
    id: '1431',
    label: `Phantom Muspah Combat Achievements`,
    description: `Complete all of the Combat Achievements for Phantom Muspah`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  1430: {
    id: '1430',
    label: `Vorkath Combat Achievements`,
    description: `Complete all of the Combat Achievements for Vorkath`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  1816: {
    id: '1816',
    label: `Barehand catch a Shark`,
    description: `Barehand catch a Shark`,
    skillReqs: [
      { skill: 'Fishing', level: 96 },
      { skill: 'Strength', level: 76 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  692: {
    id: '692',
    label: `Catch 150 Red Chinchompas in Kandarin`,
    description: `Catch 150 Red Chinchompas in Kandarin`,
    skillReqs: [{ skill: 'Hunter', level: 63 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  703: {
    id: '703',
    label: `Check a grown Dragonfruit Tree in Catherby`,
    description: `Check the health of a Dragonfruit Tree you've grown in Catherby`,
    skillReqs: [{ skill: 'Farming', level: 81 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  689: {
    id: '689',
    label: `Complete the Ardougne Agility Course`,
    description: `Complete a lap of the Ardougne Rooftop Agility Course`,
    skillReqs: [{ skill: 'Agility', level: 90 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1056: {
    id: '1056',
    label: `Complete the Elite Ardougne Diary`,
    description: `Complete all of the elite tasks in the Ardougne Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  762: {
    id: '762',
    label: `Complete the Elite Kandarin Diary`,
    description: `Complete all of the elite tasks in the Kandarin Achievement Diary`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  732: {
    id: '732',
    label: `Defeat 300 Demonic Gorillas`,
    description: `Defeat 300 Demonic Gorillas in the Crash Site Cavern`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  733: {
    id: '733',
    label: `Defeat 500 Demonic Gorillas`,
    description: `Defeat 500 Demonic Gorillas in the Crash Site Cavern`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  730: {
    id: '730',
    label: `Defeat the Kraken Boss 300 Times`,
    description: `Defeat the Kraken boss in Kraken Cove 300 times`,
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '',
  },
  715: {
    id: '715',
    label: `Equip a Fighter Torso`,
    description: `Equip a Fighter Torso`,
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.BA,
    prerequisite: '',
  },
  712: {
    id: '712',
    label: `Equip a Heavy Ballista`,
    description: `Equip a Heavy Ballista`,
    skillReqs: [
      { skill: 'Ranged', level: 75 },
      { skill: 'Fletching', level: 72 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1438: {
    id: '1438',
    label: `Equip a Light Ballista`,
    description: `Equip a Light Ballista`,
    skillReqs: [{ skill: 'Ranged', level: 65 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  756: {
    id: '756',
    label: `Equip a Maniacal Monkey Backpack`,
    description: `Equip a Maniacal Monkey Backpack`,
    skillReqs: [{ skill: 'Agility', level: 48 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  716: {
    id: '716',
    label: `Equip an Abyssal Tentacle`,
    description: `Equip an Abyssal Tentacle`,
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Attack', level: 75 },
      { skill: 'Slayer', level: 87 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '',
  },
  723: {
    id: '723',
    label: `Equip an Occult Necklace`,
    description: `Equip an Occult Necklace`,
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Slayer', level: 93 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  710: {
    id: '710',
    label: `Equip Some Zenyte Jewelry`,
    description: `Equip any piece of Zenyte Jewelry`,
    skillReqs: [{ skill: 'Crafting', level: 89 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1537: {
    id: '1537',
    label: `Get 1000 Target points`,
    description: `Achieve 1000 points in the Target minigame in the Ranging Guild`,
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.RANGED,
    prerequisite: '',
  },
  739: {
    id: '739',
    label: `Reach Level 5 in Any Barbarian Assault Role`,
    description: `Reach level 5 in any Barbarian Assault Role`,
    skillReqs: [],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.BA,
    prerequisite: '',
  },
  288: {
    id: '288',
    label: `Travel Between Your Spirit Trees`,
    description: `Travel between any two Spirit Trees that you've grown`,
    skillReqs: [{ skill: 'Farming', level: 91 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  727: {
    id: '727',
    label: `Use the Piety Prayer`,
    description: `Use the Piety Prayer`,
    skillReqs: [
      { skill: 'Prayer', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.PRAYER,
    prerequisite: '',
  },
  674: {
    id: '674',
    label: `Complete the Elite Karamja Diary`,
    description: `Complete all of the elite tasks in the Karamja Achievement Diary`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  665: {
    id: '665',
    label: `Complete the Fight Caves 10 Times`,
    description: `Complete the TzHaar Fight Cave in Mor Ul Rek 10 times`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  664: {
    id: '664',
    label: `Complete the Fight Caves 5 Times`,
    description: `Complete the TzHaar Fight Cave in Mor Ul Rek 5 times`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  668: {
    id: '668',
    label: `Complete the Inferno 10 Times`,
    description: `Complete the Inferno in Mor Ul Rek 10 times`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '',
  },
  667: {
    id: '667',
    label: `Complete the Inferno 5 Times`,
    description: `Complete the Inferno in Mor Ul Rek 5 times`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '',
  },
  1201: {
    id: '1201',
    label: `Complete Tzhaar-Ket-Rak's fifth challenge`,
    description: `Complete Tzhaar-Ket-Rak's fifth challenge`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  1200: {
    id: '1200',
    label: `Complete Tzhaar-Ket-Rak's fourth challenge`,
    description: `Complete Tzhaar-Ket-Rak's fourth challenge`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  1202: {
    id: '1202',
    label: `Complete Tzhaar-Ket-Rak's sixth challenge`,
    description: `Complete Tzhaar-Ket-Rak's sixth challenge`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  653: {
    id: '653',
    label: `Equip a Fire Cape`,
    description: `Equip a Fire Cape`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  1575: {
    id: '1575',
    label: `Pay Saniboch for Permanent Access`,
    description: `Pay Saniboch for Permanent Access into his dungeon`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  678: {
    id: '678',
    label: `Purchase an Onyx in Mor Ul Rek`,
    description: `Purchase an Onyx from an Ore and Gem Store in Mor Ul Rek`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  661: {
    id: '661',
    label: `Sacrifice a Fire Cape to Access the Inferno`,
    description: `Sacrifice a Fire Cape to access the Inferno in Mor Ul Rek`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  117: {
    id: '117',
    label: `100 Chambers of Xeric`,
    description: `Complete the Chambers of Xeric Normal or Challenge Mode 100 times`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  113: {
    id: '113',
    label: `300 Alchemical Hydra Kills`,
    description: `Kill the Alchemical Hydra in Mount Karuulm 300 times`,
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  1436: {
    id: '1436',
    label: `Alchemical Hydra Combat Achievements`,
    description: `Complete all of the Combat Achievements for Alchemical Hydra`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  1609: {
    id: '1609',
    label: `Catch 150 Anglerfish`,
    description: `Catch 150 Anglerfish`,
    skillReqs: [{ skill: 'Fishing', level: 78 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  93: {
    id: '93',
    label: `Check a grown Redwood Tree`,
    description: `Check the health of a Redwood Tree after growing one in the Farming Guild`,
    skillReqs: [{ skill: 'Farming', level: 90 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  1376: {
    id: '1376',
    label: `Chop 500 Redwood logs`,
    description: `Chop 500 Redwood logs from a Redwood Tree`,
    skillReqs: [{ skill: 'Woodcutting', level: 90 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.WOODCUTTING,
    prerequisite: '',
  },
  1349: {
    id: '1349',
    label: `Cook 100 Anglerfish`,
    description: `Cook 100 Anglerfish`,
    skillReqs: [{ skill: 'Cooking', level: 84 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1602: {
    id: '1602',
    label: `Craft 100 Soul runes`,
    description: `Craft 100 Soul runes`,
    skillReqs: [
      { skill: 'Runecraft', level: 90 },
      { skill: 'Mining', level: 38 },
      { skill: 'Crafting', level: 38 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.RUNECRAFT,
    prerequisite: '',
  },
  1069: {
    id: '1069',
    label: `Equip a Bonecrusher necklace`,
    description: `Equip a Bonecrusher necklace`,
    skillReqs: [
      { skill: 'Slayer', level: 95 },
      { skill: 'Prayer', level: 70 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.VORKATH,
    prerequisite: '',
  },
  1060: {
    id: '1060',
    label: `Equip a Dragon Hunter Crossbow`,
    description: `Equip a Dragon Hunter Crossbow`,
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  129: {
    id: '129',
    label: `Equip a Dragon Hunter Lance`,
    description: `Obtain and equip a Dragon hunter lance`,
    skillReqs: [
      { skill: 'Slayer', level: 95 },
      { skill: 'Attack', level: 78 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.HYDRA,
    prerequisite: '',
  },
  121: {
    id: '121',
    label: `Equip a Dragon Warhammer`,
    description: `Obtain and equip a Dragon warhammer`,
    skillReqs: [{ skill: 'Strength', level: 60 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.ATTACK,
    prerequisite: '',
  },
  187: {
    id: '187',
    label: `Equip a Golden Tench`,
    description: `Equip a Golden tench, caught by aerial fishing on Molch Island`,
    skillReqs: [
      { skill: 'Hunter', level: 35 },
      { skill: 'Fishing', level: 43 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  133: {
    id: '133',
    label: `Equip a Kodai Wand`,
    description: `Obtain and equip a Kodai wand`,
    skillReqs: [{ skill: 'Magic', level: 80 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1066: {
    id: '1066',
    label: `Equip a pair of Dragon Claws`,
    description: `Equip a pair of Dragon Claws`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1061: {
    id: '1061',
    label: `Equip a Twisted Buckler`,
    description: `Equip a Twisted Buckler`,
    skillReqs: [
      { skill: 'Ranged', level: 75 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  132: {
    id: '132',
    label: `Equip an Elder Maul`,
    description: `Obtain and equip an Elder maul`,
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Strength', level: 75 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1064: {
    id: '1064',
    label: `Equip any Ancestral piece`,
    description: `Equip either an Ancestral Hat, an Ancestral robe top or an Ancestral robe bottom`,
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1063: {
    id: '1063',
    label: `Equip Dinh's Bulwark`,
    description: `Equip Dinh's Bulwark`,
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  138: {
    id: '138',
    label: `Kourend and Kebos Elite Diary Tasks`,
    description: `Complete all tasks in the elite tier of the Kourend and Kebos achievement diary`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1067: {
    id: '1067',
    label: `Recolour a piece of the Ancestral Robes`,
    description: `Recolour a piece of the Ancestral robes using a Twisted ancestral colour kit`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1637: {
    id: '1637',
    label: `Smith Shayzien (5)`,
    description: `Smith any piece of Shayzien (5)`,
    skillReqs: [
      { skill: 'Smithing', level: 85 },
      { skill: 'Mining', level: 65 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1435: {
    id: '1435',
    label: `Abyssal Sire Combat Achievements`,
    description: `Complete all of the Combat Achievements for Abyssal Sire`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  444: {
    id: '444',
    label: `Catch a Herbiboar 150 Times`,
    description: `Catch a Herbiboar on Fossil Island 150 times`,
    skillReqs: [
      { skill: 'Hunter', level: 80 },
      { skill: 'Herblore', level: 31 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  491: {
    id: '491',
    label: `Complete the Elite Lumbridge & Draynor Diary`,
    description: `Complete all of the elite tasks in the Lumbridge & Draynor Achievement Diary`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  1050: {
    id: '1050',
    label: `Complete the Elite Varrock Diary`,
    description: `Complete all of the elite tasks in the Varrock Achievement Diary`,
    skillReqs: [],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  486: {
    id: '486',
    label: `Defeat the Abyssal Sire 150 Times`,
    description: `Defeat the Abyssal Sire in the Abyssal Nexus 150 times`,
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  487: {
    id: '487',
    label: `Defeat the Abyssal Sire 300 Times`,
    description: `Defeat the Abyssal Sire in the Abyssal Nexus 300 times`,
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  485: {
    id: '485',
    label: `Defeat the Abyssal Sire 50 Times`,
    description: `Defeat the Abyssal Sire in the Abyssal Nexus 50 times`,
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  474: {
    id: '474',
    label: `Equip a Dragonfire Shield`,
    description: `Equip a Dragonfire Shield`,
    skillReqs: [{ skill: 'Defence', level: 75 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  481: {
    id: '481',
    label: `Equip an Abyssal Bludgeon`,
    description: `Equip an Abyssal Bludgeon`,
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Strength', level: 70 },
      { skill: 'Slayer', level: 85 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  483: {
    id: '483',
    label: `Equip an Abyssal Dagger`,
    description: `Equip an Abyssal Dagger`,
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Slayer', level: 85 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SIRE,
    prerequisite: '',
  },
  478: {
    id: '478',
    label: `Equip an Ancient Wyvern Shield`,
    description: `Equip an Ancient Wyvern Shield`,
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Defence', level: 75 },
      { skill: 'Smithing', level: 66 },
    ],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  449: {
    id: '449',
    label: `Mine a Runite Ore Fragment`,
    description: `Mine a Runite Ore Fragment in the Volcanic Mine on Fossil Island`,
    skillReqs: [{ skill: 'Mining', level: 85 }],
    regions: ['Misthalin'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1726: {
    id: '1726',
    label: `300 Araxxor Kills`,
    description: `Kill the Araxxor in Morytania Spider cave 300 times`,
    skillReqs: [{ skill: 'Slayer', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ARAXXOR,
    prerequisite: '',
  },
  1642: {
    id: '1642',
    label: `Araxxor Combat Achievements`,
    description: `Complete all of the Combat Achievements for Araxxor`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ARAXXOR,
    prerequisite: '',
  },
  1136: {
    id: '1136',
    label: `Attach a Holy ornament kit to the Scythe of Vitur`,
    description: `Attach a Holy ornament kit to the Scythe of Vitur`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1135: {
    id: '1135',
    label: `Attach a Sanguine ornament kit to the Scythe`,
    description: `Attach a Sanguine ornament kit to the Scythe of Vitur`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  955: {
    id: '955',
    label: `Complete the Elite Morytania Diary`,
    description: `Complete all of the elite tasks in the Morytania Achievement Diary`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  904: {
    id: '904',
    label: `Complete the Hallowed Sepulchre in 6:30`,
    description: `Complete the Hallowed Sepulchre in Darkmeyer in 6:30 or less`,
    skillReqs: [{ skill: 'Agility', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  948: {
    id: '948',
    label: `Complete the Theatre of Blood 100 Times`,
    description: `Complete the Theatre of Blood on Normal or Hard Mode 100 times`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  946: {
    id: '946',
    label: `Complete the Theatre of Blood 25 Times`,
    description: `Complete the Theatre of Blood on Normal or Hard Mode 25 times`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  947: {
    id: '947',
    label: `Complete the Theatre of Blood 50 Times`,
    description: `Complete the Theatre of Blood on Normal or Hard Mode 50 times`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1081: {
    id: '1081',
    label: `Defeat The Nightmare 150 times`,
    description: `Defeat The Nightmare or Phosani's Nightmare 150 times combined`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1080: {
    id: '1080',
    label: `Defeat The Nightmare 50 times`,
    description: `Defeat The Nightmare or Phosani's Nightmare 50 times combined`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  945: {
    id: '945',
    label: `Equip a Ghrazi Rapier`,
    description: `Equip a Ghrazi Rapier`,
    skillReqs: [{ skill: 'Attack', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  935: {
    id: '935',
    label: `Equip a Piece of the Inquisitor's Set`,
    description: `Equip any piece of the Inquisitor's Armour set`,
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 30 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  943: {
    id: '943',
    label: `Equip a Piece of the Justiciar Set`,
    description: `Equip any piece of the Justiciar Armour set`,
    skillReqs: [{ skill: 'Defence', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  905: {
    id: '905',
    label: `Equip a Ring of Endurance`,
    description: `Equip a Ring of Endurance`,
    skillReqs: [{ skill: 'Agility', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  941: {
    id: '941',
    label: `Equip a Sanguinesti Staff`,
    description: `Equip a Sanguinesti Staff`,
    skillReqs: [{ skill: 'Magic', level: 82 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  933: {
    id: '933',
    label: `Equip an Inquisitor's Mace`,
    description: `Equip an Inquisitor's Mace`,
    skillReqs: [{ skill: 'Attack', level: 80 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1727: {
    id: '1727',
    label: `Equip the Amulet of Rancour`,
    description: `Equip the Amulet of Rancour`,
    skillReqs: [
      { skill: 'Slayer', level: 92 },
      { skill: 'Hitpoints', level: 90 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ARAXXOR,
    prerequisite: '',
  },
  1728: {
    id: '1728',
    label: `Equip the Noxious Halberd`,
    description: `Equip the Noxious Halberd`,
    skillReqs: [
      { skill: 'Slayer', level: 92 },
      { skill: 'Attack', level: 80 },
      { skill: 'Smithing', level: 72 },
      { skill: 'Crafting', level: 72 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ARAXXOR,
    prerequisite: '',
  },
  902: {
    id: '902',
    label: `Floor 5 of the Hallowed Sepulchre`,
    description: `Complete floor 5 of the Hallowed Sepulchre in Darkmeyer`,
    skillReqs: [{ skill: 'Agility', level: 92 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  1434: {
    id: '1434',
    label: `Grotesque Guardians Combat Achievements`,
    description: `Complete all of the Combat Achievements for Grotesque Guardians`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GGS,
    prerequisite: '',
  },
  1433: {
    id: '1433',
    label: `Phosani's Nightmare Combat Achievements`,
    description: `Complete all of the Combat Achievements for Phosani's Nightmare`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1432: {
    id: '1432',
    label: `The Nightmare Combat Achievements`,
    description: `Complete all of the Combat Achievements for The Nightmare`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  1377: {
    id: '1377',
    label: `Catch 300 Red Chinchompas in Tirannwn`,
    description: `Catch 300 Red Chinchompas in Tirannwn`,
    skillReqs: [{ skill: 'Hunter', level: 63 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1811: {
    id: '1811',
    label: `Check the health of 5 Crystal Trees`,
    description: `Check the health of 5 Crystal Trees`,
    skillReqs: [{ skill: 'Farming', level: 74 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FARMING,
    prerequisite: '',
  },
  548: {
    id: '548',
    label: `Complete the Corrupted Gauntlet 150 Times`,
    description: `Complete the Corrupted Gauntlet in Prifddinas 150 times`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  547: {
    id: '547',
    label: `Complete the Corrupted Gauntlet 50 Times`,
    description: `Complete the Corrupted Gauntlet in Prifddinas 50 times`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  543: {
    id: '543',
    label: `Craft a Toxic Blowpipe`,
    description: `Craft a Toxic Blowpipe`,
    skillReqs: [
      { skill: 'Fletching', level: 53 },
      { skill: 'Ranged', level: 75 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  545: {
    id: '545',
    label: `Craft a Toxic Trident`,
    description: `Craft a Toxic Trident`,
    skillReqs: [{ skill: 'Crafting', level: 59 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1381: {
    id: '1381',
    label: `Create 100 Divine Ranging Potions`,
    description: `Create 100 Divine Ranging Potions`,
    skillReqs: [{ skill: 'Herblore', level: 74 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1380: {
    id: '1380',
    label: `Create 100 Divine Super Combat Potions`,
    description: `Create 100 Divine Super Combat Potions`,
    skillReqs: [{ skill: 'Herblore', level: 97 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  551: {
    id: '551',
    label: `Defeat Zalcano 100 Times`,
    description: `Defeat Zalcano in Prifddinas 100 times`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.ZALCANO,
    prerequisite: '',
  },
  554: {
    id: '554',
    label: `Defeat Zulrah 150 Times`,
    description: `Defeat Zulrah at the Poison Waste 150 times`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1397: {
    id: '1397',
    label: `Defeat Zulrah 300 Times`,
    description: `Defeat Zulrah at the Poison Waste 300 times`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1390: {
    id: '1390',
    label: `Dismantle a Zulrah scale unique`,
    description: `Dismantle a Zulrah scale unique for 20,000 scales`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1764: {
    id: '1764',
    label: `Dissect 250 Sacred Eels`,
    description: `Dissect 250 Sacred Eels`,
    skillReqs: [
      { skill: 'Fishing', level: 87 },
      { skill: 'Cooking', level: 72 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.FISHING,
    prerequisite: '',
  },
  524: {
    id: '524',
    label: `Equip a Crystal Grail`,
    description: `Equip a Crystal Grail`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  531: {
    id: '531',
    label: `Equip a Full Crystal Armour Set`,
    description: `Equip a full set of Crystal armour`,
    skillReqs: [{ skill: 'Defence', level: 70 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  559: {
    id: '559',
    label: `Equip a Piece of the Dragonstone Armour Set`,
    description: `Equip any piece of the Dragonstone armour set`,
    skillReqs: [{ skill: 'Defence', level: 40 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  541: {
    id: '541',
    label: `Equip a Serpentine Helm`,
    description: `Equip a Serpentine Helm`,
    skillReqs: [
      { skill: 'Crafting', level: 52 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  520: {
    id: '520',
    label: `Equip an Elven Signet`,
    description: `Equip an Elven Signet`,
    skillReqs: [{ skill: 'Hunter', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1396: {
    id: '1396',
    label: `Equip an Enhanced Crystal Weapon`,
    description: `Equip a Blade of Saeldor or Bow of faerdhinen`,
    skillReqs: [{ skill: 'Attack', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  1514: {
    id: '1514',
    label: `Load a blowpipe with Dragon Darts`,
    description: `Load a Blowpipe with Dragon Darts`,
    skillReqs: [
      { skill: 'Fletching', level: 53 },
      { skill: 'Hunter', level: 83 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1379: {
    id: '1379',
    label: `Mine 200 Runite Ore in Tirannwn`,
    description: `Mine 200 Runite Ore in Tirannwn`,
    skillReqs: [{ skill: 'Mining', level: 85 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1052: {
    id: '1052',
    label: `Obtain a Zalcano Shard`,
    description: `Obtain a Zalcano Shard as a drop from Zalcano in Prifddinas`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.ZALCANO,
    prerequisite: '',
  },
  1383: {
    id: '1383',
    label: `Smith 500 Runite bolts (unf) in Prifddinas`,
    description: `Use an Anvil to smith 500 Runite bolts (unf) in Prifddinas`,
    skillReqs: [{ skill: 'Smithing', level: 88 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.SMITHING,
    prerequisite: '',
  },
  1428: {
    id: '1428',
    label: `Zulrah Combat Achievements`,
    description: `Complete all of the Combat Achievements for Zulrah`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.ZULRAH,
    prerequisite: '',
  },
  1720: {
    id: '1720',
    label: `Catch 100 Tecu salamanders`,
    description: `Catch 100 Tecu salamanders`,
    skillReqs: [{ skill: 'Hunter', level: 79 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1696: {
    id: '1696',
    label: `Catch 50 Moonlight Antelopes`,
    description: `Catch 50 Moonlight Antelopes`,
    skillReqs: [{ skill: 'Hunter', level: 91 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1666: {
    id: '1666',
    label: `Complete Wave 12 of Fortis Colosseum`,
    description: `Complete Wave 12 of Fortis Colosseum`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1170: {
    id: '1170',
    label: `Defeat Awakened Vardorvis`,
    description: `Defeat Awakened Vardorvis`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1169: {
    id: '1169',
    label: `Defeat Vardorvis 300 times`,
    description: `Defeat Vardorvis 300 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1722: {
    id: '1722',
    label: `Equip a Tecu Salamander`,
    description: `Equip a Tecu Salamander`,
    skillReqs: [
      { skill: 'Hunter', level: 79 },
      { skill: 'Attack', level: 80 },
      { skill: 'Ranged', level: 80 },
      { skill: 'Magic', level: 80 },
    ],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1749: {
    id: '1749',
    label: `Equip the Moonlight Fanatic Helmet`,
    description: `Equip the Moonlight Fanatic Helmet`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1224: {
    id: '1224',
    label: `Equip the Ultor Ring`,
    description: `Obtain and Equip the Ultor Ring`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1673: {
    id: '1673',
    label: `Equip Tonalztics of Ralos`,
    description: `Equip Tonalztics of Ralos`,
    skillReqs: [{ skill: 'Ranged', level: 75 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1667: {
    id: '1667',
    label: `Obtain 20,000 Glory`,
    description: `Obtain 20,000 Glory in the Fortis Colosseum`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1668: {
    id: '1668',
    label: `Obtain 40,000 Glory`,
    description: `Obtain 40,000 Glory in the Fortis Colosseum`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1748: {
    id: '1748',
    label: `Open the Varlamore Moon Chest`,
    description: `Open the Varlamore Moon Chest`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1676: {
    id: '1676',
    label: `Perilous Moons Combat Achievements`,
    description: `Complete all of the Combat Achievements for Perilous Moons`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MOONS,
    prerequisite: '',
  },
  1736: {
    id: '1736',
    label: `Purchase the Chugging Barrel`,
    description: `Purchase the Chugging Barrel from the Mixology shop`,
    skillReqs: [{ skill: 'Herblore', level: 60 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  1421: {
    id: '1421',
    label: `Vardorvis Combat Achievements`,
    description: `Complete all of the Combat Achievements for Vardorvis`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  1029: {
    id: '1029',
    label: `Complete the Elite Wilderness Diary`,
    description: `Complete all of the elite tasks in the Wilderness Achievement Diary`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  973: {
    id: '973',
    label: `Cook a Dark Crab`,
    description: `Cook a Dark Crab`,
    skillReqs: [
      { skill: 'Cooking', level: 90 },
      { skill: 'Fishing', level: 85 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.COOKING,
    prerequisite: '',
  },
  1406: {
    id: '1406',
    label: `Defeat Callisto 300 times`,
    description: `Defeat Callisto or Artio 300 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1006: {
    id: '1006',
    label: `Defeat the Corporeal Beast 150 Times`,
    description: `Defeat the Corporeal Beast in the Wilderness 150 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1007: {
    id: '1007',
    label: `Defeat the Corporeal Beast 250 Times`,
    description: `Defeat the Corporeal Beast in the Wilderness 250 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1400: {
    id: '1400',
    label: `Defeat Venenatis 300 times`,
    description: `Defeat Venenatis or Spindel 300 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1403: {
    id: '1403',
    label: `Defeat Vet'ion 300 times`,
    description: `Defeat Vet'ion or Calvar'ion 300 times`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  980: {
    id: '980',
    label: `Equip 500 Black Chinchompas`,
    description: `Equip a stack of at least 500 Black Chinchompas`,
    skillReqs: [
      { skill: 'Hunter', level: 73 },
      { skill: 'Ranged', level: 65 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HUNTER,
    prerequisite: '',
  },
  1010: {
    id: '1010',
    label: `Equip a Blessed Spirit Shield`,
    description: `Equip a Blessed Spirit Shield`,
    skillReqs: [
      { skill: 'Defence', level: 70 },
      { skill: 'Prayer', level: 85 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CORP,
    prerequisite: '',
  },
  1041: {
    id: '1041',
    label: `Equip a Full Dagon'Hai Set`,
    description: `Equip a full set of Dagon'hai robes`,
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  1015: {
    id: '1015',
    label: `Equip a Malediction Ward`,
    description: `Equip a Malediction Ward`,
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1040: {
    id: '1040',
    label: `Equip a Piece of the Dagon'Hai Set`,
    description: `Equip any piece of the Dagon'hai robe set`,
    skillReqs: [
      { skill: 'Magic', level: 70 },
      { skill: 'Defence', level: 40 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.SLAYER,
    prerequisite: '',
  },
  997: {
    id: '997',
    label: `Equip a Ring of the Gods`,
    description: `Equip a Ring of the Gods`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  697: {
    id: '697',
    label: `Equip a Thammaron's Sceptre`,
    description: `Equip a Thammaron's Sceptre`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  989: {
    id: '989',
    label: `Equip a Treasonous Ring`,
    description: `Equip a Treasonous Ring`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  993: {
    id: '993',
    label: `Equip a Tyrannical Ring`,
    description: `Equip a Tyrannical Ring`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1016: {
    id: '1016',
    label: `Equip an Odium Ward`,
    description: `Equip an Odium Ward`,
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1018: {
    id: '1018',
    label: `Equip Craw's Bow`,
    description: `Equip Craw's Bow`,
    skillReqs: [{ skill: 'Ranged', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  1297: {
    id: '1297',
    label: `Equip full Elder chaos robe`,
    description: `Equip the Elder Chaos Hood, Robe, and top`,
    skillReqs: [{ skill: 'Magic', level: 40 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1258: {
    id: '1258',
    label: `Equip the Accursed Sceptre`,
    description: `Obtain and Equip the Accursed Sceptre`,
    skillReqs: [{ skill: 'Magic', level: 70 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1259: {
    id: '1259',
    label: `Equip the Ursine Chainmace`,
    description: `Obtain and Equip the Ursine Chainmace`,
    skillReqs: [{ skill: 'Attack', level: 70 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1256: {
    id: '1256',
    label: `Equip the Voidwaker`,
    description: `Obtain and Equip the Voidwaker`,
    skillReqs: [{ skill: 'Attack', level: 75 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  1257: {
    id: '1257',
    label: `Equip the Webweaver`,
    description: `Obtain and Equip the Webweaver`,
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.WILDERNESS,
    prerequisite: '',
  },
  910: {
    id: '910',
    label: `Equip Viggora's Chainmace`,
    description: `Equip Viggora's Chainmace`,
    skillReqs: [{ skill: 'Attack', level: 60 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  974: {
    id: '974',
    label: `Make an Extended Antifire Potion`,
    description: `Make an Extended Antifire Potion`,
    skillReqs: [{ skill: 'Herblore', level: 84 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.HERBLORE,
    prerequisite: '',
  },
  985: {
    id: '985',
    label: `Mine Some Runite Ore in the Wilderness`,
    description: `Mine some Runite Ore in the Wilderness`,
    skillReqs: [{ skill: 'Mining', level: 85 }],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.ELITE,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.MINING,
    prerequisite: '',
  },
  1456: {
    id: '1456',
    label: `750 Collection log slots`,
    description: `Obtain 750 unique Collection Log slots`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.COLLECTION_LOG,
    prerequisite: '',
  },
  572: {
    id: '572',
    label: `Build a Demonic Throne`,
    description: `Build a Demonic Throne in a Throne Room in your Player Owned House`,
    skillReqs: [{ skill: 'Construction', level: 99 }],
    regions: ['Global'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.CONSTRUCTION,
    prerequisite: '',
  },
  1122: {
    id: '1122',
    label: `Combat Achievements Elite Tier`,
    description: `Obtain enough points to unlock the elite tier of Combat Achievements`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.DIARIES,
    subcategory: CATEGORY.DIARIES.subcategories.ELITE,
    prerequisite: '',
  },
  420: {
    id: '420',
    label: `Equip a Full Dragon Set`,
    description: `Equip a Dragon Platebody, a Dragon Full Helm and either some Dragon Platelegs or a Dragon Plateskirt`,
    skillReqs: [
      { skill: 'Defence', level: 60 },
      { skill: 'Smithing', level: 90 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  1226: {
    id: '1226',
    label: `Equip full Virtus`,
    description: `Equip full Virtus outfit, obtained from Desert Treasure II bosses`,
    skillReqs: [
      { skill: 'Magic', level: 78 },
      { skill: 'Defence', level: 75 },
    ],
    regions: ['Global'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.DT2,
    prerequisite: '',
  },
  225: {
    id: '225',
    label: `Reach Base Level 95`,
    description: `Reach level 95 in every skill`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.BASE,
    prerequisite: '',
  },
  214: {
    id: '214',
    label: `Reach Total Level 2277`,
    description: `Reach the highest possible Total Level of 2277`,
    skillReqs: [],
    regions: ['Global'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.TOTAL,
    prerequisite: '',
  },
  1161: {
    id: '1161',
    label: `Equip a Full Set of Torva Armour`,
    description: `Equip a Torva full helm, a Torva platebody and some Torva platelegs`,
    skillReqs: [
      { skill: 'Defence', level: 80 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NEX,
    prerequisite: '',
  },
  785: {
    id: '785',
    label: `Equip Every Godsword`,
    description: `Equip all five Godswords`,
    skillReqs: [
      { skill: 'Attack', level: 75 },
      { skill: 'Agility', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  1427: {
    id: '1427',
    label: `Godwars Dungeon Combat Achievements`,
    description: `Complete all of the Combat Achievements for Godwars Dungeon bosses, including Nex`,
    skillReqs: [],
    regions: ['Asgarnia'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GWD,
    prerequisite: '',
  },
  884: {
    id: '884',
    label: `Equip a Full Infinity Robe Set`,
    description: `Equip a full set of Infinity robes`,
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Defence', level: 25 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.MAGIC,
    prerequisite: '',
  },
  1231: {
    id: '1231',
    label: `Equip a full set of Masori`,
    description: `Obtain and Equip Masori Mask, Body and chaps`,
    skillReqs: [
      { skill: 'Ranged', level: 80 },
      { skill: 'Defence', level: 30 },
    ],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1233: {
    id: '1233',
    label: `Equip Osmumten's Fang (or)`,
    description: `Obtain and Equip Osmumten's Fang (or)`,
    skillReqs: [{ skill: 'Attack', level: 82 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1236: {
    id: '1236',
    label: `Equip the Tumeken's Shadow`,
    description: `Obtain and Equip the Tumeken's Shadow`,
    skillReqs: [{ skill: 'Magic', level: 85 }],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1426: {
    id: '1426',
    label: `Tombs of Amascut Combat Achievements`,
    description: `Complete all of the Combat Achievements for Tombs of Amascut: Entry, Normal and Expert`,
    skillReqs: [],
    regions: ['Desert'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOA,
    prerequisite: '',
  },
  1245: {
    id: '1245',
    label: `Create the Saturated Heart`,
    description: `Create the Saturated Heart`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.MUSPAH,
    prerequisite: '',
  },
  631: {
    id: '631',
    label: `Equip Every Completed God Book`,
    description: `Equip every completed God Book`,
    skillReqs: [],
    regions: ['Fremennik'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.CLUES,
    subcategory: CATEGORY.CLUES.subcategories.SHARED,
    prerequisite: '',
  },
  720: {
    id: '720',
    label: `Enhance a Trident of the Seas`,
    description: `Enhance a Trident of the Seas`,
    skillReqs: [{ skill: 'Slayer', level: 87 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.KRAKEN,
    prerequisite: '',
  },
  725: {
    id: '725',
    label: `Equip a Dragon Full Helm`,
    description: `Equip a Dragon Full Helm`,
    skillReqs: [{ skill: 'Defence', level: 60 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.DEFENCE,
    prerequisite: '',
  },
  713: {
    id: '713',
    label: `Equip All Zenyte Jewelry`,
    description: `Equip every piece of Zenyte Jewelry`,
    skillReqs: [{ skill: 'Crafting', level: 98 }],
    regions: ['Kandarin'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
  669: {
    id: '669',
    label: `Complete the Inferno 15 Times`,
    description: `Complete the Inferno in Mor Ul Rek 15 times`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '',
  },
  1521: {
    id: '1521',
    label: `Complete Tzhaar-Ket-Rak's Special challenge`,
    description: `Complete Tzhaar-Ket-Rak's league-only challenge`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  1576: {
    id: '1576',
    label: `Equip a Pirate Hook`,
    description: `Equip a Pirate Hook from Brimhaven Agility Arena`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.SKILLING,
    subcategory: CATEGORY.SKILLING.subcategories.AGILITY,
    prerequisite: '',
  },
  654: {
    id: '654',
    label: `Equip an Infernal Cape`,
    description: `Equip an Infernal Cape`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '',
  },
  1419: {
    id: '1419',
    label: `The Fight Caves Combat Achievements`,
    description: `Complete all of the Combat Achievements for The Fight Caves`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  1418: {
    id: '1418',
    label: `The Inferno Combat Achievements`,
    description: `Complete all of the Combat Achievements for The Inferno`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.INFERNO,
    prerequisite: '',
  },
  1458: {
    id: '1458',
    label: `TzHaar-Ket-Rak's Combat Achievements`,
    description: `Complete all of the Combat Achievements for TzHaar-Ket-Rak's Challenges`,
    skillReqs: [],
    regions: ['Karamja'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.JAD,
    prerequisite: '',
  },
  1424: {
    id: '1424',
    label: `Chambers of Xeric Combat Achievements`,
    description: `Complete all of the Combat Achievements for Chambers of Xeric: Normal mode and Challenge mode`,
    skillReqs: [],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  1065: {
    id: '1065',
    label: `Equip a full set of Ancestral`,
    description: `Equip an Ancestral Hat, an Ancestral robe top and an Ancestral robe bottom`,
    skillReqs: [
      { skill: 'Magic', level: 75 },
      { skill: 'Defence', level: 65 },
    ],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  131: {
    id: '131',
    label: `Equip a Twisted Bow`,
    description: `Obtain and equip a Twisted bow`,
    skillReqs: [{ skill: 'Ranged', level: 85 }],
    regions: ['Kourend'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.COX,
    prerequisite: '',
  },
  936: {
    id: '936',
    label: `Equip a Full Inquisitor's Set`,
    description: `Equip a full set of Inquisitor's Armour`,
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Defence', level: 30 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  944: {
    id: '944',
    label: `Equip a Full Justiciar Set`,
    description: `Equip a full set of Justiciar Armour`,
    skillReqs: [{ skill: 'Defence', level: 75 }],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  938: {
    id: '938',
    label: `Equip a Nightmare Staff With an Orb`,
    description: `Equip either an Eldritch Nightmare Staff, a Harmonised Nightmare Staff or a Volatile Nightmare Staff`,
    skillReqs: [
      { skill: 'Magic', level: 82 },
      { skill: 'Hitpoints', level: 50 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.NIGHTMARE,
    prerequisite: '',
  },
  942: {
    id: '942',
    label: `Equip a Scythe of Vitur`,
    description: `Equip a Scythe of Vitur`,
    skillReqs: [
      { skill: 'Attack', level: 80 },
      { skill: 'Strength', level: 90 },
    ],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  1425: {
    id: '1425',
    label: `Theatre of Blood Combat Achievements`,
    description: `Complete all of the Combat Achievements for Theatre of Blood: Entry, Normal and Hard mode`,
    skillReqs: [],
    regions: ['Morytania'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.RAIDS,
    subcategory: CATEGORY.RAIDS.subcategories.TOB,
    prerequisite: '',
  },
  536: {
    id: '536',
    label: `Equip a Corrupted Weapon`,
    description: `Equip a Corrupted Blade of Saeldor or Bow of Faerdhinen`,
    skillReqs: [{ skill: 'Attack', level: 80 }],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  560: {
    id: '560',
    label: `Equip a Crystal Crown`,
    description: `Equip a Crystal Crown`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.OTHER,
    subcategory: CATEGORY.OTHER.subcategories.GENERAL,
    prerequisite: '',
  },
  1809: {
    id: '1809',
    label: `Equip a Crystal Tool`,
    description: `Equip a Crystal Tool`,
    skillReqs: [
      { skill: 'Attack', level: 70 },
      { skill: 'Agility', level: 50 },
    ],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.MINIGAMES,
    subcategory: CATEGORY.MINIGAMES.subcategories.ZALCANO,
    prerequisite: '',
  },
  1437: {
    id: '1437',
    label: `Gauntlet Combat Achievements`,
    description: `Complete all of the Combat Achievements for the Gauntlet & Corrupted Gauntlet`,
    skillReqs: [],
    regions: ['Tirannwn'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.GAUNTLET,
    prerequisite: '',
  },
  1675: {
    id: '1675',
    label: `Colosseum Combat Achievements`,
    description: `Complete all of the Combat Achievements for Fortis Colosseum`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1684: {
    id: '1684',
    label: `Defeat Sol Heredit 10 times`,
    description: `Defeat Sol Heredit 10 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1683: {
    id: '1683',
    label: `Defeat Sol Heredit 5 times`,
    description: `Defeat Sol Heredit 5 times`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1672: {
    id: '1672',
    label: `Equip Blessed Dizana's Quiver`,
    description: `Equip Blessed Dizana's Quiver`,
    skillReqs: [{ skill: 'Ranged', level: 75 }],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1669: {
    id: '1669',
    label: `Obtain 58,000 Glory`,
    description: `Obtain 58,000 Glory in the Fortis Colosseum`,
    skillReqs: [],
    regions: ['Varlamore'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.SOL,
    prerequisite: '',
  },
  1008: {
    id: '1008',
    label: `Equip a Spectral or Arcane Spirit Shield`,
    description: `Equip either a Spectral Spirit Shield or an Arcane Spirit Shield`,
    skillReqs: [
      { skill: 'Defence', level: 45 },
      { skill: 'Prayer', level: 55 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CORP,
    prerequisite: '',
  },
  1009: {
    id: '1009',
    label: `Equip an Elysian Spirit Shield`,
    description: `Equip an Elysian Spirit Shield`,
    skillReqs: [
      { skill: 'Defence', level: 75 },
      { skill: 'Prayer', level: 85 },
      { skill: 'Prayer', level: 90 },
    ],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.BOSSING,
    subcategory: CATEGORY.BOSSING.subcategories.CORP,
    prerequisite: '',
  },
  1019: {
    id: '1019',
    label: `Obtain Every Revenant Weapon`,
    description: `Obtain Craws Bow, Viggora's Chainmace and Thammaron's Sceptre as drops from Revenants`,
    skillReqs: [],
    regions: ['Wilderness'],
    difficulty: DIFFICULTY.MASTER,
    category: CATEGORY.COMBAT,
    subcategory: CATEGORY.COMBAT.subcategories.GENERAL,
    prerequisite: '',
  },
};
