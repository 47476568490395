import images from '../assets/images';

export const QUEST_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
};

export const QUEST_DIFFICULTY = {
  NOVICE: {
    id: 0,
    label: 'Novice',
    icon: images['task-bronze-star.png'],
  },
  INTERMEDIATE: {
    id: 1,
    label: 'Intermediate',
    icon: images['task-silver-star.png'],
  },
  EXPERIENCED: {
    id: 2,
    label: 'Experienced',
    icon: images['task-gold-star.png'],
  },
  MASTER: {
    id: 3,
    label: 'Master',
    icon: images['task-two-stars.png'],
  },
  GRANDMASTER: {
    id: 4,
    label: 'Grandmaster',
    icon: images['task-three-stars.png'],
  },
};

export const QUEST_LENGTH = {
  VERY_SHORT: {
    id: 0,
    label: 'Very Short',
    icon: images['task-bronze-sword.png'],
  },
  SHORT: {
    id: 1,
    label: 'Short',
    icon: images['task-iron-sword.png'],
  },
  MEDIUM: {
    id: 2,
    label: 'Medium',
    icon: images['task-mith-sword.png'],
  },
  LONG: {
    id: 3,
    label: 'Long',
    icon: images['task-rune-sword.png'],
  },
  VERY_LONG: {
    id: 4,
    label: 'Very Long',
    icon: images['task-dragon-sword.png'],
  },
};

export const QUEST_SERIES = {
  CAMELOT: {
    id: 0,
    label: 'Camelot',
    icon: images['task-kandarin.png'],
  },
  DESERT: {
    id: 1,
    label: 'Desert',
    icon: images['task-kharidian.png'],
  },
  DORGESHUUN: {
    id: 2,
    label: 'Dorgeshuun',
    icon: images['task-bandos.png'],
  },
  GUILDS: {
    id: 3,
    label: 'Guilds / Dragonkin',
    icon: images['task-legend.png'],
  },
  ELEMENTAL: {
    id: 4,
    label: 'Elemental Workshop',
    icon: images['task-elemental.png'],
  },
  ELF: {
    id: 5,
    label: 'Elven',
    icon: images['task-tiranwnn.png'],
  },
  GNOME: {
    id: 6,
    label: 'Gnome',
    icon: images['task-gnome.png'],
  },
  FAIRY: {
    id: 7,
    label: 'Fairy Tale',
    icon: images['task-cosmic.png'],
  },
  FREMENNIK: {
    id: 8,
    label: 'Fremennik',
    icon: images['task-fremennik.png'],
  },
  KOUREND: {
    id: 9,
    label: 'Great Kourend',
    icon: images['task-xeric.png'],
  },
  KARAMJA: {
    id: 10,
    label: 'Karamja',
    icon: images['task-karamja.png'],
  },
  MAHJARRAT: {
    id: 11,
    label: 'Mahjarrat / Return of Zaros',
    icon: images['task-zaros.png'],
  },
  MYREQUE: {
    id: 12,
    label: 'Myreque',
    icon: images['task-morytania.png'],
  },
  OGRE: {
    id: 13,
    label: 'Ogre',
    icon: images['task-goblin.png'],
  },
  PIRATE: {
    id: 14,
    label: 'Pirate',
    icon: images['task-steel-scim.png'],
  },
  RED_AXE: {
    id: 15,
    label: 'Rise of the Red Axe',
    icon: images['task-dragon-baxe.png'],
  },
  TEMPLE_KNIGHT: {
    id: 16,
    label: 'Temple Knight / Sea Slug',
    icon: images['task-steel-kite.png'],
  },
  TROLL: {
    id: 17,
    label: 'Troll',
    icon: images['task-bossing.png'],
  },
  TWISTED_TALES: {
    id: 18,
    label: 'Twisted Tales',
    icon: images['task-xeric.png'],
  },
  TWILIGHT_EMISSARIES: {
    id: 19,
    label: 'Twilight Emissaries',
    icon: images['task-gold-star.png'],
  },
  VARLAMORE: {
    id: 20,
    label: 'Varlamore',
    icon: images['task-varlamore.png'],
  },
};

export const REGION_IMPOSSIBLE_TO_COMPLETE = 'Impossible';

const quests = [
  {
    id: '0',
    label: 'Animal Magnetism',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Asgarnia', 'Morytania'],
    prereqs: ['44', '111', '120'],
    skillReqs: [
      { skill: 'Ranged', level: 30 },
      { skill: 'Woodcutting', level: 35 },
      { skill: 'Crafting', level: 19 },
      { skill: 'Slayer', level: 18 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Animal_Magnetism',
  },
  {
    id: '1',
    label: 'Another Slice of H.A.M.',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik'],
    prereqs: ['24', '29', '63'],
    skillReqs: [
      { skill: 'Attack', level: 15 },
      { skill: 'Prayer', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Another_Slice_of_H.A.M.',
  },
  {
    id: '3',
    label: 'The Ascent of Arceuus',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['13'],
    skillReqs: [{ skill: 'Hunter', level: 12 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Ascent_of_Arceuus',
  },
  {
    id: '4',
    label: "Alfred Grimhand's Barcrawl",
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'miniquest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Misthalin', 'Karamja', 'Asgarnia', 'Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Alfred_Grimhand's_Barcrawl",
  },
  {
    id: '5',
    label: 'Bear Your Soul',
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.KOUREND,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Kourend', 'Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Bear_Your_Soul',
  },
  {
    id: '6',
    label: 'Below Ice Mountain',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [{ skill: 'QP', level: 16 }],
    wiki: 'https://oldschool.runescape.wiki/w/Below_Ice_Mountain',
  },
  {
    id: '7',
    label: 'Between a Rock...',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.RED_AXE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik', 'Kandarin'],
    prereqs: ['35', '52'],
    skillReqs: [
      { skill: 'Defence', level: 30 },
      { skill: 'Smithing', level: 50 },
      { skill: 'Mining', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Between_a_Rock...',
  },
  {
    id: '8',
    label: 'Big Chompy Bird Hunting',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.OGRE,
    type: 'quest',
    autoUnlockRegions: ['Morytania', 'Tirannwn'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [
      { skill: 'Ranged', level: 30 },
      { skill: 'Cooking', level: 30 },
      { skill: 'Fletching', level: 5 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Big_Chompy_Bird_Hunting',
  },
  {
    id: '9',
    label: 'Biohazard',
    points: 3,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Tirannwn'],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['109'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Biohazard',
  },
  {
    id: '10',
    label: "Black Knights' Fortress",
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [{ skill: 'QP', level: 12 }],
    wiki: "https://oldschool.runescape.wiki/w/Black_Knights'_Fortress",
  },
  {
    id: '11',
    label: 'Bone Voyage',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['29'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Bone_Voyage',
  },
  {
    id: '12',
    label: 'Cabin Fever',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.PIRATE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['108', '120', '124'],
    skillReqs: [
      { skill: 'Ranged', level: 40 },
      { skill: 'Crafting', level: 45 },
      { skill: 'Smithing', level: 50 },
      { skill: 'Agility', level: 42 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Cabin_Fever',
  },
  {
    id: '13',
    label: 'Client of Kourend',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Kourend'],
    prereqs: ['162'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Client_of_Kourend',
  },
  {
    id: '14',
    label: 'Clock Tower',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Clock_Tower',
  },
  {
    id: '15',
    label: 'Cold War',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 30 },
      { skill: 'Agility', level: 30 },
      { skill: 'Thieving', level: 15 },
      { skill: 'Hunter', level: 10 },
      { skill: 'Construction', level: 34 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Cold_War',
  },
  {
    id: '16',
    label: 'Contact!',
    points: 1,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DESERT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: ['112', '75'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Contact!',
  },
  {
    id: '17',
    label: "Cook's Assistant",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin', 'Morytania'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Cook's_Assistant",
  },
  {
    id: '18',
    label: 'The Corsair Curse',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/The_Corsair_Curse',
  },
  {
    id: '19',
    label: 'Creature of Fenkenstrain',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin', 'Morytania'],
    regions: [],
    prereqs: ['111', '120'],
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Thieving', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Creature_of_Fenkenstrain',
  },
  {
    id: '20',
    label: 'Curse of the Empty Lord',
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'miniquest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: ['120'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Curse_of_the_Empty_Lord',
  },
  {
    id: '21',
    label: "Daddy's Home",
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Daddy's_Home",
  },
  {
    id: '22',
    label: 'Darkness of Hallowvale',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['77'],
    skillReqs: [
      { skill: 'Strength', level: 40 },
      { skill: 'Magic', level: 33 },
      { skill: 'Crafting', level: 32 },
      { skill: 'Mining', level: 20 },
      { skill: 'Agility', level: 26 },
      { skill: 'Thieving', level: 22 },
      { skill: 'Construction', level: 5 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Darkness_of_Hallowvale',
  },
  {
    id: '23',
    label: 'Death Plateau',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Death_Plateau',
  },
  {
    id: '24',
    label: 'Death to the Dorgeshuun',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['87'],
    skillReqs: [
      { skill: 'Agility', level: 23 },
      { skill: 'Thieving', level: 23 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Death_to_the_Dorgeshuun',
  },
  {
    id: '25',
    label: 'Demon Slayer',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Demon_Slayer',
  },
  {
    id: '26',
    label: 'The Depths of Despair',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['13'],
    skillReqs: [{ skill: 'Agility', level: 18 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Depths_of_Despair',
  },
  {
    id: '27',
    label: 'Desert Treasure I',
    points: 3,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: ['111', '29', '148', '158', '146', '153'],
    skillReqs: [
      { skill: 'Magic', level: 50 },
      { skill: 'Firemaking', level: 50 },
      { skill: 'Thieving', level: 53 },
      { skill: 'Slayer', level: 10 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Desert_Treasure_I',
  },
  {
    id: '28',
    label: 'Devious Minds',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia', 'Morytania', 'Wilderness'],
    prereqs: ['30', '156', '153', '43'],
    skillReqs: [
      { skill: 'Fletching', level: 50 },
      { skill: 'Smithing', level: 65 },
      { skill: 'Runecraft', level: 50 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Devious_Minds',
  },
  {
    id: '29',
    label: 'The Dig Site',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['34'],
    skillReqs: [
      { skill: 'Herblore', level: 10 },
      { skill: 'Agility', level: 10 },
      { skill: 'Thieving', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Dig_Site',
  },
  {
    id: '30',
    label: "Doric's Quest",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Doric's_Quest",
  },
  {
    id: '31',
    label: 'Dragon Slayer I',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [{ skill: 'QP', level: 32 }],
    wiki: 'https://oldschool.runescape.wiki/w/Dragon_Slayer_I',
  },
  {
    id: '32',
    label: 'Dragon Slayer II',
    points: 5,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['13', '0', '11', '62', '142', '33', '85'],
    skillReqs: [
      { skill: 'Hitpoints', level: 50 },
      { skill: 'Magic', level: 75 },
      { skill: 'Crafting', level: 62 },
      { skill: 'Smithing', level: 70 },
      { skill: 'Mining', level: 68 },
      { skill: 'Agility', level: 60 },
      { skill: 'Thieving', level: 60 },
      { skill: 'Construction', level: 50 },
      { skill: 'QP', level: 200 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Dragon_Slayer_II',
  },
  {
    id: '33',
    label: 'Dream Mentor',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['36', '88'],
    skillReqs: [{ skill: 'Combat', level: 85 }],
    wiki: 'https://oldschool.runescape.wiki/w/Dream_Mentor',
  },
  {
    id: '34',
    label: 'Druidic Ritual',
    points: 4,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Druidic_Ritual',
  },
  {
    id: '35',
    label: 'Dwarf Cannon',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Dwarf_Cannon',
  },
  {
    id: '36',
    label: "Eadgar's Ruse",
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik'],
    regions: [],
    prereqs: ['34', '153'],
    skillReqs: [{ skill: 'Herblore', level: 31 }],
    wiki: "https://oldschool.runescape.wiki/w/Eadgar's_Ruse",
  },
  {
    id: '37',
    label: "Eagles' Peak",
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Varlamore'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Hunter', level: 27 }],
    wiki: "https://oldschool.runescape.wiki/w/Eagles'_Peak",
  },
  {
    id: '38',
    label: 'Elemental Workshop I',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.ELEMENTAL,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Smithing', level: 20 },
      { skill: 'Mining', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Elemental_Workshop_I',
  },
  {
    id: '39',
    label: 'Elemental Workshop II',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELEMENTAL,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: ['38'],
    skillReqs: [
      { skill: 'Magic', level: 20 },
      { skill: 'Smithing', level: 30 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Elemental_Workshop_II',
  },
  {
    id: '40',
    label: "Enakhra's Lament",
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Prayer', level: 43 },
      { skill: 'Magic', level: 39 },
      { skill: 'Firemaking', level: 45 },
      { skill: 'Crafting', level: 50 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Enakhra's_Lament",
  },
  {
    id: '41',
    label: 'The Enchanted Key',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.LONG,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['92'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/The_Enchanted_Key',
  },
  {
    id: '42',
    label: 'Enlightened Journey',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [
      { skill: 'Firemaking', level: 20 },
      { skill: 'Crafting', level: 36 },
      { skill: 'Farming', level: 30 },
      { skill: 'QP', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Enlightened_Journey',
  },
  {
    id: '43',
    label: 'Enter the Abyss',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'miniquest',
    autoUnlockRegions: ['Fremennik', 'Desert', 'Wilderness'],
    regions: [],
    prereqs: ['125'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Enter_the_Abyss',
  },
  {
    id: '44',
    label: 'Ernest the Chicken',
    points: 4,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Ernest_the_Chicken',
  },
  {
    id: '45',
    label: 'The Eyes of Glouphrie',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['66'],
    skillReqs: [
      { skill: 'Magic', level: 46 },
      { skill: 'Construction', level: 5 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Eyes_of_Glouphrie',
  },
  {
    id: '46',
    label: 'Fairytale I - Growing Pains',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FAIRY,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['103', '86'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Fairytale_I_-_Growing_Pains',
  },
  {
    id: '47',
    label: 'Fairytale II - Cure a Queen',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FAIRY,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['34', '46'],
    skillReqs: [
      { skill: 'Herblore', level: 57 },
      { skill: 'Thieving', level: 40 },
      { skill: 'Farming', level: 49 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Fairytale_II_-_Cure_a_Queen',
  },
  {
    id: '48',
    label: 'Family Crest',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: [],
    skillReqs: [
      { skill: 'Magic', level: 59 },
      { skill: 'Crafting', level: 40 },
      { skill: 'Smithing', level: 40 },
      { skill: 'Mining', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Family_Crest',
  },
  {
    id: '49',
    label: 'Family Pest',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Misthalin', 'Desert', 'Kandarin', 'Fremennik'],
    prereqs: ['48'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Family_Pest',
  },
  {
    id: '50',
    label: 'The Feud',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik'],
    regions: [],
    prereqs: [],
    skillReqs: [{ skill: 'Thieving', level: 30 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Feud',
  },
  {
    id: '51',
    label: 'Fight Arena',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Fight_Arena',
  },
  {
    id: '52',
    label: 'Fishing Contest',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Fishing', level: 10 }],
    wiki: 'https://oldschool.runescape.wiki/w/Fishing_Contest',
  },
  {
    id: '53',
    label: 'Forgettable Tale...',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.RED_AXE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik', 'Kandarin'],
    prereqs: ['52', '63'],
    skillReqs: [
      { skill: 'Cooking', level: 22 },
      { skill: 'Farming', level: 17 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Forgettable_Tale...',
  },
  {
    id: '54',
    label: 'The Forsaken Tower',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['13'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/The_Forsaken_Tower',
  },
  {
    id: '55',
    label: 'The Fremennik Exiles',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Fremennik'],
    prereqs: ['57', '98', '56', '72', '88'],
    skillReqs: [
      { skill: 'Fishing', level: 60 },
      { skill: 'Crafting', level: 65 },
      { skill: 'Smithing', level: 60 },
      { skill: 'Slayer', level: 60 },
      { skill: 'Runecraft', level: 55 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Fremennik_Exiles',
  },
  {
    id: '56',
    label: 'The Fremennik Isles',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Fremennik'],
    prereqs: ['57'],
    skillReqs: [
      { skill: 'Agility', level: 40 },
      { skill: 'Construction', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Fremennik_Isles',
  },
  {
    id: '57',
    label: 'The Fremennik Trials',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 40 },
      { skill: 'Fletching', level: 25 },
      { skill: 'Crafting', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Fremennik_Trials',
  },
  {
    id: '58',
    label: 'Garden of Tranquillity',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.VERY_LONG,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: ['19'],
    skillReqs: [{ skill: 'Farming', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/Garden_of_Tranquillity',
  },
  {
    id: '59',
    label: "The General's Shadow",
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'miniquest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: ['51', '20'],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/The_General's_Shadow",
  },
  {
    id: '60',
    label: "Gertrude's Cat",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Gertrude's_Cat",
  },
  {
    id: '61',
    label: 'Getting Ahead',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 30 },
      { skill: 'Construction', level: 26 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Getting_Ahead',
  },
  {
    id: '62',
    label: 'Ghosts Ahoy',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Morytania'],
    prereqs: ['111', '120'],
    skillReqs: [
      { skill: 'Cooking', level: 20 },
      { skill: 'Agility', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Ghosts_Ahoy',
  },
  {
    id: '63',
    label: 'The Giant Dwarf',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.RED_AXE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik'],
    prereqs: [],
    skillReqs: [
      { skill: 'Magic', level: 33 },
      { skill: 'Firemaking', level: 16 },
      { skill: 'Crafting', level: 12 },
      { skill: 'Thieving', level: 14 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Giant_Dwarf',
  },
  {
    id: '64',
    label: 'Goblin Diplomacy',
    points: 5,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Goblin_Diplomacy',
  },
  {
    id: '65',
    label: 'The Golem',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Thieving', level: 25 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Golem',
  },
  {
    id: '66',
    label: 'The Grand Tree',
    points: 5,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [{ skill: 'Agility', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Grand_Tree',
  },
  {
    id: '67',
    label: 'The Great Brain Robbery',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.PIRATE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['19', '12'],
    skillReqs: [
      { skill: 'Prayer', level: 50 },
      { skill: 'Crafting', level: 16 },
      { skill: 'Construction', level: 30 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Great_Brain_Robbery',
  },
  {
    id: '68',
    label: 'Grim Tales',
    points: 1,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: ['34', '160'],
    skillReqs: [
      { skill: 'Woodcutting', level: 71 },
      { skill: 'Herblore', level: 52 },
      { skill: 'Agility', level: 59 },
      { skill: 'Thieving', level: 58 },
      { skill: 'Farming', level: 45 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Grim_Tales',
  },
  {
    id: '69',
    label: 'The Hand in the Sand',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 49 },
      { skill: 'Thieving', level: 17 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Hand_in_the_Sand',
  },
  {
    id: '70',
    label: 'Haunted Mine',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['111'],
    skillReqs: [
      { skill: 'Agility', level: 15 },
      { skill: 'Runecraft', level: 35 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Haunted_Mine',
  },
  {
    id: '71',
    label: 'Hazeel Cult',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Hazeel_Cult',
  },
  {
    id: '72',
    label: "Heroes' Quest",
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Asgarnia'],
    prereqs: ['34', '132', '93', '31', '86'],
    skillReqs: [
      { skill: 'Cooking', level: 53 },
      { skill: 'Fishing', level: 53 },
      { skill: 'Mining', level: 50 },
      { skill: 'Herblore', level: 25 },
      { skill: 'QP', level: 55 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Heroes'_Quest",
  },
  {
    id: '73',
    label: 'Holy Grail',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.CAMELOT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['93'],
    skillReqs: [{ skill: 'Attack', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/Holy_Grail',
  },
  {
    id: '74',
    label: 'Horror from the Deep',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['4'],
    skillReqs: [{ skill: 'Agility', level: 35 }],
    wiki: 'https://oldschool.runescape.wiki/w/Horror_from_the_Deep',
  },
  {
    id: '75',
    label: "Icthlarin's Little Helper",
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.DESERT,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Desert'],
    prereqs: ['60'],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Icthlarin's_Little_Helper",
  },
  {
    id: '76',
    label: 'Imp Catcher',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Imp_Catcher',
  },
  {
    id: '77',
    label: 'In Aid of the Myreque',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['79'],
    skillReqs: [
      { skill: 'Magic', level: 7 },
      { skill: 'Crafting', level: 25 },
      { skill: 'Mining', level: 15 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/In_Aid_of_the_Myreque',
  },
  {
    id: '78',
    label: 'In Search of Knowledge',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/In_Search_of_Knowledge',
  },
  {
    id: '79',
    label: 'In Search of the Myreque',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['103'],
    skillReqs: [{ skill: 'Agility', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/In_Search_of_the_Myreque',
  },
  {
    id: '80',
    label: 'Jungle Potion',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KARAMJA,
    type: 'quest',
    autoUnlockRegions: ['Karamja'],
    regions: [],
    prereqs: ['34'],
    skillReqs: [{ skill: 'Herblore', level: 3 }],
    wiki: 'https://oldschool.runescape.wiki/w/Jungle_Potion',
  },
  {
    id: '81',
    label: 'A Kingdom Divided',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['34', '54', '3', '26', '113', '13', '143'],
    skillReqs: [
      { skill: 'Magic', level: 35 },
      { skill: 'Woodcutting', level: 52 },
      { skill: 'Crafting', level: 38 },
      { skill: 'Mining', level: 42 },
      { skill: 'Herblore', level: 50 },
      { skill: 'Agility', level: 54 },
      { skill: 'Thieving', level: 52 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/A_Kingdom_Divided',
  },
  {
    id: '82',
    label: "King's Ransom",
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.CAMELOT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['10', '73', '107'],
    skillReqs: [
      { skill: 'Defence', level: 65 },
      { skill: 'Magic', level: 45 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/King's_Ransom",
  },
  {
    id: '83',
    label: "The Knight's Sword",
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [{ skill: 'Mining', level: 10 }],
    wiki: "https://oldschool.runescape.wiki/w/The_Knight's_Sword",
  },
  {
    id: '84',
    label: 'Lair of Tarn Razorlor',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['70'],
    skillReqs: [{ skill: 'Slayer', level: 40 }],
    wiki: 'https://oldschool.runescape.wiki/w/Lair_of_Tarn_Razorlor',
  },
  {
    id: '85',
    label: "Legends' Quest",
    points: 4,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['158', '48', '72', '133', '154'],
    skillReqs: [
      { skill: 'Strength', level: 50 },
      { skill: 'Prayer', level: 42 },
      { skill: 'Magic', level: 56 },
      { skill: 'Woodcutting', level: 50 },
      { skill: 'Crafting', level: 50 },
      { skill: 'Smithing', level: 50 },
      { skill: 'Mining', level: 52 },
      { skill: 'Herblore', level: 45 },
      { skill: 'Agility', level: 50 },
      { skill: 'Thieving', level: 50 },
      { skill: 'QP', level: 107 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Legends'_Quest",
  },
  {
    id: '86',
    label: 'Lost City',
    points: 3,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.FAIRY,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 36 },
      { skill: 'Crafting', level: 31 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Lost_City',
  },
  {
    id: '87',
    label: 'The Lost Tribe',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia'],
    prereqs: ['64', '125'],
    skillReqs: [
      { skill: 'Mining', level: 17 },
      { skill: 'Agility', level: 13 },
      { skill: 'Thieving', level: 13 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Lost_Tribe',
  },
  {
    id: '88',
    label: 'Lunar Diplomacy',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['125', '57', '86', '133'],
    skillReqs: [
      { skill: 'Defence', level: 40 },
      { skill: 'Magic', level: 65 },
      { skill: 'Woodcutting', level: 55 },
      { skill: 'Firemaking', level: 49 },
      { skill: 'Crafting', level: 61 },
      { skill: 'Mining', level: 60 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Lunar_Diplomacy',
  },
  {
    id: '89',
    label: 'Mage Arena I',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Wilderness'],
    prereqs: [],
    skillReqs: [{ skill: 'Magic', level: 60 }],
    wiki: 'https://oldschool.runescape.wiki/w/Mage_Arena_I',
  },
  {
    id: '90',
    label: 'Mage Arena II',
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Wilderness'],
    prereqs: ['89'],
    skillReqs: [{ skill: 'Magic', level: 75 }],
    wiki: 'https://oldschool.runescape.wiki/w/Mage_Arena_II',
  },
  {
    id: '91',
    label: 'Making Friends with My Arm',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: ['121', '15', '102', '140'],
    skillReqs: [
      { skill: 'Firemaking', level: 66 },
      { skill: 'Mining', level: 72 },
      { skill: 'Agility', level: 68 },
      { skill: 'Construction', level: 35 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Making_Friends_with_My_Arm',
  },
  {
    id: '92',
    label: 'Making History',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: ['Fremennik', 'Kandarin', 'Morytania'],
    prereqs: ['111', '120'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Making_History',
  },
  {
    id: '93',
    label: "Merlin's Crystal",
    points: 6,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.CAMELOT,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Merlin's_Crystal",
  },
  {
    id: '94',
    label: 'Misthalin Mystery',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Misthalin_Mystery',
  },
  {
    id: '95',
    label: 'Monkey Madness I',
    points: 3,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['150', '66'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Monkey_Madness_I',
  },
  {
    id: '96',
    label: 'Monkey Madness II',
    points: 4,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['42', '45', '157', '153', '95'],
    skillReqs: [
      { skill: 'Firemaking', level: 60 },
      { skill: 'Agility', level: 55 },
      { skill: 'Thieving', level: 55 },
      { skill: 'Slayer', level: 69 },
      { skill: 'Runecraft', level: 70 },
      { skill: 'Hunter', level: 60 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Monkey_Madness_II',
  },
  {
    id: '97',
    label: "Monk's Friend",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Monk's_Friend",
  },
  {
    id: '98',
    label: 'Mountain Daughter',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: [],
    skillReqs: [{ skill: 'Agility', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/Mountain_Daughter',
  },
  {
    id: '99',
    label: "Mourning's End Part I",
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['130', '122'],
    skillReqs: [
      { skill: 'Ranged', level: 60 },
      { skill: 'Thieving', level: 50 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Mourning's_End_Part_I",
  },
  {
    id: '100',
    label: "Mourning's End Part II",
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['8', '99'],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Mourning's_End_Part_II",
  },
  {
    id: '101',
    label: 'Murder Mystery',
    points: 3,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.CAMELOT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Murder_Mystery',
  },
  {
    id: '102',
    label: "My Arm's Big Adventure",
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik'],
    regions: [],
    prereqs: ['80', '50', '36'],
    skillReqs: [
      { skill: 'Woodcutting', level: 10 },
      { skill: 'Farming', level: 29 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/My_Arm's_Big_Adventure",
  },
  {
    id: '103',
    label: 'Nature Spirit',
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: ['111', '120'],
    skillReqs: [{ skill: 'Crafting', level: 18 }],
    wiki: 'https://oldschool.runescape.wiki/w/Nature_Spirit',
  },
  {
    id: '104',
    label: 'A Night at the Theatre',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['144'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/A_Night_at_the_Theatre',
  },
  {
    id: '105',
    label: 'Observatory Quest',
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Crafting', level: 10 }],
    wiki: 'https://oldschool.runescape.wiki/w/Observatory_Quest',
  },
  {
    id: '106',
    label: "Olaf's Quest",
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Fremennik'],
    prereqs: ['57'],
    skillReqs: [
      { skill: 'Woodcutting', level: 50 },
      { skill: 'Firemaking', level: 40 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Olaf's_Quest",
  },
  {
    id: '107',
    label: 'One Small Favour',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.KARAMJA,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Kandarin', 'Desert'],
    regions: [],
    prereqs: ['125', '133'],
    skillReqs: [
      { skill: 'Crafting', level: 25 },
      { skill: 'Smithing', level: 30 },
      { skill: 'Herblore', level: 18 },
      { skill: 'Agility', level: 36 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/One_Small_Favour',
  },
  {
    id: '108',
    label: "Pirate's Treasure",
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.PIRATE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Pirate's_Treasure",
  },
  {
    id: '109',
    label: 'Plague City',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Tirannwn'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Plague_City',
  },
  {
    id: '110',
    label: 'A Porcine of Interest',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin', 'Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/A_Porcine_of_Interest',
  },
  {
    id: '111',
    label: 'Priest in Peril',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Priest_in_Peril',
  },
  {
    id: '112',
    label: 'Prince Ali Rescue',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Prince_Ali_Rescue',
  },
  {
    id: '113',
    label: 'The Queen of Thieves',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['13'],
    skillReqs: [{ skill: 'Thieving', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Queen_of_Thieves',
  },
  {
    id: '114',
    label: 'Rag and Bone Man I',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin', 'Karamja'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Rag_and_Bone_Man_I',
  },
  {
    id: '115',
    label: 'Rag and Bone Man II',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    type: 'quest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['114', '19', '57', '163', '74', '135'],
    skillReqs: [{ skill: 'Slayer', level: 40 }],
    wiki: 'https://oldschool.runescape.wiki/w/Rag_and_Bone_Man_II',
  },
  {
    id: '116',
    label: 'Ratcatchers',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['75'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Ratcatchers',
  },
  {
    id: '117',
    label: 'Recipe for Disaster',
    points: 10,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    type: 'quest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['9', '17', '25', '52', '60', '64', '8', '103', '129', '72', '74', '86', '133', '154', '27', '85', '95'],
    skillReqs: [
      { skill: 'Ranged', level: 40 },
      { skill: 'Magic', level: 59 },
      { skill: 'Cooking', level: 70 },
      { skill: 'Woodcutting', level: 36 },
      { skill: 'Fletching', level: 10 },
      { skill: 'Fishing', level: 53 },
      { skill: 'Firemaking', level: 50 },
      { skill: 'Crafting', level: 40 },
      { skill: 'Smithing', level: 40 },
      { skill: 'Mining', level: 50 },
      { skill: 'Herblore', level: 25 },
      { skill: 'Agility', level: 48 },
      { skill: 'Thieving', level: 53 },
      { skill: 'Slayer', level: 10 },
      { skill: 'QP', level: 175 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster',
  },
  {
    id: '118',
    label: 'Recruitment Drive',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.TEMPLE_KNIGHT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia'],
    prereqs: ['10', '34'],
    skillReqs: [{ skill: 'QP', level: 12 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recruitment_Drive',
  },
  {
    id: '119',
    label: 'Regicide',
    points: 3,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['154'],
    skillReqs: [
      { skill: 'Crafting', level: 10 },
      { skill: 'Agility', level: 56 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Regicide',
  },
  {
    id: '120',
    label: 'The Restless Ghost',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/The_Restless_Ghost',
  },
  {
    id: '121',
    label: 'Romeo & Juliet',
    points: 5,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Romeo_&_Juliet',
  },
  {
    id: '122',
    label: 'Roving Elves',
    points: 1,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['158', '119'],
    skillReqs: [{ skill: 'Agility', level: 56 }],
    wiki: 'https://oldschool.runescape.wiki/w/Roving_Elves',
  },
  {
    id: '123',
    label: 'Royal Trouble',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Fremennik'],
    prereqs: ['147'],
    skillReqs: [
      { skill: 'Agility', level: 40 },
      { skill: 'Slayer', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Royal_Trouble',
  },
  {
    id: '124',
    label: 'Rum Deal',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.PIRATE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: [],
    prereqs: ['111', '163'],
    skillReqs: [
      { skill: 'Prayer', level: 47 },
      { skill: 'Fishing', level: 50 },
      { skill: 'Crafting', level: 42 },
      { skill: 'Slayer', level: 42 },
      { skill: 'Farming', level: 40 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Rum_Deal',
  },
  {
    id: '125',
    label: 'Rune Mysteries',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin', 'Wilderness'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Rune_Mysteries',
  },
  {
    id: '126',
    label: 'Scorpion Catcher',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['4'],
    skillReqs: [{ skill: 'Prayer', level: 31 }],
    wiki: 'https://oldschool.runescape.wiki/w/Scorpion_Catcher',
  },
  {
    id: '127',
    label: 'Sea Slug',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.TEMPLE_KNIGHT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Firemaking', level: 30 }],
    wiki: 'https://oldschool.runescape.wiki/w/Sea_Slug',
  },
  {
    id: '128',
    label: "Shades of Mort'ton",
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['34', '111'],
    skillReqs: [
      { skill: 'Firemaking', level: 5 },
      { skill: 'Crafting', level: 20 },
      { skill: 'Herblore', level: 15 },
    ],
    wiki: "https://oldschool.runescape.wiki/w/Shades_of_Mort'ton",
  },
  {
    id: '129',
    label: 'Shadow of the Storm',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: ['25', '65'],
    skillReqs: [{ skill: 'Crafting', level: 30 }],
    wiki: 'https://oldschool.runescape.wiki/w/Shadow_of_the_Storm',
  },
  {
    id: '130',
    label: 'Sheep Herder',
    points: 4,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Sheep_Herder',
  },
  {
    id: '131',
    label: 'Sheep Shearer',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Sheep_Shearer',
  },
  {
    id: '132',
    label: 'Shield of Arrav',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Shield_of_Arrav',
  },
  {
    id: '133',
    label: 'Shilo Village',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.KARAMJA,
    type: 'quest',
    autoUnlockRegions: ['Karamja'],
    regions: [],
    prereqs: ['80'],
    skillReqs: [
      { skill: 'Crafting', level: 20 },
      { skill: 'Agility', level: 32 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Shilo_Village',
  },
  {
    id: '134',
    label: 'Sins of the Father',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['155', '144'],
    skillReqs: [
      { skill: 'Attack', level: 50 },
      { skill: 'Magic', level: 49 },
      { skill: 'Woodcutting', level: 62 },
      { skill: 'Fletching', level: 60 },
      { skill: 'Crafting', level: 56 },
      { skill: 'Agility', level: 52 },
      { skill: 'Slayer', level: 50 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Sins_of_the_Father',
  },
  {
    id: '135',
    label: 'Skippy and the Mogres',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [{ skill: 'Cooking', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/Skippy_and_the_Mogres',
  },
  {
    id: '136',
    label: 'The Slug Menace',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.TEMPLE_KNIGHT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin', 'Desert'],
    prereqs: ['127', '156'],
    skillReqs: [
      { skill: 'Crafting', level: 30 },
      { skill: 'Thieving', level: 30 },
      { skill: 'Slayer', level: 30 },
      { skill: 'Runecraft', level: 30 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Slug_Menace',
  },
  {
    id: '137',
    label: 'Song of the Elves',
    points: 4,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Tirannwn'],
    regions: [],
    prereqs: ['34', '92', '100'],
    skillReqs: [
      { skill: 'Woodcutting', level: 70 },
      { skill: 'Smithing', level: 70 },
      { skill: 'Mining', level: 70 },
      { skill: 'Herblore', level: 70 },
      { skill: 'Agility', level: 70 },
      { skill: 'Farming', level: 70 },
      { skill: 'Hunter', level: 70 },
      { skill: 'Construction', level: 70 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Song_of_the_Elves',
  },
  {
    id: '138',
    label: "A Soul's Bane",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/A_Soul's_Bane",
  },
  {
    id: '139',
    label: 'Spirits of the Elid',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DESERT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: [],
    skillReqs: [
      { skill: 'Ranged', level: 37 },
      { skill: 'Magic', level: 33 },
      { skill: 'Mining', level: 37 },
      { skill: 'Thieving', level: 37 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Spirits_of_the_Elid',
  },
  {
    id: '140',
    label: 'Swan Song',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: ['58', '107'],
    skillReqs: [
      { skill: 'Magic', level: 66 },
      { skill: 'Cooking', level: 62 },
      { skill: 'Fishing', level: 62 },
      { skill: 'Firemaking', level: 42 },
      { skill: 'Crafting', level: 40 },
      { skill: 'Smithing', level: 45 },
      { skill: 'QP', level: 100 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Swan_Song',
  },
  {
    id: '141',
    label: 'Tai Bwo Wannai Trio',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.KARAMJA,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Karamja'],
    prereqs: ['80'],
    skillReqs: [
      { skill: 'Cooking', level: 30 },
      { skill: 'Fishing', level: 5 },
      { skill: 'Agility', level: 15 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Tai_Bwo_Wannai_Trio',
  },
  {
    id: '142',
    label: 'A Tail of Two Cats',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.GUILDS,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: ['Asgarnia', 'Desert'],
    prereqs: ['75'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/A_Tail_of_Two_Cats',
  },
  {
    id: '143',
    label: 'Tale of the Righteous',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kourend'],
    prereqs: ['13'],
    skillReqs: [
      { skill: 'Strength', level: 16 },
      { skill: 'Mining', level: 10 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Tale_of_the_Righteous',
  },
  {
    id: '144',
    label: 'A Taste of Hope',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.MYREQUE,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Morytania'],
    prereqs: ['34', '22'],
    skillReqs: [
      { skill: 'Attack', level: 40 },
      { skill: 'Crafting', level: 48 },
      { skill: 'Herblore', level: 40 },
      { skill: 'Agility', level: 45 },
      { skill: 'Slayer', level: 38 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/A_Taste_of_Hope',
  },
  {
    id: '145',
    label: 'Tears of Guthix',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Firemaking', level: 49 },
      { skill: 'Crafting', level: 20 },
      { skill: 'Mining', level: 20 },
      { skill: 'QP', level: 43 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Tears_of_Guthix',
  },
  {
    id: '146',
    label: 'Temple of Ikov',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [
      { skill: 'Ranged', level: 40 },
      { skill: 'Thieving', level: 42 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Temple_of_Ikov',
  },
  {
    id: '147',
    label: 'Throne of Miscellania',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.FREMENNIK,
    type: 'quest',
    autoUnlockRegions: ['Fremennik'],
    regions: [],
    prereqs: ['57', '72'],
    skillReqs: [
      { skill: 'Woodcutting', level: 45 },
      { skill: 'Fishing', level: 35 },
      { skill: 'Mining', level: 30 },
      { skill: 'Farming', level: 10 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Throne_of_Miscellania',
  },
  {
    id: '148',
    label: 'The Tourist Trap',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: [],
    skillReqs: [
      { skill: 'Fletching', level: 10 },
      { skill: 'Smithing', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Tourist_Trap',
  },
  {
    id: '149',
    label: 'Tower of Life',
    points: 2,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Construction', level: 10 }],
    wiki: 'https://oldschool.runescape.wiki/w/Tower_of_Life',
  },
  {
    id: '150',
    label: 'Tree Gnome Village',
    points: 2,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Tree_Gnome_Village',
  },
  {
    id: '151',
    label: 'Tribal Totem',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Karamja'],
    prereqs: [],
    skillReqs: [{ skill: 'Thieving', level: 21 }],
    wiki: 'https://oldschool.runescape.wiki/w/Tribal_Totem',
  },
  {
    id: '152',
    label: 'Troll Romance',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['153'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Troll_Romance',
  },
  {
    id: '153',
    label: 'Troll Stronghold',
    points: 1,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TROLL,
    type: 'quest',
    autoUnlockRegions: ['Asgarnia', 'Desert', 'Fremennik', 'Kandarin'],
    regions: [],
    prereqs: ['23'],
    skillReqs: [{ skill: 'Agility', level: 15 }],
    wiki: 'https://oldschool.runescape.wiki/w/Troll_Stronghold',
  },
  {
    id: '154',
    label: 'Underground Pass',
    points: 5,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Tirannwn'],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['9'],
    skillReqs: [{ skill: 'Ranged', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/Underground_Pass',
  },
  {
    id: '155',
    label: 'Vampyre Slayer',
    points: 3,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Vampyre_Slayer',
  },
  {
    id: '156',
    label: 'Wanted!',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.TEMPLE_KNIGHT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: ['Asgarnia', 'Morytania', 'Wilderness'],
    prereqs: ['111', '118', '87', '43'],
    skillReqs: [{ skill: 'QP', level: 32 }],
    wiki: 'https://oldschool.runescape.wiki/w/Wanted!',
  },
  {
    id: '157',
    label: 'Watchtower',
    points: 4,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.LONG,
    series: QUEST_SERIES.OGRE,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['34'],
    skillReqs: [
      { skill: 'Magic', level: 15 },
      { skill: 'Mining', level: 40 },
      { skill: 'Herblore', level: 14 },
      { skill: 'Agility', level: 25 },
      { skill: 'Thieving', level: 15 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Watchtower',
  },
  {
    id: '158',
    label: 'Waterfall Quest',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.ELF,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik', 'Tirannwn'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Waterfall_Quest',
  },
  {
    id: '159',
    label: 'What Lies Below',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Misthalin', ['Wilderness', 'Desert']],
    prereqs: ['125'],
    skillReqs: [{ skill: 'Runecraft', level: 35 }],
    wiki: 'https://oldschool.runescape.wiki/w/What_Lies_Below',
  },
  {
    id: '160',
    label: "Witch's House",
    points: 4,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Witch's_House",
  },
  {
    id: '161',
    label: "Witch's Potion",
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: "https://oldschool.runescape.wiki/w/Witch's_Potion",
  },
  {
    id: '162',
    label: 'X Marks the Spot',
    points: 1,
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.KOUREND,
    type: 'quest',
    autoUnlockRegions: ['Fremennik', 'Kourend'],
    regions: ['Misthalin', 'Asgarnia'],
    prereqs: [],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/X_Marks_the_Spot',
  },
  {
    id: '163',
    label: 'Zogre Flesh Eaters',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.OGRE,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: ['Kandarin'],
    prereqs: ['80', '8'],
    skillReqs: [
      { skill: 'Strength', level: 10 },
      { skill: 'Ranged', level: 30 },
      { skill: 'Fletching', level: 30 },
      { skill: 'Smithing', level: 4 },
      { skill: 'Herblore', level: 8 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Zogre_Flesh_Eaters',
  },
  {
    id: '164',
    label: 'The Frozen Door',
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.LONG,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Asgarnia'],
    prereqs: ['27'],
    skillReqs: [
      { skill: 'Strength', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Agility', level: 70 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Frozen_Door',
  },
  {
    id: '165',
    label: 'Land of the Goblins',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik', 'Kandarin'],
    prereqs: ['52', '1'],
    skillReqs: [
      { skill: 'Herblore', level: 48 },
      { skill: 'Thieving', level: 45 },
      { skill: 'Fishing', level: 40 },
      { skill: 'Agility', level: 38 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Land_of_the_Goblins',
  },
  {
    id: '166',
    label: "Hopespear's Will",
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.DORGESHUUN,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['47', '27'],
    skillReqs: [{ skill: 'Prayer', level: 50 }],
    wiki: 'https://oldschool.runescape.wiki/w/Hopespear%27s_Will',
  },
  {
    id: '167',
    label: 'Temple of the Eye',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: ['43'],
    skillReqs: [{ skill: 'Runecraft', level: 10 }],
    wiki: 'https://oldschool.runescape.wiki/w/Temple_of_the_Eye',
  },
  {
    id: '168',
    label: 'Beneath Cursed Sands',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DESERT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: ['16'],
    skillReqs: [
      { skill: 'Agility', level: 62 },
      { skill: 'Crafting', level: 55 },
      { skill: 'Firemaking', level: 55 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Beneath_Cursed_Sands',
  },
  {
    id: '169',
    label: 'Sleeping Giants',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert'],
    prereqs: [],
    skillReqs: [{ skill: 'Smithing', level: 15 }],
    wiki: 'https://oldschool.runescape.wiki/w/Sleeping_Giants',
  },
  {
    id: '180',
    label: 'The Garden of Death',
    points: 1,
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.TWISTED_TALES,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: ['Kourend'],
    prereqs: [],
    skillReqs: [{ skill: 'Farming', level: 20 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Garden_of_Death',
  },
  {
    id: '2306',
    label: 'Into the Tombs',
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.DESERT,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Misthalin', 'Desert'],
    prereqs: ['168'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Into_the_Tombs',
  },
  {
    id: '2307',
    label: "Recipe for Disaster - Another Cook's Quest",
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Kandarin', 'Morytania'],
    regions: [],
    prereqs: ['17'],
    skillReqs: [{ skill: 'Cooking', level: 10 }],
    wiki: "https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Another_Cook's_Quest",
  },
  {
    id: '2308',
    label: 'Recipe for Disaster - Mountain Dwarf',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['52'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_the_Mountain_Dwarf',
  },
  {
    id: '2309',
    label: 'Recipe for Disaster - Wartface & Bentnoze',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', ['Kandarin', 'Morytania']],
    prereqs: ['64'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_the_Goblin_generals',
  },
  {
    id: '2310',
    label: 'Recipe for Disaster - Pirate Pete',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: ['Morytania'],
    regions: ['Kandarin'],
    prereqs: [],
    skillReqs: [{ skill: 'Cooking', level: 31 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Pirate_Pete',
  },
  {
    id: '2311',
    label: 'Recipe for Disaster - Lumbridge Guide',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Kandarin'],
    prereqs: ['9', '25', '8', '103'],
    skillReqs: [{ skill: 'Cooking', level: 40 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_the_Lumbridge_Guide',
  },
  {
    id: '2312',
    label: 'Recipe for Disaster - Evil Dave',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Desert', ['Kandarin', 'Morytania']],
    prereqs: ['60', '129'],
    skillReqs: [{ skill: 'Cooking', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Evil_Dave',
  },
  {
    id: '2313',
    label: 'Recipe for Disaster - Skrach Uglogwee',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: ['8'],
    skillReqs: [
      { skill: 'Cooking', level: 41 },
      { skill: 'Firemaking', level: 20 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Skrach_Uglogwee',
  },
  {
    id: '2314',
    label: 'Recipe for Disaster - Sir Amik Varze',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Fremennik', ['Kandarin', 'Morytania']],
    prereqs: ['72', '86', '133', '154', '85'],
    skillReqs: [{ skill: 'QP', level: 107 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_Sir_Amik_Varze',
  },
  {
    id: '2315',
    label: 'Recipe for Disaster - King Awowogei',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.LONG,
    type: 'quest',
    autoUnlockRegions: ['Kandarin'],
    regions: [],
    prereqs: ['95'],
    skillReqs: [
      { skill: 'Cooking', level: 70 },
      { skill: 'Agility', level: 48 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Freeing_King_Awowogei',
  },
  {
    id: '2316',
    label: 'Recipe for Disaster - Culinaromancer',
    points: 1,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: [],
    skillReqs: [{ skill: 'QP', level: 175 }],
    wiki: 'https://oldschool.runescape.wiki/w/Recipe_for_Disaster/Defeating_the_Culinaromancer',
  },
  {
    id: '2338',
    label: 'Secrets of the North',
    points: 2,
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert', 'Fremennik'],
    regions: [],
    prereqs: ['71', '28', '91', '59'],
    skillReqs: [
      { skill: 'Hunter', level: 56 },
      { skill: 'Thieving', level: 64 },
      { skill: 'Agility', level: 69 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Secrets_of_the_North',
  },
  {
    id: '2343',
    label: 'Desert Treasure II - The Fallen Empire',
    points: 5,
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Desert'],
    regions: [],
    prereqs: ['27', '2338', '40', '167', '180', '6', '3250'],
    skillReqs: [
      { skill: 'Firemaking', level: 75 },
      { skill: 'Magic', level: 75 },
      { skill: 'Thieving', level: 70 },
      { skill: 'Herblore', level: 62 },
      { skill: 'Runecraft', level: 60 },
      { skill: 'Construction', level: 60 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Desert_Treasure_II_-_The_Fallen_Empire',
  },
  {
    id: '3250',
    label: 'His Faithful Servants',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'miniquest',
    autoUnlockRegions: ['Desert'],
    regions: ['Morytania'],
    prereqs: ['111'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/His_Faithful_Servants',
  },
  {
    id: '3425',
    label: 'The Path of Glouphrie',
    points: 2,
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.GNOME,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Kandarin'],
    prereqs: ['45', '158', '150'],
    skillReqs: [
      { skill: 'Strength', level: 60 },
      { skill: 'Slayer', level: 56 },
      { skill: 'Thieving', level: 56 },
      { skill: 'Ranged', level: 47 },
      { skill: 'Agility', level: 45 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Path_of_Glouphrie',
  },
  {
    id: '3450',
    points: 1,
    label: 'Children of the Sun',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    series: QUEST_SERIES.TWILIGHT_EMISSARIES,
    type: 'quest',
    autoUnlockRegions: ['Varlamore'],
    regions: ['Misthalin'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Children_of_the_Sun',
    prereqs: [],
  },
  {
    id: '3451',
    label: 'Barbarian Training',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    type: 'miniquest',
    autoUnlockRegions: [],
    regions: ['Karamja', 'Kandarin'],
    prereqs: [],
    skillReqs: [
      { skill: 'Fishing', level: 55 },
      { skill: 'Firemaking', level: 35 },
      { skill: 'Strength', level: 35 },
      { skill: 'Agility', level: 15 },
      { skill: 'Farming', level: 15 },
      { skill: 'Crafting', level: 11 },
      { skill: 'Smithing', level: 5 },
      { skill: 'Herblore', level: 4 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Barbarian_Training',
  },
  {
    id: '3466',
    points: 2,
    label: 'Defender of Varrock',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Desert', 'Fremennik'],
    prereqs: ['132', '146', '6', '48', '58', '159', '121', '25'],
    skillReqs: [
      {
        skill: 'Smithing',
        level: 55,
      },
      { skill: 'Hunter', level: 52 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Defender_of_Varrock',
  },
  {
    id: '3466',
    points: 5,
    label: 'While Guthix Sleeps',
    difficulty: QUEST_DIFFICULTY.GRANDMASTER,
    length: QUEST_LENGTH.VERY_LONG,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: ['Misthalin'],
    regions: ['Misthalin'],
    prereqs: ['3466', '3425', '51', '33', '69', '156', '167', '145', '103', '142'],
    skillReqs: [
      { skill: 'Thieving', level: 72 },
      { skill: 'Magic', level: 67 },
      { skill: 'Agility', level: 66 },
      { skill: 'Farming', level: 65 },
      { skill: 'Herblore', level: 65 },
      { skill: 'Hunter', level: 62 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/While_Guthix_Sleeps',
  },
  {
    id: '3512',
    points: 1,
    label: "Twilight's Promise",
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    series: QUEST_SERIES.TWILIGHT_EMISSARIES,
    type: 'quest',
    autoUnlockRegions: ['Varlamore'],
    regions: ['Varlamore'],
    prereqs: ['3450'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Twilight%27s_Promise',
  },
  {
    id: '3513',
    points: 1,
    label: 'At First Light',
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: ['Varlamore'],
    regions: ['Varlamore'],
    prereqs: ['3450', '37'],
    skillReqs: [
      { skill: 'Hunter', level: 46 },
      { skill: 'Herblore', level: 30 },
      { skill: 'Construction', level: 27 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/At_First_Light',
  },
  {
    id: '3514',
    points: 2,
    label: 'Perilous Moons',
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Varlamore'],
    prereqs: ['3512'],
    skillReqs: [
      { skill: 'Slayer', level: 48 },
      { skill: 'Hunter', level: 20 },
      { skill: 'Fishing', level: 20 },
      { skill: 'Runecraft', level: 20 },
      { skill: 'Construction', level: 10 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Perilous_Moons',
  },
  {
    id: '3515',
    points: 1,
    label: 'The Ribbiting Tale of a Lily Pad Labour Dispute',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.VERY_SHORT,
    type: 'quest',
    autoUnlockRegions: ['Varlamore'],
    regions: ['Varlamore'],
    prereqs: ['3450'],
    skillReqs: [{ skill: 'Woodcutting', level: 15 }],
    wiki: 'https://oldschool.runescape.wiki/w/The%20Ribbiting%20Tale%20of%20a%20Lily%20Pad%20Labour%20Dispute',
  },
  {
    id: '3710',
    points: 2,
    label: 'The Heart of Darkness',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.TWILIGHT_EMISSARIES,
    type: 'quest',
    autoUnlockRegions: ['Varlamore'],
    regions: ['Varlamore'],
    prereqs: ['3512'],
    skillReqs: [{ skill: 'Woodcutting', level: 15 }],
    wiki: 'https://oldschool.runescape.wiki/w/The_Heart_of_Darkness',
  },
  {
    id: '3711',
    points: 2,
    label: 'Death on the Isle',
    difficulty: QUEST_DIFFICULTY.INTERMEDIATE,
    length: QUEST_LENGTH.MEDIUM,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Varlamore'],
    prereqs: ['3450'],
    skillReqs: [
      { skill: 'Thieving', level: 34 },
      { skill: 'Agility', level: 32 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/Death_on_the_Isle',
  },
  {
    id: '3712',
    points: 1,
    label: 'Meat and Greet',
    difficulty: QUEST_DIFFICULTY.EXPERIENCED,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Varlamore'],
    prereqs: ['3450'],
    skillReqs: [],
    wiki: 'https://oldschool.runescape.wiki/w/Meat_and_Greet',
  },
  {
    id: '3713',
    points: 1,
    label: 'Ethically Acquired Antiquities',
    difficulty: QUEST_DIFFICULTY.NOVICE,
    length: QUEST_LENGTH.SHORT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: ['Asgarnia', 'Varlamore'],
    prereqs: ['3450', '132'],
    skillReqs: [{ skill: 'Thieving', level: 25 }],
    wiki: 'https://oldschool.runescape.wiki/w/Ethically_Acquired_Antiquities',
  },
  {
    id: '3937',
    points: 2,
    label: 'The Curse of Arrav',
    difficulty: QUEST_DIFFICULTY.MASTER,
    length: QUEST_LENGTH.MEDIUM,
    series: QUEST_SERIES.MAHJARRAT,
    type: 'quest',
    autoUnlockRegions: [],
    regions: [REGION_IMPOSSIBLE_TO_COMPLETE],
    prereqs: ['3450'],
    skillReqs: [
      { skill: 'Mining', level: 64 },
      { skill: 'Ranged', level: 62 },
      { skill: 'Thieving', level: 62 },
      { skill: 'Agility', level: 61 },
      { skill: 'Strength', level: 58 },
      { skill: 'Slayer', level: 37 },
    ],
    wiki: 'https://oldschool.runescape.wiki/w/The_Curse_of_Arrav',
  },
];

function getQuestsById() {
  const questsById = {};
  quests.forEach(quest => {
    questsById[quest.id] = quest;
  });
  return questsById;
}

export const questsById = getQuestsById();

export default quests;
