import { DIARY_LOCATIONS, DIARY_DIFFICULTY } from './constants';

const DIARY_TASKS = [
  {
    id: 0,
    task: 'Check what pets you have insured with Probita in East Ardougne.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 1,
    task: 'Enter the Combat Training Camp north of West Ardougne.',
    questReqs: ['9'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 2,
    task: 'Go out fishing on the Fishing Trawler.',
    questReqs: [],
    skillReqs: [{ skill: 'Fishing', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 3,
    task: 'Have Tindel Marchant identify a rusty sword for you.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 4,
    task: 'Have Wizard Cromperty teleport you to the Rune essence mine.',
    questReqs: ['125'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 5,
    task: 'Sell silk to the Silk trader in East Ardougne for 60 coins each.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 6,
    task: 'Steal a cake from the East Ardougne market stalls.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 5 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 7,
    task: "Use the altar in East Ardougne's church.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 8,
    task: 'Use the Ardougne lever to teleport to the Wilderness.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 9,
    task: "View Aleck's Hunter Emporium in Yanille.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 10,
    task: 'Enter the unicorn pen in Ardougne Zoo using Fairy rings.',
    questReqs: ['47'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 11,
    task: "Grapple over Yanille's south wall and jump off.",
    questReqs: [],
    skillReqs: [
      { skill: 'Agility', level: 39 },
      { skill: 'Strength', level: 38 },
      { skill: 'Ranged', level: 21 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 12,
    task: 'Harvest some strawberries from the Ardougne farming patch.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 31 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 13,
    task: 'Cast the Ardougne Teleport spell.',
    questReqs: ['109'],
    skillReqs: [{ skill: 'Magic', level: 51 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 14,
    task: 'Travel to Castle Wars by Hot Air Balloon.',
    questReqs: ['42'],
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 15,
    task: 'Claim buckets of sand from Bert in Yanille.',
    questReqs: ['69'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 16,
    task: 'Catch any fish on the Fishing Platform.',
    questReqs: ['127'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 17,
    task: 'Pickpocket the master farmer north of East Ardougne.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 38 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 18,
    task: 'Collect some cave nightshade from the Skavid caves.',
    questReqs: ['157'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 19,
    task: 'Kill a swordchick in the Tower of Life.',
    questReqs: ['149'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 20,
    task: "Equip an Iban's upgraded staff or upgrade an Iban's staff.",
    questReqs: ['154'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 21,
    task: 'Visit the island east of the Necromancer Tower.',
    questReqs: ['47'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 22,
    task: "Recharge some jewellery at the Totem pole in the Legends' Guild.",
    questReqs: ['85'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 23,
    task: 'Enter the Magic Guild.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 66 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 24,
    task: 'Attempt to steal from a chest in Ardougne Castle.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 72 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 25,
    task: "Have a zookeeper put you in Ardougne Zoo's monkey cage.",
    questReqs: ['95'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 26,
    task: 'Teleport to the Watchtower .',
    questReqs: ['157'],
    skillReqs: [{ skill: 'Magic', level: 58 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 27,
    task: 'Catch a Red Salamander.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 59 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 28,
    task: 'Check the health of a palm tree near Tree Gnome Village.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 68 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 29,
    task: 'Pick some poison ivy berries from the patch south of East Ardougne.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 70 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 30,
    task: 'Smith a Mithril platebody near Ardougne.',
    questReqs: [],
    skillReqs: [{ skill: 'Smithing', level: 68 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 31,
    task: 'Enter your POH from Yanille.',
    questReqs: [],
    skillReqs: [{ skill: 'Construction', level: 50 }],
    otherReqs: ['20,000 coins'],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 32,
    task: 'Smith a Dragon sq shield in West Ardougne.',
    questReqs: [],
    skillReqs: [{ skill: 'Smithing', level: 60 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 33,
    task: 'Craft some death runes from essence.',
    questReqs: ['99'],
    skillReqs: [{ skill: 'Runecraft', level: 65 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 34,
    task: 'Successfully picklock the door to the basement of Yanille Agility dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 82 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 35,
    task: 'Cast Ice Barrage on another player within Castle Wars.',
    questReqs: ['27'],
    skillReqs: [{ skill: 'Magic', level: 94 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 36,
    task: 'Catch a manta ray in the Fishing Trawler and cook it in Port Khazard.',
    questReqs: [],
    skillReqs: [
      { skill: 'Cooking', level: 91 },
      { skill: 'Fishing', level: 81 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 37,
    task: "Complete a lap of Ardougne's rooftop agility course.",
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 90 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 38,
    task: 'Make a rune crossbow yourself from scratch within Witchaven or Yanille.',
    questReqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 10 },
      { skill: 'Smithing', level: 91 },
      { skill: 'Fletching', level: 69 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 39,
    task: 'Pick some torstol from the patch north of East Ardougne.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 85 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 40,
    task: 'Pickpocket a hero.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 80 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 41,
    task: 'Imbue a salve amulet at Nightmare Zone, or equip a salve amulet(i) that was imbued there.',
    questReqs: ['84'],
    skillReqs: [],
    otherReqs: ['800,000 Nightmare Zone points'],
    location: DIARY_LOCATIONS.ARDOUGNE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 42,
    task: 'Catch a golden warbler.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 5 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 43,
    task: 'Mine five clay in the north-eastern desert.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 44,
    task: 'Enter the Kalphite Lair.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 45,
    task: 'Enter the Desert with a set of desert robes equipped.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 46,
    task: 'Kill a vulture (can be found north-west of Agility Pyramid).',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 47,
    task: 'Have the Nardah herbalist (Zahur) clean a herb for you.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 48,
    task: 'Collect 5 potato cacti from the Kalphite Hive.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 49,
    task: 'Sell some artefacts to Simon Templeton.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 50,
    task: 'Open the sarcophagus in the first room of Pyramid Plunder.',
    questReqs: ['75'],
    skillReqs: [{ skill: 'Thieving', level: 21 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 51,
    task: 'Cut a desert cactus open to fill a waterskin.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 52,
    task: 'Travel from the Shantay Pass to Pollnivneach by magic carpet.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 53,
    task: 'Climb to the summit of the Agility Pyramid.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 30 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 54,
    task: 'Slay a desert lizard.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 22 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 55,
    task: 'Catch an orange salamander.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 47 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 56,
    task: 'Steal a Phoenix feather from the Desert phoenix.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 25 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 57,
    task: 'Travel to Uzer via magic carpet.',
    questReqs: ['65'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 58,
    task: 'Travel to the desert via the Eagle transport system.',
    questReqs: ['37'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 59,
    task: 'Pray at the Elidinis Statuette in Nardah.',
    questReqs: ['139'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 60,
    task: 'Create a combat potion in the desert.',
    questReqs: [],
    skillReqs: [{ skill: 'Herblore', level: 36 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 61,
    task: "Teleport to Enakhra's Temple with the Camulet.",
    questReqs: ['40'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 62,
    task: 'Visit the genie.',
    questReqs: ['139'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 63,
    task: 'Enter your house via the Pollnivneach portal.',
    questReqs: [],
    skillReqs: [{ skill: 'Construction', level: 20 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 64,
    task: 'Chop some teak logs near Uzer.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 35 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 65,
    task: 'Knock out and pickpocket a Menaphite Thug.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 65 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 66,
    task: 'Mine some granite.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 45 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 67,
    task: 'Refill your waterskins in the Desert using Lunar spells.',
    questReqs: ['33'],
    skillReqs: [{ skill: 'Magic', level: 68 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 68,
    task: 'Kill the Kalphite Queen.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 69,
    task: 'Complete a lap of the Pollnivneach Rooftop Course.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 70 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 70,
    task: 'Slay a Dust devil in the desert cave with a Slayer helmet equipped.',
    questReqs: ['27'],
    skillReqs: [
      { skill: 'Slayer', level: 65 },
      { skill: 'Defence', level: 10 },
    ],
    otherReqs: ['300 Slayer points'],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 71,
    task: 'Activate Ancient Magicks at the altar in the Ancient Pyramid.',
    questReqs: ['27'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 72,
    task: 'Defeat a locust rider with Keris.',
    questReqs: ['16'],
    skillReqs: [{ skill: 'Attack', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 73,
    task: "Burn some yew logs on the Nardah Mayor's balcony.",
    questReqs: [],
    skillReqs: [{ skill: 'Firemaking', level: 60 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 74,
    task: 'Create a Mithril platebody in Nardah.',
    questReqs: [],
    skillReqs: [{ skill: 'Smithing', level: 68 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 75,
    task: 'Bake a wild pie at the Nardah clay oven.',
    questReqs: [],
    skillReqs: [{ skill: 'Cooking', level: 85 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 76,
    task: 'Cast Ice Barrage against a foe in the Desert.',
    questReqs: ['27'],
    skillReqs: [{ skill: 'Magic', level: 94 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 77,
    task: 'Fletch some Dragon darts at the Bedabin Camp.',
    questReqs: [],
    skillReqs: [{ skill: 'Fletching', level: 95 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 78,
    task: 'Speak to the Kq head in your POH.',
    questReqs: ['111'],
    skillReqs: [{ skill: 'Construction', level: 78 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 79,
    task: 'Steal from the Grand Gold Chest in the final room of Pyramid Plunder.',
    questReqs: ['75'],
    skillReqs: [{ skill: 'Thieving', level: 91 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 80,
    task: 'Restore at least 85 Prayer points when praying at the altar in Sophanem.',
    questReqs: ['75'],
    skillReqs: [{ skill: 'Prayer', level: 85 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.DESERT,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 81,
    task: 'Find out what your family crest is from Sir Renitee.',
    questReqs: [],
    skillReqs: [{ skill: 'Construction', level: 16 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 82,
    task: 'Climb over the western Falador wall.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 5 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 83,
    task: "Browse Sarah's Farming Shop.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 84,
    task: 'Get a haircut from the Falador hairdresser.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 85,
    task: 'Fill a bucket from the pump north of Falador West Bank.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 86,
    task: 'Kill a duck in Falador Park.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 87,
    task: 'Make a mind tiara.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 88,
    task: 'Take the boat to Entrana.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 89,
    task: 'Repair a broken strut in the Motherlode Mine.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 90,
    task: 'Claim a security book from the Security Guard upstairs at Port Sarim jail.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 91,
    task: "Smith some Blurite limbs on Doric's anvil.",
    questReqs: ['30', '83'],
    skillReqs: [
      { skill: 'Mining', level: 10 },
      { skill: 'Smithing', level: 13 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 92,
    task: "Light a bullseye lantern at the Chemist's in Rimmington.",
    questReqs: [],
    skillReqs: [{ skill: 'Firemaking', level: 49 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 93,
    task: 'Telegrab some Wine of zamorak at the Chaos Temple just outside the Wilderness',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 33 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 94,
    task: 'Unlock the crystal chest in Taverley.',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['Crystal key'],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 95,
    task: 'Place a Scarecrow in the Falador farm flower patch.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 23 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 96,
    task: 'Kill a Mogre at Mudskipper Point.',
    questReqs: ['135'],
    skillReqs: [{ skill: 'Slayer', level: 32 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 97,
    task: 'Visit the Port Sarim Rat Pits.',
    questReqs: ['116'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 98,
    task: 'Grapple up and then jump off the north Falador wall.',
    questReqs: [],
    skillReqs: [
      { skill: 'Agility', level: 11 },
      { skill: 'Strength', level: 37 },
      { skill: 'Ranged', level: 19 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 99,
    task: 'Pickpocket a Falador guard.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 100,
    task: 'Pray at the Altar of Guthix in Taverley whilst wearing full Initiate armour.',
    questReqs: ['118'],
    skillReqs: [
      { skill: 'Prayer', level: 10 },
      { skill: 'Defence', level: 20 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 101,
    task: 'Mine some gold ore at the Crafting Guild.',
    questReqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 40 },
      { skill: 'Mining', level: 40 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 102,
    task: 'Squeeze through the crevice in the Dwarven Mines.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 42 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 103,
    task: 'Chop and burn some willow logs in Taverley.',
    questReqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 30 },
      { skill: 'Firemaking', level: 30 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 104,
    task: 'Craft a basket on the Falador farm loom.',
    questReqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 36 },
      { skill: 'Farming', level: 30 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 105,
    task: 'Teleport to Falador.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 37 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 106,
    task: 'Craft 140 mind runes simultaneously from essence.',
    questReqs: [],
    skillReqs: [{ skill: 'Runecraft', level: 56 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 107,
    task: 'Change your family crest to the Saradomin symbol.',
    questReqs: [],
    skillReqs: [{ skill: 'Prayer', level: 70 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 108,
    task: 'Kill the Giant Mole beneath Falador Park.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 109,
    task: 'Kill a Skeletal Wyvern in the Asgarnia Ice Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 72 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 110,
    task: 'Complete a lap of the Falador Rooftop Agility Course.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 111,
    task: 'Enter the Mining Guild wearing full Prospector.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 60 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 112,
    task: "Kill the blue dragon under the Heroes' Guild.",
    questReqs: ['72'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 113,
    task: "Crack a wall safe within Rogues' Den.",
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 114,
    task: 'Recharge your Prayer in the Port Sarim church while wearing full Proselyte.',
    questReqs: ['136'],
    skillReqs: [{ skill: 'Defence', level: 30 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 115,
    task: "Enter the Warriors' Guild.",
    questReqs: [],
    skillReqs: [],
    otherReqs: ['130 combined levels in  Attack and  Strength, or level 99 in either'],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 116,
    task: 'Equip a Dwarven helmet within the Dwarven Mines.',
    questReqs: ['68'],
    skillReqs: [{ skill: 'Defence', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 117,
    task: 'Craft 252 air runes simultaneously from essence.',
    questReqs: [],
    skillReqs: [{ skill: 'Runecraft', level: 88 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 118,
    task: 'Purchase a white 2h sword from Sir Vyvin.',
    questReqs: ['156'],
    skillReqs: [],
    otherReqs: ['Rank of White Knight Master (1300 black knight kills)'],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 119,
    task: 'Find at least 3 magic roots at once when digging up your magic tree in Falador (you need to cut the tree down yourself).',
    questReqs: [],
    skillReqs: [
      { skill: 'Farming', level: 91 },
      { skill: 'Woodcutting', level: 75 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 120,
    task: 'Perform a Skillcape or Quest cape emote at the top of Falador Castle.',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['Completion of all quests or level 99 in any skill'],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 121,
    task: 'Jump over the strange floor in Taverley Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 80 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 122,
    task: 'Mix a Saradomin brew in Falador East Bank.',
    questReqs: [],
    skillReqs: [{ skill: 'Herblore', level: 81 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FALADOR,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 123,
    task: 'Catch a cerulean twitch.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 11 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 124,
    task: "Change your boots at Yrsa's Shoe Store.",
    questReqs: ['57'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 125,
    task: 'Kill 5 Rock Crabs.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 126,
    task: 'Craft a tiara from scratch in Rellekka.',
    questReqs: ['57'],
    skillReqs: [
      { skill: 'Crafting', level: 23 },
      { skill: 'Mining', level: 20 },
      { skill: 'Smithing', level: 20 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 127,
    task: "Browse the Stonemason's shop.",
    questReqs: ['63'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 128,
    task: 'Collect 5 snape grass on Waterbirth Island.',
    questReqs: ['57'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 129,
    task: "Steal from the Keldagrim crafting or baker's stall.",
    questReqs: ['63'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 130,
    task: 'Fill a bucket with water at the Rellekka well.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 131,
    task: 'Enter the Troll Stronghold .',
    questReqs: ['153', '23'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 132,
    task: 'Chop and burn some oak logs in the Fremennik Province.',
    questReqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 15 },
      { skill: 'Firemaking', level: 15 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 133,
    task: 'Slay a brine rat.',
    questReqs: ['37'],
    skillReqs: [{ skill: 'Slayer', level: 47 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 134,
    task: 'Travel to the Snowy Hunter Area via Eagle.',
    questReqs: ['37'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 135,
    task: 'Mine some coal in Rellekka.',
    questReqs: ['57'],
    skillReqs: [{ skill: 'Mining', level: 30 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 136,
    task: 'Steal from the Rellekka fish stalls.',
    questReqs: ['57'],
    skillReqs: [{ skill: 'Thieving', level: 42 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 137,
    task: 'Travel to Miscellania by fairy ring.',
    questReqs: ['57', '47'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 138,
    task: 'Catch a Snowy knight.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 35 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 139,
    task: 'Pick up your pet rock from your POH menagerie.',
    questReqs: ['57'],
    skillReqs: [{ skill: 'Construction', level: 37 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 140,
    task: 'Visit the Lighthouse from Waterbirth Island.',
    questReqs: ['74', '57'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 141,
    task: 'Mine some gold at the Arzinian Mine.',
    questReqs: ['7'],
    skillReqs: [{ skill: 'Mining', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 142,
    task: 'Teleport to Trollheim.',
    questReqs: ['36'],
    skillReqs: [{ skill: 'Magic', level: 61 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 143,
    task: 'Catch a Sabre-toothed kyatt.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 55 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 144,
    task: 'Mix a Super defence potion in the Fremennik Province.',
    questReqs: [],
    skillReqs: [{ skill: 'Herblore', level: 66 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 145,
    task: 'Steal from the Keldagrim Gem Stall.',
    questReqs: ['63'],
    skillReqs: [{ skill: 'Thieving', level: 75 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 146,
    task: 'Craft a Fremennik shield on Neitiznot.',
    questReqs: ['56'],
    skillReqs: [{ skill: 'Woodcutting', level: 56 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 147,
    task: 'Mine 5 adamantite ores on Jatizso.',
    questReqs: ['56'],
    skillReqs: [{ skill: 'Mining', level: 70 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 148,
    task: 'Obtain 100% support from your kingdom subjects.',
    questReqs: ['147'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 149,
    task: 'Teleport to Waterbirth Island.',
    questReqs: ['88'],
    skillReqs: [{ skill: 'Magic', level: 72 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 150,
    task: "Obtain the Blast Furnace Foreman's permission to use the Blast Furnace for free.",
    questReqs: ['63'],
    skillReqs: [{ skill: 'Smithing', level: 60 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 151,
    task: 'Kill each of the Dagannoth Kings.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 152,
    task: 'Craft 56 astral runes at once from essence.',
    questReqs: ['88'],
    skillReqs: [{ skill: 'Runecraft', level: 82 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 153,
    task: 'Create a dragonstone amulet in the Neitiznot furnace.',
    questReqs: ['56'],
    skillReqs: [{ skill: 'Crafting', level: 80 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 154,
    task: 'Complete a lap of the Rellekka Rooftop Course.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 80 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 155,
    task: 'Kill the generals of Armadyl, Bandos, Saradomin, and Zamorak in the God Wars Dungeon.',
    questReqs: ['153'],
    skillReqs: [
      { skill: 'Agility', level: 70 },
      { skill: 'Strength', level: 70 },
      { skill: 'Hitpoints', level: 70 },
      { skill: 'Ranged', level: 70 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 156,
    task: 'Slay a Spiritual mage within the God Wars Dungeon.',
    questReqs: ['153'],
    skillReqs: [{ skill: 'Slayer', level: 83 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.FREMENNIK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 157,
    task: 'Catch a mackerel at Catherby.',
    questReqs: [],
    skillReqs: [{ skill: 'Fishing', level: 16 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 158,
    task: 'Buy a candle from the candle maker in Catherby.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 159,
    task: "Collect 5 flax from the Seers' Village flax field.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 160,
    task: "Play the Church organ in the Seers' Village church.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 161,
    task: "Plant jute seeds in the farming patch north of McGrubor's Wood.",
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 13 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 162,
    task: 'Have Galahad make you a cup of tea.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 163,
    task: 'Defeat one of each elemental in the Elemental Workshop.',
    questReqs: ['38'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 164,
    task: 'Get a pet fish from Harry in Catherby.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 165,
    task: "Buy a stew from the Seers' Village pub.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 166,
    task: "Speak to Sherlock between the Sorcerer's Tower and Keep Le Faye.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 167,
    task: 'Cross the Coal truck log shortcut.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 20 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 168,
    task: 'Complete a lap of the Barbarian agility course.',
    questReqs: ['4'],
    skillReqs: [{ skill: 'Agility', level: 35 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 169,
    task: 'Create a Superantipoison potion from scratch in the Seers/Catherby area.',
    questReqs: [],
    skillReqs: [{ skill: 'Herblore', level: 48 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 170,
    task: 'Enter the Ranging Guild.',
    questReqs: [],
    skillReqs: [{ skill: 'Ranged', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 171,
    task: 'Use the grapple shortcut to get from the water obelisk to Catherby shore.',
    questReqs: [],
    skillReqs: [
      { skill: 'Agility', level: 36 },
      { skill: 'Strength', level: 22 },
      { skill: 'Ranged', level: 39 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 172,
    task: 'Catch and cook a bass in Catherby.',
    questReqs: [],
    skillReqs: [
      { skill: 'Fishing', level: 46 },
      { skill: 'Cooking', level: 43 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 173,
    task: 'Teleport to Camelot.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 45 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 174,
    task: "String a maple shortbow in Seers' Village bank.",
    questReqs: [],
    skillReqs: [{ skill: 'Fletching', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 175,
    task: 'Pick some limpwurt root from the farming patch in Catherby.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 26 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 176,
    task: 'Create a mind helmet.',
    questReqs: ['39'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 177,
    task: 'Kill a fire giant in the Waterfall Dungeon.',
    questReqs: ['158'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 178,
    task: 'Complete a wave of Barbarian Assault.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 179,
    task: 'Steal from the chest in Hemenster.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 47 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 180,
    task: "Travel to McGrubor's Wood by Fairy ring.",
    questReqs: ['47'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 181,
    task: 'Mine some coal near the coal trucks.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 30 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 182,
    task: 'Catch a leaping sturgeon.',
    questReqs: [],
    skillReqs: [
      { skill: 'Fishing', level: 70 },
      { skill: 'Agility', level: 45 },
      { skill: 'Strength', level: 45 },
    ],
    otherReqs: ['Access to Barbarian Fishing'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 183,
    task: "Complete a lap of the Seers' Village Rooftop Course.",
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 60 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 184,
    task: "Create a yew longbow from scratch around Seers' Village.",
    questReqs: [],
    skillReqs: [
      { skill: 'Fletching', level: 70 },
      { skill: 'Woodcutting', level: 60 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 185,
    task: "Enter the Seers' Village courthouse with Piety turned on.",
    questReqs: ['82'],
    skillReqs: [
      { skill: 'Prayer', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    otherReqs: ['Knight Waves Training Grounds'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 186,
    task: 'Charge a water orb.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 56 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 187,
    task: "Burn some maple logs with a bow in Seers' Village.",
    questReqs: [],
    skillReqs: [{ skill: 'Firemaking', level: 65 }],
    otherReqs: ['Access to Barbarian Firemaking'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 188,
    task: 'Kill a shadow hound in the Shadow Dungeon.',
    questReqs: ['27'],
    skillReqs: [{ skill: 'Thieving', level: 53 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 189,
    task: 'Kill a Mithril dragon.',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['Access to Barbarian Firemaking'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 190,
    task: 'Purchase and equip a granite body from Barbarian Assault.',
    questReqs: [],
    skillReqs: [
      { skill: 'Strength', level: 50 },
      { skill: 'Defence', level: 50 },
    ],
    otherReqs: ['95,000 coins'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 191,
    task: "Have the Seers' Village estate agent decorate your house with Fancy Stone.",
    questReqs: [],
    skillReqs: [{ skill: 'Construction', level: 50 }],
    otherReqs: ['25,000 coins'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 192,
    task: "Smith an adamant spear at Otto's Grotto.",
    questReqs: [],
    skillReqs: [{ skill: 'Smithing', level: 75 }],
    otherReqs: ['Access to Barbarian Smithing'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 193,
    task: 'Read the blackboard at Barbarian Assault after reaching level 5 in every role',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['1400 honour points in each role'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 194,
    task: 'Pick some dwarf weed from the herb patch at Catherby.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 79 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 195,
    task: 'Fish and cook 5 sharks in Catherby using the Cooking gauntlets.',
    questReqs: ['48'],
    skillReqs: [
      { skill: 'Fishing', level: 76 },
      { skill: 'Cooking', level: 80 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 196,
    task: "Mix a Stamina mix on top of the Seers' Village bank.",
    questReqs: [],
    skillReqs: [
      { skill: 'Herblore', level: 86 },
      { skill: 'Agility', level: 60 },
    ],
    otherReqs: ['Access to Barbarian Herblore'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 197,
    task: "Smith a rune hasta at Otto's Grotto.",
    questReqs: [],
    skillReqs: [{ skill: 'Smithing', level: 90 }],
    otherReqs: ['Access to Barbarian Smithing'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 198,
    task: 'Construct a pyre ship from magic logs.',
    questReqs: [],
    skillReqs: [
      { skill: 'Firemaking', level: 85 },
      { skill: 'Crafting', level: 85 },
    ],
    otherReqs: ['Access to Barbarian Firemaking', 'Chewed bones'],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 199,
    task: 'Teleport to Catherby',
    questReqs: ['88'],
    skillReqs: [{ skill: 'Magic', level: 87 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KANDARIN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 200,
    task: 'Pick 5 bananas from the plantation located east of the volcano.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 201,
    task: 'Use the rope swing to travel to the Moss Giant Island north-west of Karamja.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 10 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 202,
    task: 'Mine some gold from the rocks on the north-west peninsula of Karamja.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 203,
    task: 'Travel to Port Sarim via the dock, east of Musa Point.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 204,
    task: 'Travel to Ardougne via the port near Brimhaven.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 205,
    task: 'Explore Cairn Isle to the west of Karamja.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 206,
    task: 'Use the fishing spots north of the banana plantation.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 207,
    task: 'Collect 5 seaweed from anywhere on Karamja.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 208,
    task: 'Attempt the TzHaar Fight Pits or Fight Cave.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 209,
    task: 'Kill a jogre in the Pothole Dungeon.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 210,
    task: 'Claim a ticket from the Agility arena in Brimhaven.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 211,
    task: 'Discover hidden wall in the dungeon below the volcano.',
    questReqs: ['31'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 212,
    task: 'Visit the Isle of Crandor via the dungeon below the volcano.',
    questReqs: ['31'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 213,
    task: "Use Vigroy and Hajedy's cart service.",
    questReqs: ['133'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 214,
    task: 'Earn 100% favour in the Tai Bwo Wannai Cleanup minigame.',
    questReqs: ['80'],
    skillReqs: [{ skill: 'Woodcutting', level: 10 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 215,
    task: 'Cook a Spider on stick.',
    questReqs: [],
    skillReqs: [{ skill: 'Cooking', level: 16 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 216,
    task: 'Charter the Lady of the Waves from south of Cairn Isle to Port Khazard.',
    questReqs: ['133'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 217,
    task: 'Cut a log from a teak tree.',
    questReqs: ['80'],
    skillReqs: [{ skill: 'Woodcutting', level: 35 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 218,
    task: 'Cut a log from a mahogany tree.',
    questReqs: ['80'],
    skillReqs: [{ skill: 'Woodcutting', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 219,
    task: 'Catch a karambwan.',
    questReqs: ['141'],
    skillReqs: [{ skill: 'Fishing', level: 65 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 220,
    task: 'Exchange gems for a machete.',
    questReqs: ['80'],
    skillReqs: [],
    otherReqs: ['Gout tuber'],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 221,
    task: 'Use the gnome glider to travel to Karamja.',
    questReqs: ['66'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 222,
    task: 'Grow a healthy fruit tree in the patch near Brimhaven.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 27 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 223,
    task: 'Trap a Horned Graahk.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 41 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 224,
    task: 'Chop the vines to gain deeper access to Brimhaven Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 10 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 225,
    task: 'Cross the lava using the stepping stones within Brimhaven Dungeon.',
    questReqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 10 },
      { skill: 'Agility', level: 12 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 226,
    task: 'Climb the stairs within Brimhaven Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 10 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 227,
    task: 'Charter a ship from the shipyard in the far east of Karamja.',
    questReqs: ['66'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 228,
    task: 'Mine a red topaz from a gem rock.',
    questReqs: ['80'],
    skillReqs: [{ skill: 'Mining', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 229,
    task: 'Become the champion of the Fight Pit.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 230,
    task: 'Kill a Ket-Zek in the Fight Caves.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 231,
    task: 'Eat an oomlie wrap.',
    questReqs: [],
    skillReqs: [{ skill: 'Cooking', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 232,
    task: 'Craft some nature runes from essence.',
    questReqs: [],
    skillReqs: [{ skill: 'Runecraft', level: 44 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 233,
    task: 'Cook a raw karambwan thoroughly.',
    questReqs: ['141'],
    skillReqs: [{ skill: 'Cooking', level: 30 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 234,
    task: 'Kill a deathwing in the dungeon under the Kharazi Jungle.',
    questReqs: ['85'],
    skillReqs: [
      { skill: 'Woodcutting', level: 15 },
      { skill: 'Strength', level: 50 },
      { skill: 'Agility', level: 50 },
      { skill: 'Thieving', level: 50 },
      { skill: 'Mining', level: 52 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 235,
    task: 'Use the crossbow shortcut south of the volcano.',
    questReqs: [],
    skillReqs: [
      { skill: 'Agility', level: 53 },
      { skill: 'Ranged', level: 42 },
      { skill: 'Strength', level: 21 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 236,
    task: 'Collect 5 palm leaves.',
    questReqs: ['85'],
    skillReqs: [{ skill: 'Woodcutting', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 237,
    task: 'Be assigned a Slayer task by Duradel in Shilo Village .',
    questReqs: ['133'],
    skillReqs: [],
    otherReqs: ['100 Combat level and 50 Slayer, or 99 Slayer'],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 238,
    task: 'Kill a metal dragon in Brimhaven Dungeon.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 239,
    task: 'Craft 56 nature runes at once from essence.',
    questReqs: [],
    skillReqs: [{ skill: 'Runecraft', level: 91 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 240,
    task: 'Equip a fire cape or infernal cape in the Tzhaar city.',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['Fire cape or infernal cape'],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 241,
    task: 'Check the health of a palm tree in Brimhaven.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 68 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 242,
    task: 'Create an antivenom potion whilst standing in the horse shoe mine.',
    questReqs: [],
    skillReqs: [{ skill: 'Herblore', level: 87 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 243,
    task: 'Check the health of your Calquat tree patch.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 72 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KARAMJA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 244,
    task: 'Mine some iron ore at the Mount Karuulm mine.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 245,
    task: 'Kill a Sand Crab.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 246,
    task: 'Hand in a book at the Arceuus Library.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 247,
    task: 'Steal from a Hosidius Fruit Stall.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 25 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 248,
    task: 'Browse the Warrens General Store.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 249,
    task: "Take a boat to Land's End.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 250,
    task: 'Pray at the altar on the top floor of the Kourend Castle.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 251,
    task: 'Dig up some saltpetre.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 252,
    task: 'Enter your player-owned house from Hosidius.',
    questReqs: [],
    skillReqs: [{ skill: 'Construction', level: 25 }],
    otherReqs: ['8,750 coins'],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 253,
    task: 'Do a lap of either tier of the Shayzien Agility Course.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 10 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 254,
    task: 'Create a strength potion in the Lovakengj pub.',
    questReqs: ['34'],
    skillReqs: [{ skill: 'Herblore', level: 12 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 255,
    task: 'Fish a Trout from the River Molch.',
    questReqs: [],
    skillReqs: [{ skill: 'Fishing', level: 20 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 256,
    task: 'Travel to the fairy ring south of Mount Karuulm.',
    questReqs: ['47'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 257,
    task: 'Kill a lizardman.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 258,
    task: "Use Kharedst's memoirs to teleport to all five cities in Great Kourend.",
    questReqs: ['26', '113', '143', '54', '3'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 259,
    task: 'Mine some volcanic sulphur.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 42 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 260,
    task: 'Enter the Farming Guild.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 45 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 261,
    task: 'Switch to the Arceuus spellbook via Tyss.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 262,
    task: 'Repair a crane within Port Piscarilius.',
    questReqs: [],
    skillReqs: [{ skill: 'Crafting', level: 30 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 263,
    task: 'Deliver some intelligence to Captain Ginea.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 264,
    task: 'Catch a Bluegill on Lake Molch.',
    questReqs: [],
    skillReqs: [
      { skill: 'Fishing', level: 43 },
      { skill: 'Hunter', level: 35 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 265,
    task: 'Use the boulder leap shortcut in the dense essence mine.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 49 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 266,
    task: 'Subdue the Wintertodt.',
    questReqs: [],
    skillReqs: [{ skill: 'Firemaking', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 267,
    task: 'Catch a chinchompa in the Kourend Woodland.',
    questReqs: ['37'],
    skillReqs: [{ skill: 'Hunter', level: 53 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 268,
    task: 'Chop some mahogany logs north of the Farming Guild.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 269,
    task: 'Enter the Woodcutting Guild.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 60 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 270,
    task: 'Smelt an adamantite bar in The Forsaken Tower .',
    questReqs: ['54'],
    skillReqs: [{ skill: 'Smithing', level: 70 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 271,
    task: 'Kill a lizardman shaman in the Lizardman Temple.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 272,
    task: 'Mine some Lovakite ore.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 65 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 273,
    task: 'Plant some Logavano seeds at the Tithe Farm.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 74 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 274,
    task: 'Kill a zombie in the Shayzien Crypts.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 275,
    task: "Teleport to Xeric's Heart using Xeric's talisman.",
    questReqs: [],
    skillReqs: [],
    otherReqs: ["Xeric's talisman"],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 276,
    task: 'Deliver an artefact to Captain Khaled.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 49 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 277,
    task: 'Kill a wyrm in the Karuulm Slayer Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 62 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 278,
    task: 'Cast Monster Examine on a mountain troll south of Mount Quidamortem.',
    questReqs: ['33'],
    skillReqs: [{ skill: 'Magic', level: 66 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 279,
    task: 'Craft one or more blood runes from essence.',
    questReqs: [],
    skillReqs: [
      { skill: 'Runecraft', level: 77 },
      { skill: 'Mining', level: 38 },
      { skill: 'Crafting', level: 38 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 280,
    task: 'Chop some redwood logs.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 90 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 281,
    task: 'Defeat Skotizo in the Catacombs of Kourend.',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['Dark totem'],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 282,
    task: 'Catch an anglerfish and cook it whilst in Great Kourend.',
    questReqs: [],
    skillReqs: [
      { skill: 'Fishing', level: 82 },
      { skill: 'Cooking', level: 84 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 283,
    task: 'Kill a hydra in the Karuulm Slayer Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 95 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 284,
    task: 'Create an Ape Atoll teleport tablet.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 90 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 285,
    task: 'Complete a raid in the Chambers of Xeric.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 286,
    task: 'Create your own battlestaff from scratch within the Farming Guild.',
    questReqs: [],
    skillReqs: [
      { skill: 'Farming', level: 85 },
      { skill: 'Fletching', level: 40 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.KOUREND,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 287,
    task: 'Complete a lap of the Draynor Village Rooftop Course.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 10 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 288,
    task: 'Slay a cave bug in the Lumbridge Swamp Caves.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 7 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 289,
    task: 'Have Archmage Sedridor teleport you to the Rune essence mine.',
    questReqs: ['125'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 290,
    task: 'Craft some water runes.',
    questReqs: ['125'],
    skillReqs: [{ skill: 'Runecraft', level: 5 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 291,
    task: 'Learn your age from Hans in Lumbridge.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 292,
    task: 'Pickpocket a man or woman in Lumbridge.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 293,
    task: 'Chop and burn some oak logs in Lumbridge.',
    questReqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 15 },
      { skill: 'Firemaking', level: 15 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 294,
    task: 'Kill a zombie in the Draynor Sewers.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 295,
    task: 'Catch some anchovies in Al-Kharid.',
    questReqs: [],
    skillReqs: [{ skill: 'Fishing', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 296,
    task: 'Bake some bread on the Lumbridge castle kitchen range.',
    questReqs: ['17'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 297,
    task: 'Mine some iron ore at the Al-Kharid mine.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 298,
    task: 'Enter the H.A.M. Hideout.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 299,
    task: 'Complete a lap of the Al Kharid Rooftop Course.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 20 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 300,
    task: 'Grapple across the River Lum.',
    questReqs: [],
    skillReqs: [
      { skill: 'Agility', level: 8 },
      { skill: 'Strength', level: 19 },
      { skill: 'Ranged', level: 37 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 301,
    task: 'Purchase an upgraded device from Ava.',
    questReqs: ['0'],
    skillReqs: [{ skill: 'Ranged', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 302,
    task: "Travel to the Wizards' Tower by Fairy ring.",
    questReqs: ['47'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 303,
    task: 'Cast the Lumbridge Teleport spell.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 31 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 304,
    task: 'Catch some salmon in Lumbridge.',
    questReqs: [],
    skillReqs: [{ skill: 'Fishing', level: 30 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 305,
    task: 'Craft a coif in the Lumbridge cow pen.',
    questReqs: [],
    skillReqs: [{ skill: 'Crafting', level: 38 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 306,
    task: 'Chop some willow logs in Draynor Village.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 30 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 307,
    task: 'Pickpocket Martin the Master Gardener.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 38 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 308,
    task: 'Get a Slayer task from Chaeldar.',
    questReqs: ['86'],
    skillReqs: [{ skill: 'Combat', level: 70 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 309,
    task: 'Catch an essence or eclectic impling in Puro-Puro.',
    questReqs: ['86'],
    skillReqs: [{ skill: 'Hunter', level: 42 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 310,
    task: 'Craft some lava runes at the Fire Altar in Al Kharid.',
    questReqs: [],
    skillReqs: [{ skill: 'Runecraft', level: 23 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 311,
    task: 'Cast Bones to Peaches in Al Kharid Palace.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 60 }],
    otherReqs: ['Unlocked Bones to Peaches via the Mage Training Arena'],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 312,
    task: 'Squeeze past the jutting wall on your way to the Cosmic Altar.',
    questReqs: ['86'],
    skillReqs: [{ skill: 'Agility', level: 46 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 313,
    task: 'Craft 56 cosmic runes simultaneously from essence.',
    questReqs: ['86'],
    skillReqs: [{ skill: 'Runecraft', level: 59 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 314,
    task: 'Travel from Lumbridge to Edgeville on a waka canoe.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 57 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 315,
    task: 'Collect at least 100 Tears of Guthix  in one visit.',
    questReqs: ['145'],
    skillReqs: [],
    otherReqs: ['Recommended: at least 150 Quest Points'],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 316,
    task: 'Take the train from Dorgesh-Kaan to Keldagrim.',
    questReqs: ['1'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 317,
    task: "Purchase some Barrows gloves from the Culinaromancer's Chest.",
    questReqs: ['117'],
    skillReqs: [],
    otherReqs: ['130,000 coins'],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 318,
    task: 'Pick some belladonna from the farming patch at Draynor Manor.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 63 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 319,
    task: 'Light your mining helmet in the Lumbridge Castle basement.',
    questReqs: [],
    skillReqs: [{ skill: 'Firemaking', level: 65 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 320,
    task: "Recharge your prayer at Emir's Arena with Smite activated.",
    questReqs: [],
    skillReqs: [{ skill: 'Prayer', level: 52 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 321,
    task: 'Craft, string and enchant an amulet of power in Lumbridge.',
    questReqs: [],
    skillReqs: [
      { skill: 'Crafting', level: 70 },
      { skill: 'Magic', level: 57 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 322,
    task: 'Steal from a Dorgesh-Kaan rich chest.',
    questReqs: ['24'],
    skillReqs: [{ skill: 'Thieving', level: 78 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 323,
    task: 'Pickpocket Movario on the Dorgesh-Kaan Agility Course.',
    questReqs: ['24', '146'],
    skillReqs: [
      { skill: 'Thieving', level: 42 },
      { skill: 'Agility', level: 70 },
      { skill: 'Ranged', level: 70 },
      { skill: 'Strength', level: 70 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 324,
    task: 'Chop some magic logs at the Mage Training Arena.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 75 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 325,
    task: 'Smith an adamant platebody down in Draynor Sewer.',
    questReqs: [],
    skillReqs: [{ skill: 'Smithing', level: 88 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 326,
    task: 'Craft 140 or more water runes at once from essence.',
    questReqs: [],
    skillReqs: [{ skill: 'Runecraft', level: 76 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 327,
    task: "Perform the Quest point cape emote in the Wise Old Man's house.",
    questReqs: [],
    skillReqs: [],
    otherReqs: ['All quests completed'],
    location: DIARY_LOCATIONS.LUMBRIDGE,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 328,
    task: 'Craft any snelm from scratch in Morytania.',
    questReqs: [],
    skillReqs: [{ skill: 'Crafting', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 329,
    task: 'Cook a thin snail on the Port Phasmatys range.',
    questReqs: [],
    skillReqs: [{ skill: 'Cooking', level: 12 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 330,
    task: 'Get a Slayer task from Mazchna.',
    questReqs: [],
    skillReqs: [{ skill: 'Combat', level: 20 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 331,
    task: 'Kill a banshee in the Slayer Tower.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 332,
    task: 'Have Sbott tan something for you.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 333,
    task: 'Enter Mort Myre Swamp.',
    questReqs: ['103'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 334,
    task: 'Kill a ghoul.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 335,
    task: 'Place a Scarecrow in the Morytania flower patch.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 47 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 336,
    task: 'Offer some Bonemeal at the Ectofuntus.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 337,
    task: 'Kill a Werewolf in its human form using the Wolfbane dagger.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 338,
    task: 'Restore your prayer points at the nature altar.',
    questReqs: ['103'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 339,
    task: 'Catch a swamp lizard.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 29 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 340,
    task: 'Complete a lap of the Canifis Rooftop Agility Course.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 341,
    task: 'Obtain some bark from a Hollow tree.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 45 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 342,
    task: 'Travel to Dragontooth Isle.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 343,
    task: 'Kill a Terror Dog.',
    questReqs: ['70', '84'],
    skillReqs: [{ skill: 'Slayer', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 344,
    task: 'Complete a game of trouble brewing.',
    questReqs: ['12'],
    skillReqs: [{ skill: 'Cooking', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 345,
    task: 'Board the Swamp boaty at the Hollows.',
    questReqs: ['103'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 346,
    task: 'Make a batch of cannonballs at the Port Phasmatys furnace.',
    questReqs: ['35'],
    skillReqs: [{ skill: 'Smithing', level: 35 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 347,
    task: 'Kill a fever spider on Braindeath Island.',
    questReqs: ['124'],
    skillReqs: [{ skill: 'Slayer', level: 42 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 348,
    task: 'Use an ectophial to return to Port Phasmatys.',
    questReqs: ['62'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 349,
    task: 'Mix a Guthix balance potion while in Morytania.',
    questReqs: ['77'],
    skillReqs: [{ skill: 'Herblore', level: 22 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 350,
    task: 'Enter the Kharyrll portal in your POH.',
    questReqs: ['27'],
    skillReqs: [{ skill: 'Magic', level: 66 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 351,
    task: 'Climb the advanced spike chain within Slayer Tower.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 71 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 352,
    task: 'Harvest some Watermelon from the allotment patch on Harmony Island.',
    questReqs: ['67'],
    skillReqs: [{ skill: 'Farming', level: 47 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 353,
    task: "Chop and burn some mahogany logs on Mos Le'Harmless.",
    questReqs: ['12'],
    skillReqs: [
      { skill: 'Woodcutting', level: 50 },
      { skill: 'Firemaking', level: 50 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 354,
    task: 'Complete a temple trek with a hard companion.',
    questReqs: ['77'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 355,
    task: 'Kill a Cave Horror.',
    questReqs: ['12'],
    skillReqs: [{ skill: 'Slayer', level: 58 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 356,
    task: 'Harvest some Bittercap Mushrooms from the patch in Canifis.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 53 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 357,
    task: 'Pray at the Altar of Nature with Piety activated.',
    questReqs: ['103'],
    skillReqs: [
      { skill: 'Prayer', level: 70 },
      { skill: 'Defence', level: 70 },
    ],
    otherReqs: ['Knight Waves Training Grounds'],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 358,
    task: 'Use the shortcut to get to the bridge over the Salve.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 65 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 359,
    task: 'Mine some mithril ore in the Abandoned Mine.',
    questReqs: ['70'],
    skillReqs: [{ skill: 'Mining', level: 55 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 360,
    task: 'Catch a shark in Burgh de Rott with your bare hands.',
    questReqs: ['77'],
    skillReqs: [
      { skill: 'Fishing', level: 96 },
      { skill: 'Strength', level: 76 },
    ],
    otherReqs: ['Access to Barbarian Fishing'],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 361,
    task: 'Cremate any Shade remains on a Magic or Redwood pyre.',
    questReqs: ['128'],
    skillReqs: [{ skill: 'Firemaking', level: 80 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 362,
    task: 'Fertilize the Morytania herb patch using Lunar Magic.',
    questReqs: ['88'],
    skillReqs: [{ skill: 'Magic', level: 83 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 363,
    task: 'Craft a Black dragonhide body in the Canifis bank.',
    questReqs: [],
    skillReqs: [{ skill: 'Crafting', level: 84 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 364,
    task: 'Kill an Abyssal demon in the Slayer Tower.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 85 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 365,
    task: 'Loot the Barrows chest while wearing any complete barrows set.',
    questReqs: [],
    skillReqs: [{ skill: 'Defence', level: 70 }],
    otherReqs: ['Various other skill requirements depending on the set used'],
    location: DIARY_LOCATIONS.MORYTANIA,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 366,
    task: "Browse Thessalia's store.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 367,
    task: 'Have Aubury teleport you to the essence mine.',
    questReqs: ['125'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 368,
    task: 'Mine some iron in the south-east Varrock mine.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 369,
    task: 'Make a normal plank at the Sawmill.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 370,
    task: 'Enter the second level of the Stronghold of Security.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 371,
    task: 'Jump over the fence south of Varrock.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 13 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 372,
    task: 'Chop down a dying tree in the Lumber Yard.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 373,
    task: 'Buy a newspaper.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 374,
    task: 'Give a dog a bone!',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 375,
    task: 'Spin a bowl on the pottery wheel and fire it in the oven in Barbarian Village.',
    questReqs: [],
    skillReqs: [{ skill: 'Crafting', level: 8 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 376,
    task: 'Speak to Haig Halen after obtaining at least 50 Kudos.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 377,
    task: 'Craft some earth runes from Essence.',
    questReqs: ['125'],
    skillReqs: [{ skill: 'Runecraft', level: 9 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 378,
    task: 'Catch some trout in the River Lum at Barbarian Village.',
    questReqs: [],
    skillReqs: [{ skill: 'Fishing', level: 20 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 379,
    task: 'Steal from the tea stall in Varrock.',
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 5 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 380,
    task: 'Have the Apothecary in Varrock make you a Strength potion.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 381,
    task: "Enter the Champions' Guild.",
    questReqs: [],
    skillReqs: [],
    otherReqs: ['32 Quest points'],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 382,
    task: 'Select a colour for your kitten.',
    questReqs: ['60', '58'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 383,
    task: 'Use the Spirit tree in the north-eastern corner of Grand Exchange.',
    questReqs: ['150'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 384,
    task: 'Perform the 4 emotes from the Stronghold of Security.',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['Enabled the RuneScape Authenticator'],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 385,
    task: "Enter the Tolna dungeon after completing A Soul's Bane .",
    questReqs: ['138'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 386,
    task: 'Teleport to the digsite using a Digsite pendant.',
    questReqs: ['29'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 387,
    task: 'Cast the teleport to Varrock spell.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 25 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 388,
    task: 'Get a Slayer task from Vannaka.',
    questReqs: [],
    skillReqs: [{ skill: 'Combat', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 389,
    task: 'Make 20 mahogany planks in one go (at the Lumber Yard).',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 390,
    task: 'Pick a white tree fruit.',
    questReqs: ['58'],
    skillReqs: [{ skill: 'Farming', level: 25 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 391,
    task: 'Use the balloon to travel from Varrock.',
    questReqs: ['42'],
    skillReqs: [
      { skill: 'Farming', level: 30 },
      { skill: 'Firemaking', level: 40 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 392,
    task: 'Complete a lap of the Varrock Rooftop Course.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 30 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 393,
    task: 'Trade furs with the Fancy Dress Seller for a Spottier cape and equip it.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 69 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 394,
    task: 'Speak to Orlando Smith when you have achieved 153 Kudos.',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['Various requirements to earn Kudos'],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 395,
    task: 'Make a Waka canoe near Edgeville.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 57 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 396,
    task: 'Teleport to Paddewwa.',
    questReqs: ['27'],
    skillReqs: [{ skill: 'Magic', level: 54 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 397,
    task: 'Teleport to Barbarian Village with a Skull sceptre.',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['Skull sceptre'],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 398,
    task: 'Chop some yew logs in Varrock and burn them at the top of the Varrock church.',
    questReqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 60 },
      { skill: 'Firemaking', level: 60 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 399,
    task: 'Have the Varrock estate agent decorate your house with Fancy Stone (complete the dialogue).',
    questReqs: [],
    skillReqs: [{ skill: 'Construction', level: 50 }],
    otherReqs: ['25,000 coins'],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 400,
    task: 'Collect at least 2 yew roots from the tree patch in Varrock Palace.',
    questReqs: [],
    skillReqs: [
      { skill: 'Farming', level: 68 },
      { skill: 'Woodcutting', level: 60 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 401,
    task: 'Pray at the altar in Varrock Palace (2nd floor[US]) with Smite active.',
    questReqs: [],
    skillReqs: [{ skill: 'Prayer', level: 52 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 402,
    task: 'Squeeze through the obstacle pipe in Edgeville Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 51 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 403,
    task: 'Create a Super combat potion in Varrock West Bank.',
    questReqs: [],
    skillReqs: [{ skill: 'Herblore', level: 90 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 404,
    task: 'Use Lunar magic to make 20 mahogany planks in the Varrock Lumber Yard (inside the fences).',
    questReqs: ['33'],
    skillReqs: [{ skill: 'Magic', level: 86 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 405,
    task: 'Bake a summer pie in the Cooking Guild.',
    questReqs: [],
    skillReqs: [{ skill: 'Cooking', level: 95 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 406,
    task: 'Smith and fletch ten rune darts within Varrock.',
    questReqs: ['148'],
    skillReqs: [
      { skill: 'Smithing', level: 89 },
      { skill: 'Fletching', level: 81 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 407,
    task: 'Craft 100 or more earth runes simultaneously from essence.',
    questReqs: [],
    skillReqs: [{ skill: 'Runecraft', level: 78 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.VARROCK,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 408,
    task: 'Catch a copper longtail.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 9 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 409,
    task: 'Complete a novice game of Pest Control.',
    questReqs: [],
    skillReqs: [{ skill: 'Combat', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 410,
    task: 'Mine some iron ore near Piscatoris.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 411,
    task: 'Complete a lap of the Gnome Agility Course.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 412,
    task: 'Score a goal in a Gnome Ball match.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 413,
    task: 'Claim any Chompy bird hat from Rantz.',
    questReqs: ['8'],
    skillReqs: [],
    otherReqs: ['30 chompy bird kills'],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 414,
    task: 'Teleport to Pest Control using the Minigame teleports.',
    questReqs: [],
    skillReqs: [{ skill: 'Combat', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 415,
    task: 'Collect a swamp toad at the Gnome Stronghold.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 416,
    task: 'Have Brimstail teleport you to the Essence mine.',
    questReqs: ['125'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 417,
    task: 'Fletch an oak shortbow in the Gnome Stronghold.',
    questReqs: [],
    skillReqs: [{ skill: 'Fletching', level: 20 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 418,
    task: 'Kill a terrorbird in the terrorbird enclosure.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 419,
    task: "Take the agility shortcut from the Grand Tree to Otto's Grotto.",
    questReqs: ['150', '66'],
    skillReqs: [{ skill: 'Agility', level: 37 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 420,
    task: 'Travel to the Gnome Stronghold by Spirit tree.',
    questReqs: ['150'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 421,
    task: 'Trap a Spined larupia.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 31 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 422,
    task: 'Fish some bass on Ape Atoll.',
    questReqs: ['95'],
    skillReqs: [{ skill: 'Fishing', level: 46 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 423,
    task: 'Chop and burn some teak logs on Ape Atoll.',
    questReqs: ['95'],
    skillReqs: [
      { skill: 'Woodcutting', level: 35 },
      { skill: 'Firemaking', level: 35 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 424,
    task: 'Complete an intermediate game of Pest Control.',
    questReqs: [],
    skillReqs: [{ skill: 'Combat', level: 70 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 425,
    task: 'Travel to the Feldip Hills by Gnome glider.',
    questReqs: ['66', '107'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 426,
    task: 'Claim a Chompy bird hat from Rantz after registering at least 125 kills.',
    questReqs: ['8'],
    skillReqs: [],
    otherReqs: ['125 chompy bird kills'],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 427,
    task: "Travel from Eagles' Peak  to the Feldip Hills by Eagle.",
    questReqs: ['37'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 428,
    task: 'Make a Chocolate bomb at the Grand Tree.',
    questReqs: [],
    skillReqs: [{ skill: 'Cooking', level: 42 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 429,
    task: 'Complete a delivery for the Gnome Restaurant.',
    questReqs: [],
    skillReqs: [{ skill: 'Cooking', level: 42 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 430,
    task: 'Turn your crystal saw seed into a crystal saw.',
    questReqs: ['45'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 431,
    task: 'Mine some gold ore underneath the Grand Tree.',
    questReqs: ['66'],
    skillReqs: [{ skill: 'Mining', level: 40 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 432,
    task: 'Kill an elf with a crystal bow.',
    questReqs: ['122'],
    skillReqs: [{ skill: 'Ranged', level: 70 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 433,
    task: 'Catch and cook a monkfish in the Piscatoris Fishing Colony.',
    questReqs: ['140'],
    skillReqs: [
      { skill: 'Fishing', level: 62 },
      { skill: 'Cooking', level: 62 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 434,
    task: 'Complete a veteran game of Pest Control.',
    questReqs: [],
    skillReqs: [{ skill: 'Combat', level: 100 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 435,
    task: 'Catch a dashing kebbit.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 69 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 436,
    task: 'Complete a lap of the Ape Atoll Agility Course.',
    questReqs: ['95'],
    skillReqs: [{ skill: 'Agility', level: 48 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 437,
    task: 'Chop and burn some mahogany logs on Ape Atoll.',
    questReqs: ['95'],
    skillReqs: [
      { skill: 'Woodcutting', level: 50 },
      { skill: 'Firemaking', level: 50 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 438,
    task: 'Mine some adamantite ore in Tirannwn.',
    questReqs: ['119'],
    skillReqs: [{ skill: 'Mining', level: 70 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 439,
    task: 'Check the health of your palm tree in Lletya.',
    questReqs: ['99'],
    skillReqs: [{ skill: 'Farming', level: 68 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 440,
    task: 'Claim a Chompy bird hat from Rantz after registering at least 300 kills.',
    questReqs: ['8'],
    skillReqs: [],
    otherReqs: ['300 chompy bird kills'],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 441,
    task: 'Build an Isafdar painting in your POH Quest Hall.',
    questReqs: ['122'],
    skillReqs: [{ skill: 'Construction', level: 65 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 442,
    task: 'Kill Zulrah.',
    questReqs: ['119'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 443,
    task: 'Teleport to Ape Atoll.',
    questReqs: ['2315'],
    skillReqs: [{ skill: 'Magic', level: 64 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 444,
    task: 'Pickpocket a gnome.',
    questReqs: ['150'],
    skillReqs: [{ skill: 'Thieving', level: 75 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 445,
    task: 'Fletch a magic longbow in Tirannwn.',
    questReqs: ['119'],
    skillReqs: [{ skill: 'Fletching', level: 85 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 446,
    task: 'Kill the Thermonuclear smoke devil.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 93 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 447,
    task: 'Have Prissy Scilla protect your magic tree.',
    questReqs: [],
    skillReqs: [{ skill: 'Farming', level: 75 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 448,
    task: 'Use the advanced elven overpass cliffside shortcut.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 85 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 449,
    task: 'Equip any complete void set.',
    questReqs: [],
    skillReqs: [
      { skill: 'Combat', level: 40 },
      { skill: 'Prayer', level: 22 },
      { skill: 'Attack', level: 42 },
      { skill: 'Strength', level: 42 },
      { skill: 'Defence', level: 42 },
      { skill: 'Hitpoints', level: 42 },
      { skill: 'Ranged', level: 42 },
      { skill: 'Magic', level: 42 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 450,
    task: 'Claim a Chompy bird hat from Rantz after registering at least 1,000 kills.',
    questReqs: ['8'],
    skillReqs: [],
    otherReqs: ['1000 chompy bird kills'],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 451,
    task: 'Pickpocket an elf.',
    questReqs: ['99'],
    skillReqs: [{ skill: 'Thieving', level: 85 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WESTERN,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 452,
    task: 'Cast Low Alchemy at the Fountain of Rune.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 21 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 453,
    task: 'Enter the Wilderness from the Ardougne or Edgeville lever.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 454,
    task: 'Pray at the Chaos Altar in level 38, western Wilderness.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 455,
    task: 'Enter the Chaos Runecrafting temple.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 456,
    task: 'Kill a mammoth.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 457,
    task: 'Kill an earth warrior in the Wilderness beneath Edgeville.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 458,
    task: 'Restore some Prayer points at the Demonic Ruins.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 459,
    task: 'Enter the King Black Dragon Lair.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 460,
    task: "Collect 5 red spider's eggs from the Wilderness.",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 461,
    task: 'Mine some iron ore in the Wilderness.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 15 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 462,
    task: 'Have the Mage of Zamorak teleport you to the Abyss.',
    questReqs: ['43'],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 463,
    task: 'Equip any team cape in the Wilderness.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.EASY,
  },
  {
    id: 464,
    task: 'Mine some mithril ore in the Wilderness.',
    questReqs: [],
    skillReqs: [{ skill: 'Mining', level: 55 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 465,
    task: 'Chop some yew logs from an ent.',
    questReqs: [],
    skillReqs: [{ skill: 'Woodcutting', level: 61 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 466,
    task: 'Enter the Wilderness God Wars Dungeon.',
    questReqs: [],
    skillReqs: [],
    otherReqs: ['60 Agility or 60 Strength'],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 467,
    task: 'Complete a lap of the Wilderness Agility Course.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 52 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 468,
    task: 'Kill a green dragon.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 469,
    task: 'Kill an ankou in the Wilderness.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 470,
    task: 'Charge an earth orb.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 60 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 471,
    task: 'Kill a bloodveld in the Wilderness God Wars Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 472,
    task: 'Talk to the Emblem Trader in Edgeville about emblems.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 473,
    task: 'Smith a Gold helmet in the Resource Area.',
    questReqs: ['7'],
    skillReqs: [{ skill: 'Smithing', level: 50 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 474,
    task: 'Open the muddy chest in the Lava Maze.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.MEDIUM,
  },
  {
    id: 475,
    task: 'Cast any of the three God spells against another player in the Wilderness.',
    questReqs: ['89'],
    skillReqs: [],
    otherReqs: ['Unlock the ability to cast a god spell outside of the arena'],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 476,
    task: 'Charge an air orb.',
    questReqs: [],
    skillReqs: [{ skill: 'Magic', level: 66 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 477,
    task: 'Catch a black salamander.',
    questReqs: [],
    skillReqs: [{ skill: 'Hunter', level: 67 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 478,
    task: 'Smith an adamant scimitar in the Resource Area.',
    questReqs: [],
    skillReqs: [{ skill: 'Smithing', level: 75 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 479,
    task: 'Kill a lava dragon and bury the bones on Lava Dragon Isle.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 480,
    task: 'Kill the Chaos Elemental.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 481,
    task: 'Kill the Crazy archaeologist, Chaos Fanatic & Scorpia.',
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 482,
    task: 'Take the Agility Shortcut from Trollheim into the Wilderness.',
    questReqs: [],
    skillReqs: [{ skill: 'Agility', level: 64 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 483,
    task: 'Kill a spiritual warrior in the Wilderness God Wars Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 68 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 484,
    task: 'Fish some raw lava eel in the Wilderness.',
    questReqs: [],
    skillReqs: [
      { skill: 'Fishing', level: 53 },
      { skill: 'Herblore', level: 25 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.HARD,
  },
  {
    id: 485,
    task: "Kill Callisto, Venenatis, and Vet'ion (or Artio, Spindel, and Calvar'ion).",
    questReqs: [],
    skillReqs: [],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 486,
    task: 'Teleport to Ghorrock.',
    questReqs: ['27'],
    skillReqs: [{ skill: 'Magic', level: 96 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 487,
    task: 'Fish and cook a dark crab in the Resource Area.',
    questReqs: [],
    skillReqs: [
      { skill: 'Fishing', level: 85 },
      { skill: 'Cooking', level: 90 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 488,
    task: 'Smith a rune scimitar from scratch in the Resource Area.',
    questReqs: [],
    skillReqs: [
      { skill: 'Mining', level: 85 },
      { skill: 'Smithing', level: 90 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 489,
    task: "Steal from the Rogues' Castle chest.",
    questReqs: [],
    skillReqs: [{ skill: 'Thieving', level: 84 }],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 490,
    task: 'Kill a spiritual mage inside the Wilderness God Wars Dungeon.',
    questReqs: [],
    skillReqs: [{ skill: 'Slayer', level: 83 }],
    otherReqs: ['60 Agility or 60 Strength'],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
  {
    id: 491,
    task: 'Cut and burn some magic logs in the Resource Area.',
    questReqs: [],
    skillReqs: [
      { skill: 'Woodcutting', level: 75 },
      { skill: 'Firemaking', level: 75 },
    ],
    otherReqs: [],
    location: DIARY_LOCATIONS.WILDERNESS,
    difficulty: DIARY_DIFFICULTY.ELITE,
  },
];

function getDiaryTasksById() {
  const tasksById = {};
  DIARY_TASKS.forEach(task => {
    tasksById[task.id] = task;
  });
  return tasksById;
}

export const diaryTasksById = getDiaryTasksById();

export default DIARY_TASKS;
